<template>
  <v-sheet class="ma-2" color="gray-1" rounded="lg">
    <!-- Banner Image-->
    <v-img
      :alt="alt_source"
      :lazy-src="lazy_source"
      :max-height="
        $vuetify.breakpoint.height > $vuetify.breakpoint.width ? '30vw' : '30vh'
      "
      :min-height="'25vh'"
      :src="source"
      class="rounded-t-lg"
    >
      <!-- Image placeholder-->
      <template v-slot:placeholder>
        <v-sheet
          class="fill-height ma-0 d-flex align-center justify-center"
          color="white"
          rounded="t-lg"
        >
          <v-icon color="gray-7" x-large>fas fa-images</v-icon>
        </v-sheet>
      </template>
    </v-img>
    <!-- Title and Description -->
    <v-container class="white--text">
      <h1 class="space-mono">New Features</h1>
      <p class="mb-0 body-2">See what’s new with Urban AI</p>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "NewFeatureCard",
  props: {
    source: {
      type: String,
      default: "",
      required: true,
    },
    lazy_source: {
      type: String,
      default: "",
      required: true,
    },
    alt_source: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>
