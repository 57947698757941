import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"ma-4",attrs:{"persistent":"","width":"850"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,{attrs:{"color":"secondary","rounded":"lg"}},[_c(VRow,{staticClass:"ma-0 pa-4",attrs:{"align-content":"center","justify":"space-between"}},[_c(VCol,{staticClass:"pa-2",attrs:{"cols":"auto"}},[_c('h2',{staticClass:"font-weight-black white--text space-mono text-h2"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c(VCol,{staticClass:"pa-2",attrs:{"align-self":"center","cols":"auto"}},[_c('u-button',{attrs:{"color":"white","icon":"","large":""},on:{"click":function($event){_vm.model = false}}},[_c(VIcon,{attrs:{"color":"white","size":"32"}},[_vm._v(" fas fa-times")])],1)],1)],1),_c(VCardText,[_vm._t("content",function(){return [_c('p',{staticClass:"font-weight-bold white--text subtitle-1 mb-0"},[_vm._v(" "+_vm._s(_vm.body)+" ")])]})],2),_c(VCardActions,{staticClass:"d-flex justify-end gap-1 pb-8 px-6"},[_c('u-button',{staticClass:"text-capitalize font-weight-bold white--text space-mono",attrs:{"color":"white","large":"","outlined":""},on:{"click":function($event){_vm.model = false}}},[_vm._v(" Cancel ")]),_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text space-mono",attrs:{"color":"white","large":""},on:{"click":function($event){return _vm.$emit('click:confirmAction')}}},[_vm._v(" "+_vm._s(_vm.actionName)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }