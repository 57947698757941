<template>
  <v-sheet
    :height="'65vh'"
    class="pa-4 card-border d-flex flex-column fill-height"
    outlined
    rounded="lg"
  >
    <!--  Videos by Camera  -->
    <v-container class="mb-2 px-2 flex-grow-1 overflow-y-auto" fluid>
      <v-item-group v-model="model" class="pa-0" mandatory>
        <video-setup-camera-videos-list
          v-for="(camera, key) in videosByCamera"
          :key="key"
          :camera-name="camera.camera_name"
          :camera-videos="camera.videos"
          @click:delete="triggerDeleteConfirmationModal(camera)"
        />
      </v-item-group>
    </v-container>

    <!--  Plus Button  -->
    <u-button
      class="
        text-none
        font-weight-bold
        space-mono
        rounded-sm
        mx-4
        secondary--text
      "
      color="gray-7"
      large
      @click.native="$emit('click:addVideos')"
    >
      <v-icon class="mr-4" small>fas fa-plus</v-icon>
      Add Videos
    </u-button>

    <!--  Delete Confirmation Dialog  -->
    <u-confirmation-modal
      v-model="showDeleteCameraVideoModal"
      :body="`Delete files selected under camera ${selectedCameraName}? This change cannot be undone`"
      action-name="delete"
      title="Are you sure?"
      @click:confirmAction="deleteCameraVideoToUpload(selectedCamera)"
    />
  </v-sheet>
</template>

<script>
import { UButton } from "@/components/base";
import { UConfirmationModal } from "@/components/modals";
import { VideoSetupCameraVideosList } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "VideoSetupCameraContainer",
  components: {
    UConfirmationModal,
    UButton,
    VideoSetupCameraVideosList,
  },
  props: {
    input: Object,
  },
  computed: {
    ...mapGetters({
      videosByCamera: "VideoUpload/getFilesToUploadByCamera",
    }),
    model: {
      get() {
        return this.input;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectedCameraName() {
      return this.selectedCamera?.camera_name ?? "";
    },
  },
  data() {
    return {
      showDeleteCameraVideoModal: false,
      selectedCamera: undefined,
    };
  },
  mounted() {
    for (const camera in this.videosByCamera) {
      const videos = this.videosByCamera[camera];
      if (videos.length > 0) {
        this.model = videos[0];
        break;
      }
    }
  },
  methods: {
    // Shows delete confirmation modal for the selected camera
    triggerDeleteConfirmationModal(camera) {
      this.selectedCamera = camera;
      this.showDeleteCameraVideoModal = true;
    },
    deleteCameraVideoToUpload(camera) {
      this.$store.dispatch("VideoUpload/deleteCameraVideos", camera);
      this.showDeleteCameraVideoModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
