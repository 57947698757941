import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{staticClass:"menu",attrs:{"max-width":"175","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VCard,_vm._g({staticClass:"mx-1 px-4 d-flex align-center fill-height",attrs:{"id":"nav-menu","ripple":false,"elevation":"0","tile":""}},on),[_c('p',{staticClass:"mb-0 mr-2 text-right dropdown-text"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.name))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.credits))])]),_c(VAvatar,{staticClass:"mr-2 avatar",attrs:{"size":"36"}}),_c(VIcon,{staticClass:"icon",attrs:{"x-small":""}},[_vm._v("fas fa-chevron-down")])],1)]}}])},[_c(VList,{staticClass:"border py-0 rounded-b-lg"},[_vm._l((_vm.menuOptions),function(item,index){return _c(VListItem,{key:index,attrs:{"to":item.link,"link":""}},[_c(VListItemTitle,{staticClass:"list-item-text"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c(VListItem,{staticClass:"accent-link list-item-text rounded-b-lg",attrs:{"link":""},on:{"click":_vm.logout}},[_c('span',[_vm._v("Log Out")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }