<template>
  <v-sheet
    :color="active ? 'secondary' : 'primary'"
    class="pa-8 rounded d-flex flex-column gap-1 justify-center align-start"
    height="10rem"
    min-width="15rem"
    width="15rem"
    v-on="$listeners"
  >
    <v-icon :color="active ? 'primary' : 'secondary'" size="3.5rem">
      {{ icon }}
    </v-icon>
    <h2
      :class="active ? 'primary--text' : 'secondary--text'"
      class="subtitle-1 text-capitalize"
    >
      {{ metric.label }}
    </h2>
  </v-sheet>
</template>

<script>
export default {
  name: "UMetricTile",
  props: {
    metric: {
      type: Object,
      default: () => ({ label: "", value: "" }),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      switch (this.metric.value) {
        case "flow":
          return "$flow";
        case "spot_speed":
          return "$spotSpeed";
        case "clearance_speed":
          return "$clearanceSpeed";
        case "clearance_time":
          return "fas fa-clock";
        default:
          return "";
      }
    },
  },
};
</script>
