import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,{staticClass:"ma-0 pa-6",attrs:{"color":"accent","rounded":"lg"}},[_c(VCardTitle,{staticClass:"py-0 secondary--text text-h3 flex justify-space-between flex-nowrap"},[_c('p',{staticClass:"text-truncate mb-0"},[_vm._v("Uh oh!")]),_c('u-button',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){_vm.model = false}}},[_c(VIcon,{attrs:{"color":"secondary","size":"25"}},[_vm._v(" fas fa-times")])],1)],1),_c(VCardText,{staticClass:"py-4 secondary--text"},[_c('p',{staticClass:"mb-0 body-1"},[_vm._v(" Date and Time Information have not been specified for all videos. "),_c('br'),_vm._v(" Kindly fill these out to continue to proceed. ")]),_c(VRow,{staticClass:"py-3"},[_c(VCol,[_c('p',{staticClass:"subtitle-2 font-weight-bold mb-1"},[_vm._v(" Videos with missing date "),_c(VIcon,{staticClass:"pl-1 pb-1",attrs:{"color":"secondary","x-small":""}},[_vm._v(" fas fa-chevron-down ")])],1),_vm._l((_vm.videosMissingDate),function(ref){
var filename = ref.filename;
return _c(VListItemGroup,{key:filename,staticClass:"body-1 video-name"},[_vm._v(" "+_vm._s(filename)+" ")])})],2),_c(VCol,[_c('p',{staticClass:"subtitle-2 font-weight-bold mb-1"},[_vm._v(" Videos with missing time "),_c(VIcon,{staticClass:"pl-1 pb-1",attrs:{"color":"secondary","x-small":""}},[_vm._v(" fas fa-chevron-down ")])],1),_vm._l((_vm.videosMissingTime),function(ref){
var filename = ref.filename;
return _c(VListItemGroup,{key:filename,staticClass:"body-1 video-name"},[_vm._v(" "+_vm._s(filename)+" ")])})],2)],1),_c('p',{staticClass:"ma-0 pt-2 body-1 font-weight-bold"},[_vm._v(" Missing fields are highlighted to make it easier to identify. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }