<template>
  <!-- Generator: Adobe Illustrator 24.1.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    fill="currentColor"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    :style="`height: ${size}px`"
    xml:space="preserve"
  >
    <path
      d="M335.3,66.6H46.9C20.5,66.6-0.9,88-0.9,114.4v288.4c0,26.4,21.4,47.8,47.8,47.8h288.4c26.4,0,47.8-21.4,47.8-47.8V114.4
	C383.1,88,361.7,66.6,335.3,66.6L335.3,66.6z"
    />
    <path
      d="M525.6,104.4L416,180v157.4l109.6,75.5c21.2,14.6,50.4-0.3,50.4-25.8V130.2C576,104.8,546.9,89.8,525.6,104.4z"
    />
    <text
      x="33%"
      y="55%"
      dominant-baseline="middle"
      text-anchor="middle"
      class="st0 st1 st2"
    >
      {{ number }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "CameraIcon",
  props: {
    number: {
      type: [String, Number],
      default: "",
    },
    size: {
      type: [String, Number],
      default: 64,
    },
  },
};
</script>

<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  font-family: "Urbanist", sans-serif;
  font-weight: 700;
}
.st2 {
  font-size: 256px;
}
</style>
