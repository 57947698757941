<template>
  <v-dialog
    :width="'85vw'"
    max-width="600"
    overlay-color="rgb(244,244,244)"
    overlay-opacity="0.75"
    persistent
    value="true"
  >
    <v-sheet class="pa-2 px-4 secondary--text" color="white" rounded="lg">
      <!--   Title   -->
      <v-row align="center" class="ma-2 flex-nowrap">
        <h1 class="modal-title space-mono text-wrap text-break">
          Check Geotagging
        </h1>
        <v-spacer />
        <u-button color="secondary" icon @click="$emit('click:close')">
          <v-icon>fas fa-times</v-icon>
        </u-button>
      </v-row>

      <p class="pa-2 mb-0">
        Please check the geotagging on the map below and choose to proceed or
        redo.
      </p>

      <!--   Map   -->
      <v-container fluid>
        <v-sheet class="overflow-hidden" height="350" rounded>
          <u-map
            :center="mapCenter"
            :map-annotations="mapAnnotations"
            :roi-projections="roiProjections"
            class="fill-height"
            show-geotagging
            zoom="17"
          ></u-map>
        </v-sheet>
      </v-container>

      <!--   Action Button   -->
      <v-row class="ma-2" justify="end">
        <u-button
          class="secondary--text font-weight-bold text-capitalize mr-2"
          color="background"
          large
          @click="$emit('click:close')"
        >
          Redo
        </u-button>
        <u-button
          class="secondary--text font-weight-bold text-capitalize"
          color="primary"
          large
          @click="$emit('click:proceed')"
        >
          Proceed
        </u-button>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { UButton, UMap } from "@/components/base";

export default {
  name: "GeotaggingModal",
  components: { UMap, UButton },
  props: {
    mapCenter: {
      type: Array,
      default: () => [77.59796, 12.96991],
    },
    roiProjections: {
      type: Array,
      default: () => [],
    },
    mapAnnotations: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
