const rules = {
  required: (value) =>
    (value !== undefined && value !== null && value !== "") ||
    "This field is required",

  email: (value) =>
    /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(value) || "Enter a valid email",

  min: (min) => (value) =>
    value.length >= min || `Enter at least ${min} characters`,

  password: (value) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!@#$%^&*]{8,}$/.test(value) ||
    "Must contain min. 8 characters, 1 upper and lower case letter, 1 digit",

  passwordConfirm: (value1) => (value2) =>
    value1 === value2 || "Passwords do not match",
};

export { rules };
