import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VForm,{ref:"form",staticClass:"flex-grow-1",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('u-text-field',_vm._g(_vm._b({attrs:{"dense":_vm.dense,"hide-details":false,"label":_vm.label,"outlined":!_vm.valid,"rules":[function () { return !!_vm.model || 'Field cannot be blank'; } ].concat( _vm.customRules),"append-icon":"fas fa-calendar-day","placeholder":"01/04/2022","readonly":""},on:{"click:append":function($event){_vm.showMenu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.valid)?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"accent","small":""}},[_vm._v(" fas fa-exclamation-triangle ")]):_vm._e(),_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v("fas fa-calendar-day")])]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'u-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('u-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.showMenu = false}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }