<template>
  <v-item-group v-model="model" mandatory>
    <v-container>
      <v-row>
        <!-- Toggle Items List -->
        <v-item
          v-for="(item, index) in items"
          :key="index"
          :value="itemValue.length === 0 ? item : item[itemValue]"
          v-slot="{ active, toggle }"
        >
          <u-button
            :class="active ? '' : 'outline'"
            :color="active ? 'secondary' : 'white'"
            @click="toggle"
            class="ma-1 rounded"
            fab
          >
            <!-- icon -->
            <slot v-bind="{ item, index, active }">
              <v-icon :color="active ? 'white' : 'secondary'">
                {{ item.icon || "fas fa-video" }}
              </v-icon>
            </slot>
          </u-button>
        </v-item>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UPreviewToggleGroup",
  components: { UButton },
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    // Items must include icon to display unless using slot
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    itemValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.outline {
  outline: 2px solid var(--v-gray-7-base);
}
</style>
