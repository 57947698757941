<template>
  <v-dialog v-model="model" max-width="1600" scrollable width="75vw">
    <v-card class="pa-2" color="white" rounded="lg">
      <!--   Title   -->
      <v-card-title class="font-weight-black secondary--text space-mono">
        <v-row class="ma-0" justify="space-between">
          <v-col class="pa-0" cols="auto">
            <h2 v-if="!deleteProjectsFlag">All Projects</h2>
            <h2 v-else>Select projects to delete</h2>
          </v-col>

          <!--   Action Buttons   -->
          <v-col class="pa-0" cols="auto">
            <u-button
              v-if="!deleteProjectsFlag"
              class="rounded mr-2"
              color="gray-7"
              fab
              small
              @click="deleteProjectsFlag = !deleteProjectsFlag"
            >
              <v-icon color="secondary" small> fas fa-trash</v-icon>
            </u-button>
            <u-button color="secondary" icon @click="closeModal">
              <v-icon color="secondary"> fas fa-times</v-icon>
            </u-button>
          </v-col>
        </v-row>
      </v-card-title>

      <!--   Search Field   -->
      <u-text-field
        v-model="search"
        class="my-2 mx-auto px-8"
        color="secondary"
        dense
        hide-details
        placeholder="Search for projects"
      >
        <template #prepend-inner>
          <v-icon class="mr-2" color="secondary" small> fas fa-search </v-icon>
        </template>
        <template v-if="search.length" #append>
          <v-icon class="ml-2" small @click="search = ''">
            fas fa-times
          </v-icon>
        </template>
      </u-text-field>

      <!--   Project List   -->
      <v-card-text class="my-4 overflow-x-hidden">
        <!--  Project List  -->
        <v-item-group
          v-model="selectedProjects"
          class="d-flex flex-wrap"
          multiple
        >
          <v-col
            v-for="project in filteredProjects"
            :key="project.id"
            align-self="center"
            cols="12"
            lg="4"
            md="6"
          >
            <v-item v-slot="{ active, toggle }" :value="project">
              <u-project-tile
                :active="active"
                :created-on="project['created_at']"
                :lazy_source="project.image_url"
                :projectName="project.name"
                :source="project.image_url"
                class="cursor-pointer"
                @click.native="
                  deleteProjectsFlag ? toggle() : resumeProject(project)
                "
              />
            </v-item>
          </v-col>
        </v-item-group>
      </v-card-text>
      <v-card-actions
        v-if="deleteProjectsFlag"
        class="d-flex justify-end gap-1"
      >
        <u-button
          class="font-weight-bold text-capitalize secondary--text"
          color="secondary"
          large
          outlined
          @click="clearSelection"
        >
          Cancel
        </u-button>
        <u-button
          class="font-weight-bold text-capitalize secondary--text"
          color="error"
          large
          :disabled="!selectedProjects.length"
          @click="showDeleteConfirmationModal = true"
        >
          Delete
        </u-button>
      </v-card-actions>
    </v-card>

    <!--  Delete Confirmation Dialog  -->
    <u-confirmation-modal
      v-model="showDeleteConfirmationModal"
      action-name="delete"
      title="Are you sure?"
      @click:confirmAction="deleteProjects"
    >
      <template #content>
        <p class="white--text subtitle-1">
          Deleting will remove all the data associated with the project. Are you
          sure you want to Delete the following projects:
        </p>
        <ul class="project-list-2-col white--text subtitle-1 font-weight-bold">
          <li v-for="project in selectedProjects" :key="project.id">
            {{ project.name }}
          </li>
        </ul>
      </template>
    </u-confirmation-modal>
  </v-dialog>
</template>

<script>
import { UButton, UTextField } from "@/components/base";
import { UProjectTile } from "@/components/common";
import { UConfirmationModal } from "@/components/modals";
import { mapState } from "vuex";

export default {
  name: "AllProjectsModal",
  components: { UConfirmationModal, UTextField, UButton, UProjectTile },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("User", ["projects"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    filteredProjects() {
      return this.projects.filter((project) =>
        project.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  data: () => ({
    deleteProjectsFlag: false,
    selectedProjects: [],
    search: "",
    showDeleteConfirmationModal: false,
  }),
  methods: {
    resumeProject(project) {
      this.$store.dispatch("Project/clearProjectState");
      this.$store.commit("Project/storeProjectDetails", project);
      this.$store.dispatch("Project/getProjectCameras", project.project_id);
      this.$router.push({
        name: "Project Details",
        params: { project_id: project.project_id },
      });
    },
    clearSelection() {
      this.selectedProjects = [];
      this.deleteProjectsFlag = false;
    },
    closeModal() {
      this.clearSelection();
      this.model = false;
    },
    async deleteProjects() {
      await this.$store.dispatch("User/deleteProjects", this.selectedProjects);
      this.showDeleteConfirmationModal = false;
      this.clearSelection();
    },
  },
};
</script>

<style lang="scss">
.project-list-2-col {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
