<template>
  <v-btn
    elevation="0"
    v-bind="{ ...$attrs, ...$props }"
    v-on="{ ...$listeners }"
  >
    <slot></slot>
    <v-icon v-if="loadState" class="custom-loader px-1" size="16" color="white">
      fas fa-sync-alt
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "UButton",
  props: {
    color: { type: String, default: "" },
    to: { type: String, default: "" },
    // Button Styles
    outlined: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    // Button Size
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    // Misc
    loadState: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
