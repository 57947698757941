import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"py-2 mx-1 mb-2 secondary--text",attrs:{"align":"center"}},[_c('camera-icon',{attrs:{"number":_vm.cameraIndex,"size":22}}),_c('p',{staticClass:"px-2 mb-0 subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.cameraName))]),_c(VSpacer),_c('u-button',{staticClass:"rounded",attrs:{"color":"gray-7","fab":"","small":"","tile":""},on:{"click":function($event){return _vm.$emit('click:delete')}}},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v("fas fa-trash")])],1)],1),_vm._l((_vm.cameraVideos),function(video,index){return _c(VItem,{key:index,attrs:{"value":video},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('video-setup-camera-videos-list-item',{attrs:{"active":active,"date":video.date,"label":video.filename,"time":video.time,"validation":_vm.$store.state.VideoUpload.validateFilesToUpload || video.error},nativeOn:{"click":function($event){return toggle.apply(null, arguments)}}})]}}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }