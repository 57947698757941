<template>
  <svg
    fill="none"
    height="26"
    viewBox="0 0 25 26"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3459 10.1996C12.824 10.2751 13.302 10.3757 13.755 10.5267C13.9059 10.577 14.0569 10.5015 14.1072 10.3757L15.1389 7.88466C15.2144 7.70852 15.1137 7.53239 14.9376 7.48206C14.0821 7.30593 13.2266 7.20528 12.371 7.20528C12.2201 7.20528 12.0691 7.33109 12.0691 7.50722V9.92281C12.0943 10.0738 12.2201 10.1996 12.3459 10.1996Z"
      fill="currentColor"
    />
    <path
      d="M18.9876 9.16793C18.2075 8.66468 17.3772 8.26209 16.4965 7.93498C16.3455 7.88465 16.1945 7.96014 16.1191 8.11111L15.0371 10.7532C14.9868 10.9041 15.0371 11.0551 15.1629 11.1306C15.5906 11.357 16.0184 11.6087 16.3958 11.8855C16.5217 11.9609 16.6726 11.9609 16.7733 11.8603L19.0127 9.62085C19.1637 9.49504 19.1385 9.26858 18.9876 9.16793Z"
      fill="currentColor"
    />
    <path
      d="M20.6487 10.426C21.3029 11.0048 21.8817 11.659 22.3849 12.3635C22.4856 12.5145 22.4352 12.7158 22.2843 12.7913L18.8874 14.2004C18.7615 14.2759 18.6106 14.2256 18.5351 14.0997C18.3338 13.7978 18.1325 13.4959 17.8809 13.2191C17.7802 13.0933 17.6796 12.9926 17.5789 12.892C17.4531 12.7913 17.4783 12.59 17.5789 12.4894L19.919 10.1493C20.0197 10.0486 20.1706 10.0235 20.2964 10.1241C20.4223 10.2248 20.5481 10.3254 20.6487 10.426Z"
      fill="currentColor"
    />
    <path
      d="M24.5242 16.8928C24.2474 15.7856 23.8196 14.7288 23.266 13.7475C23.1906 13.6217 23.0396 13.5713 22.8886 13.6217L19.3659 15.0811C19.2149 15.1314 19.1394 15.3075 19.2149 15.4585C19.4414 15.9618 19.5924 16.4902 19.7182 17.0186C19.7433 17.1444 19.8691 17.245 19.9949 17.245H24.2222C24.4235 17.245 24.5493 17.0689 24.5242 16.8928Z"
      fill="currentColor"
    />
    <path
      d="M3.63919 10.9041C3.73984 10.7783 3.91597 10.7783 4.04178 10.9041L4.97279 11.81C5.0986 11.9358 5.07344 12.1371 4.94763 12.2377C4.87214 12.2881 4.77149 12.3635 4.696 12.439C4.26824 12.8165 3.89081 13.2191 3.53854 13.6468C3.46305 13.7475 3.33724 13.7726 3.21143 13.7223L2.25526 13.3197C2.07912 13.2694 2.00364 13.0681 2.10429 12.9171C2.45656 12.3132 2.88432 11.7093 3.38756 11.1558L3.63919 10.9041Z"
      fill="currentColor"
    />
    <path
      d="M11.1131 9.82214V7.60785C11.1131 7.43172 10.9621 7.3059 10.786 7.3059C9.95565 7.40655 9.15045 7.58269 8.37042 7.85948C8.21945 7.9098 8.14396 8.08594 8.19428 8.23691L8.94915 10.0738C8.99948 10.1996 9.12529 10.2751 9.27626 10.2499C9.77951 10.1492 10.3079 10.0989 10.8112 10.0989C10.9873 10.0989 11.1131 9.97311 11.1131 9.82214Z"
      fill="currentColor"
    />
    <path
      d="M6.8858 8.48855C7.03678 8.41307 7.21291 8.48855 7.2884 8.63953L7.94262 10.2751C7.99294 10.4261 7.91746 10.6022 7.76648 10.6525C7.23807 10.8538 6.70967 11.0803 6.20642 11.3822C6.08061 11.4325 5.9548 11.4325 5.85415 11.3319L4.77217 10.2499C4.64636 10.1241 4.67152 9.9228 4.79733 9.82215C5.45155 9.31891 6.1561 8.86599 6.8858 8.48855Z"
      fill="currentColor"
    />
    <path
      d="M2.5068 14.5275L1.75193 14.2256C1.60096 14.1752 1.42482 14.2256 1.3745 14.3765C1.02223 15.2069 0.770602 16.0624 0.644791 16.9431C0.594466 17.1192 0.74544 17.2954 0.921576 17.2954H1.44999C1.5758 17.2954 1.67645 17.2199 1.72677 17.0941C1.95323 16.3392 2.28034 15.6346 2.65778 14.9553C2.73326 14.8043 2.65778 14.603 2.5068 14.5275Z"
      fill="currentColor"
    />
    <path
      d="M12.3699 15.8863C13.3764 15.1063 14.7854 14.0243 15.8423 13.2442C16.0687 13.0681 16.3707 13.3449 16.1694 13.5714C16.0917 13.674 16.0111 13.7807 15.9281 13.8905C15.177 14.8842 14.2297 16.1374 13.5273 17.0437C13.628 17.2702 13.6783 17.5218 13.6783 17.7735C13.6783 18.9058 12.7725 19.8116 11.6402 19.8116C10.5078 19.8116 9.60201 18.9058 9.60201 17.7735C9.60201 16.6412 10.5078 15.7353 11.6402 15.7353C11.8918 15.7353 12.1434 15.8108 12.3699 15.8863Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ClearanceSpeedIcon",
};
</script>

<style scoped></style>
