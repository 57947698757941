<template>
  <v-container class="pa-0" fluid>
    <!-- Area -->
    <v-container v-if="false" class="mb-4" fluid>
      <u-text-field
        dense
        label="Assign configurable area"
        placeholder="Create an area"
      />
      <u-button class="text-capitalize font-weight-bold" color="gray-7">
        <v-icon class="mr-2" small>fas fa-draw-polygon</v-icon>
        Add area
      </u-button>
    </v-container>

    <!-- Movements -->
    <v-container v-if="false" class="mb-4" fluid>
      <p class="font-weight-bold mb-0 body-2">Movements Drawn</p>
      <!-- Placeholder for list component -->
      <v-sheet class="pa-2 py-4 my-2" color="background" rounded>
        <p class="body-2 placeholder--text mb-0">
          Click here to draw movement (placeholder)
        </p>
      </v-sheet>
      <!-- --- -->
      <u-button class="text-capitalize font-weight-bold" color="gray-7">
        <v-icon class="mr-2" small>fas fa-route</v-icon>
        Add Movements
      </u-button>
    </v-container>

    <!--  Temporary V1 UI  -->
    <!-- Tags -->
    <v-row align="end" class="ma-2">
      <u-dropdown
        v-model="selectedTag"
        :items="projectTags"
        class="mr-2 col"
        dense
        item-text="tag_name"
        label="Tag"
        mandatory
        placeholder="Select a tag"
        return-object
      />
      <!-- Create Tag Menu -->
      <v-menu
        :close-on-content-click="false"
        bottom
        offset-y
        right
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <u-button
            class="text-capitalize font-weight-bold secondary--text rounded"
            color="gray-7"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2" small>fas fa-tag</v-icon>
            Add New
          </u-button>
        </template>
        <v-sheet class="shadow ma-4 pa-4" rounded="lg" width="300">
          <u-text-field v-model="newTag" dense placeholder="Tag Name" />
          <v-row class="my-2 mx-0" justify="end">
            <u-button
              class="text-capitalize font-weight-bold rounded"
              color="gray-7"
              @click="createTag"
            >
              Create
            </u-button>
          </v-row>
        </v-sheet>
      </v-menu>
    </v-row>

    <!-- Cluster tags -->
    <v-container class="my-4 pa-0" fluid>
      <p class="font-weight-bold mb-0 mx-2 body-2">Cluster Tags</p>
      <u-cluster-tag-toggles
        v-model="selectedLabels"
        :cluster-items="clusterItems"
      />
    </v-container>

    <!-- Clusters -->
    <v-container class="my-2 py-1 px-1 d-flex gap-2 align-end" fluid>
      <u-text-field
        v-model="clusterSize"
        class="col"
        dense
        label="Cluster Size"
        type="number"
      />
      <u-button
        :class="clusterLoader ? 'white--text' : 'secondary--text'"
        :color="clusterLoader ? 'gray-1' : 'gray-7'"
        :disbled="clusterLoader"
        :loadState="clusterLoader"
        class="text-capitalize font-weight-bold rounded"
        @click="getDirectionMovement"
      >
        Generate Clusters
      </u-button>
    </v-container>

    <v-container v-if="movementGroups.length" fluid>
      <p class="mb-0 body-2 font-weight-bold">
        Select the movements to include
      </p>
      <v-row class="px-2">
        <v-switch
          v-for="group in movementGroups"
          :key="group.name"
          v-model="selectedMovementGroups"
          :value="group"
          color="secondary"
          dense
          inset
        ></v-switch>
      </v-row>
    </v-container>
    <!--  ---  -->

    <!-- Action Buttons -->
    <v-row class="ma-4" justify="space-between">
      <u-simple-checkbox
        v-model="showAnnotations"
        class="mt-1"
        label="Show Annotations"
      />
      <u-button
        class="text-capitalize font-weight-bold"
        :class="testLoader ? 'white--text' : 'secondary--text'"
        :color="testLoader ? 'gray-1' : 'gray-7'"
        :disbled="testLoader"
        :loadState="testLoader"
        @click="testConfig"
      >
        Test
      </u-button>
    </v-row>

    <!--  Test Metric Results Modal  -->
    <test-metric-results-modal
      v-if="showTestMetricResults"
      :metric-results="metric_results"
      @click:close="showTestMetricResults = false"
    />
  </v-container>
</template>

<script>
import {
  UButton,
  UTextField,
  USimpleCheckbox,
  UDropdown,
} from "@/components/base";
import { UClusterTagToggles } from "@/components/common";
import { TestMetricResultsModal } from "@/components/modals";
import { randomColor } from "@/utils";
import { mapState, mapGetters } from "vuex";
import ApiService from "@/services/ApiService";

export default {
  components: {
    TestMetricResultsModal,
    USimpleCheckbox,
    UClusterTagToggles,
    UTextField,
    UButton,
    UDropdown,
  },
  name: "MetricConfigParametersCard",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    canvasRef: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("MetricConfig", ["getActiveConfig"]),
    ...mapState({
      activeCameraId: (state) => state.MetricConfig.activeCamera?.camera_id,
      activeProjectId: (state) => state.Project.activeProject.project_id,
      activeMetric: (state) => state.MetricConfig.activeMetric,
      projectTags: (state) => state.Project.tags,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    // Selected cluster labels
    selectedLabels: {
      get() {
        return this.model?.selectedLabels ?? [];
      },
      set(value) {
        this.model = { ...this.model, selectedLabels: value };
      },
    },
  },

  watch: {
    model(newValue) {
      this.selectedMovementGroups = newValue?.selectedMovementGroups ?? [];
      this.movementGroups = newValue?.movementGroups ?? [];
    },
    selectedMovementGroups(newValue) {
      this.model = { ...this.model, selectedMovementGroups: newValue };
      this.$emit("movement-updated", newValue);
    },
    selectedTag(newValue) {
      this.$store.commit("MetricConfig/SET_ACTIVE_TAG", newValue);
    },
  },

  mounted() {
    this.selectedMovementGroups = this.model?.selectedMovementGroups ?? [];
    this.movementGroups = this.model?.movementGroups ?? [];
    this.selectedTag =
      this.$store.state.MetricConfig.activeTag ?? this.projectTags[0];
  },
  data: () => ({
    clusterItems: [
      { icon: "fas fa-car", text: "Cars", value: "car" },
      { icon: "fas fa-biking", text: "Motorcycles", value: "motorcycle" },
      { icon: "fas fa-bus", text: "Buses", value: "bus" },
      { icon: "fas fa-truck", text: "Trucks", value: "truck" },
      { icon: "fas fa-walking", text: "Pedestrians", value: "person" },
      { icon: "fas fa-bicycle", text: "Bicycles", value: "bicycle" },
    ],
    showAnnotations: true,

    // Create tag input value
    newTag: "",
    selectedTag: "",
    clusterSize: "6",
    movementGroups: [],
    selectedMovementGroups: [],
    modelPath: "",
    metric_results: [],
    showTestMetricResults: false,

    // Button loader
    clusterLoader: false,
    testLoader: false,
  }),
  methods: {
    /**
     * Get direction movement / clusters
     */
    async getDirectionMovement() {
      const { canvasArea, selectedLabels } = this.getActiveConfig;
      const params = {
        tag_id: this.selectedTag.tag_id,
        metric: this.activeMetric,
        direction_roi: canvasArea,
        cluster_size: this.clusterSize,
        clustering_labels: selectedLabels,
      };
      try {
        this.clusterLoader = true;
        const res = await ApiService.getDirectionMovement(
          this.activeProjectId,
          this.activeCameraId,
          params,
        );
        this.clusterLoader = false;

        const { s3_model_key, data } = res.data;
        this.modelPath = s3_model_key;
        this.selectedMovementGroups = [];
        // Scale and format movement groups
        this.movementGroups = Object.entries(data).map((group) => {
          const scaledArrows = group[1].map(({ src, dst }) => ({
            src: this.canvasRef.scaleToCanvas(src),
            dst: this.canvasRef.scaleToCanvas(dst),
          }));

          return {
            color: randomColor(),
            arrowData: scaledArrows,
            name: group[0],
          };
        });

        this.model = {
          ...this.model,
          movementGroups: this.movementGroups,
          model_path: this.modelPath,
        };
      } catch (e) {
        this.clusterLoader = false;
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to get clusters!",
          color: "error",
        });
      }
    },

    /**
     * Test Metric Configuration for current metric
     */
    async testConfig() {
      this.testLoader = true;
      const { canvasArea = [] } = this.getActiveConfig;
      const selected_clusters = this.selectedMovementGroups.map((selected) =>
        parseInt(selected.name.slice(-1)),
      );
      const params = {
        metric: this.activeMetric,
        direction_roi: canvasArea,
        selected_clusters: selected_clusters,
        model_path: this.modelPath,
      };
      try {
        const res = await ApiService.testConfig(
          this.activeProjectId,
          this.activeCameraId,
          params,
        );
        this.testLoader = false;
        this.metric_results = res.data["metrics_result"];
        this.showTestMetricResults = true;
      } catch (e) {
        this.testLoader = false;
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to test metric configuration!",
          color: "error",
        });
      }
    },

    /**
     * Create Tag
     */
    async createTag() {
      const params = {
        tag_name: this.newTag,
      };
      await this.$store.dispatch("Project/createTag", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}

.shadow {
  box-shadow: 0 0 15px rgba(3, 23, 39, 0.15);
}
</style>
