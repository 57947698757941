import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":'75%',"height":"80%","max-height":"800","max-width":"1200","overlay-color":"#969ea5","overlay-opacity":"0.5","persistent":"","value":"true"}},[_c(VSheet,{staticClass:"pa-6 px-8 secondary--text",attrs:{"color":"white","height":"80%","max-height":"800","rounded":"lg"}},[_c(VRow,{staticClass:"mx-2",attrs:{"align":"center"}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c(VIcon,{staticClass:"mr-4",attrs:{"color":"secondary","size":"44"}},[_vm._v(" fas fa-video ")]):_vm._e(),_c('h1',{staticClass:"heading-4 space-mono text-wrap text-break"},[_vm._v(" Assign Preview ")]),_c(VSpacer),_c('u-button',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_c(VIcon,[_vm._v("fas fa-times")])],1)],1),_c('p',{staticClass:"pa-2 mb-0 body-1 font-weight-bold"},[_vm._v(" Browse and select the ideal camera preview here ")]),_c(VRow,[_c(VSheet,{staticClass:"overflow-auto",class:_vm.$vuetify.breakpoint.mdAndDown ? 'py-4 mx-4' : 'my-4 px-4',attrs:{"max-height":'60vh',"cols":"12","lg":"auto"}},[_c(VItemGroup,{class:{
            'd-flex flex-row': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"mandatory":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.videos),function(video){return _c(VItem,{key:video.id,staticClass:"ma-2",attrs:{"value":video},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
          var toggle = ref.toggle;
return [_c('u-camera-preview-tile',{attrs:{"active":active,"image-url":video.camera_image,"hide-label":""},nativeOn:{"click":function($event){return toggle.apply(null, arguments)}}})]}}],null,true)})}),1)],1),_c(VCol,[_c(VImg,{staticClass:"rounded-lg ma-4",attrs:{"aspect-ratio":16 / 9,"src":_vm.model.camera_image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VSheet,{staticClass:"fill-height d-flex align-center justify-center",attrs:{"color":"background","max-height":"150","rounded":"lg"}},[_c(VIcon,{attrs:{"color":"gray-5","x-large":""}},[_vm._v("fa-photo-video")])],1)]},proxy:true}])}),_c(VRow,{staticClass:"mt-2 mx-4",attrs:{"justify":"space-between"}},[_c('p',{staticClass:"body-1 font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.model.video_file_name)+" ")]),_c('u-button',{staticClass:"secondary--text text-capitalize font-weight-bold",attrs:{"color":"primary"},on:{"click":_vm.assignCameraImage}},[_vm._v(" Assign ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }