<template>
  <v-dialog v-model="model" class="ma-4" persistent width="850">
    <v-card color="secondary" rounded="lg">
      <!-- Header -->
      <v-row align-content="center" class="ma-0 pa-4" justify="space-between">
        <v-col class="pa-2" cols="auto">
          <h2 class="font-weight-black white--text space-mono text-h2">
            {{ title }}
          </h2>
        </v-col>

        <v-col align-self="center" class="pa-2" cols="auto">
          <u-button color="white" icon large @click="model = false">
            <v-icon color="white" size="32"> fas fa-times</v-icon>
          </u-button>
        </v-col>
      </v-row>

      <!-- Body -->
      <v-card-text>
        <slot name="content">
          <p class="font-weight-bold white--text subtitle-1 mb-0">
            {{ body }}
          </p>
        </slot>
      </v-card-text>

      <!-- Action Buttons -->
      <v-card-actions class="d-flex justify-end gap-1 pb-8 px-6">
        <u-button
          class="text-capitalize font-weight-bold white--text space-mono"
          color="white"
          large
          outlined
          @click="model = false"
        >
          Cancel
        </u-button>
        <u-button
          class="text-capitalize font-weight-bold secondary--text space-mono"
          color="white"
          large
          @click="$emit('click:confirmAction')"
        >
          {{ actionName }}
        </u-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UConfirmationModal",
  components: { UButton },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    actionName: {
      type: String,
      default: "",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
