<template>
  <v-container class="px-0 d-flex flex-column gap-2" fluid>
    <v-container
      v-for="(camera, index) in data"
      :key="camera.id"
      class="pa-0"
      fluid
    >
      <v-row
        align="center"
        class="
          py-2
          mx-1
          secondary--text
          d-flex
          flex-nowrap
          justify-space-between
          align-center
        "
      >
        <!--   Camera Name   -->
        <v-container class="d-flex align-center" fluid>
          <camera-icon :number="index + 1" :size="32" />
          <p class="px-2 mb-0 subtitle-1 font-weight-bold">{{ camera.name }}</p>
        </v-container>

        <!--   Action Buttons   -->
        <u-button
          class="text-capitalize font-weight-bold"
          color="gray-7"
          @click="toggleAll(camera.id)"
        >
          {{ isAllSelected(camera.id) ? "Clear All" : "Select All" }}
        </u-button>
      </v-row>

      <!--   Metric List   -->
      <v-container class="px-2 d-flex gap-2 flex-nowrap overflow-x-auto" fluid>
        <u-metric-tile
          v-for="(metric, metricIndex) in camera.metrics"
          :key="metricIndex"
          :active="isSelected(camera.id, metric)"
          :metric="metric"
          @click="toggle(camera.id, metric)"
        />
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { UButton } from "@/components/base";
import { CameraIcon } from "@/components/icons";
import { UMetricTile } from "@/components/common";

export default {
  name: "ProcessMetricSelector",
  components: { UMetricTile, UButton, CameraIcon },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    /**
     * Check if given metric is selected for the camera
     * @param cameraId - Camera ID
     * @param {Object} metric - Metric Object
     * @returns {Boolean} - True if selected, false otherwise
     */
    isSelected(cameraId, metric) {
      return this.model.some(
        (item) =>
          item.cameraId === cameraId &&
          item.metrics.some((m) => m.value === metric.value),
      );
    },

    /**
     * Toggle the metric selection for the camera
     * @param cameraId - Camera ID
     * @param {Object} metric - Metric object
     */
    toggle(cameraId, metric) {
      if (this.isSelected(cameraId, metric)) {
        let list = this.model.map((item) => {
          if (item.cameraId === cameraId) {
            item.metrics = item.metrics.filter((m) => m.value !== metric.value);
          }
          return item;
        });
        this.model = list.filter((item) => item.metrics.length > 0);
      } else {
        // check if camera exists
        const cameraIndex = this.model.findIndex(
          (item) => item.cameraId === cameraId,
        );
        if (cameraIndex === -1) {
          this.model.push({
            cameraId,
            metrics: [metric],
          });
        } else {
          this.model[cameraIndex].metrics.push(metric);
        }
      }
    },

    /**
     * Check if all metrics are selected for given camera
     * @param cameraId
     * @returns {Boolean} - True if all selected, false otherwise
     */
    isAllSelected(cameraId) {
      const camera = this.data.find((c) => c.id === cameraId);
      return this.model.some(
        (item) =>
          item.cameraId === cameraId &&
          item.metrics.length === camera.metrics.length,
      );
    },

    /**
     * Toggle all metrics for given camera
     * @param {string} cameraId
     */
    toggleAll(cameraId) {
      const camera = this.data.find((c) => c.id === cameraId);
      if (this.isAllSelected(cameraId)) {
        this.model = this.model.filter((item) => item.cameraId !== cameraId);
      } else {
        this.model.push({
          cameraId,
          metrics: [...camera.metrics],
        });
      }
    },
  },
};
</script>
