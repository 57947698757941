import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"tile",attrs:{"color":"gray-1","flat":"","height":"64","min-width":"50vw"}},[_c(VRow,{staticClass:"mx-2",attrs:{"align":"center"}},[_c(VAvatar,{staticClass:"mx-2",attrs:{"color":"white","size":"40"}},[_c('p',{staticClass:"gray-1--text mb-0 font-weight-black sub-step text-h5"},[_vm._v(" "+_vm._s(_vm.step)+" ")])]),_c(VCol,{attrs:{"align-self":"center","cols":"auto"}},[_c('h2',{staticClass:"space-mono white--text title-height"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"white--text body-2 mb-0"},[_vm._v(_vm._s(_vm.subtitle))])]),_c(VSpacer),_c('u-button',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('click:eye-icon')}}},[_c(VIcon,[_vm._v("fas fa-eye")])],1),_c('u-button',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('click:prev')}}},[_c(VIcon,[_vm._v("fas fa-chevron-left")])],1),_c('p',{staticClass:"mb-0 font-weight-bold white--text"},[_vm._v(_vm._s(_vm.subStep))]),_c('u-button',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('click:next')}}},[_c(VIcon,[_vm._v("fas fa-chevron-right")])],1),_c('u-button',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_c(VIcon,[_vm._v("fas fa-times")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }