import Vue from "vue";
import Vuex from "vuex";
import {
  CameraConfig,
  MetricConfig,
  Notification,
  Project,
  User,
  VideoUpload,
} from "@/store/modules";
import ApiService from "@/services/ApiService";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    templates: [],
  },
  mutations: {
    // Store all templates
    SET_TEMPLATES(state, payload) {
      state.templates = payload;
    },
  },
  getters: {},
  actions: {
    // Fetch all templates
    async getTemplates({ commit, dispatch }) {
      try {
        const res = await ApiService.getAllTemplates();
        commit("SET_TEMPLATES", res.data);
      } catch (error) {
        console.log(error);
        dispatch("Notification/addNotification", {
          text: "Error fetching templates!",
          color: "error",
        });
      }
    },

    // Reset all state
    resetAllState({ dispatch }) {
      dispatch("Project/resetState");
      dispatch("CameraConfig/resetState");
      dispatch("MetricConfig/resetState");
      dispatch("Notification/resetState");
      dispatch("User/resetState");
      dispatch("VideoUpload/resetState");
    },
  },
  modules: {
    User,
    Project,
    VideoUpload,
    CameraConfig,
    MetricConfig,
    Notification,
  },
});
