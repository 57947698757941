import Vue from "vue";
import VueRouter from "vue-router";
import { ACCESS_TOKEN, getJwt, isValidJwt, REFRESH_TOKEN } from "@/utils";
import store from "@/store";
import ApiService from "@/services/ApiService";

Vue.use(VueRouter);

// Fall back to home page if project is not set
const beforeEnterProjectRoute = async (to, from, next) => {
  const projectId = to.params.project_id;

  if (!projectId) next({ name: "Dashboard", replace: true });
  if (store.state.Project.activeProject) next();

  try {
    const res = await ApiService.projectDetails(projectId);

    store.commit("Project/storeProjectDetails", { ...res.data });
    // await store.dispatch("Project/getProjectCameras", projectId);

    next();
  } catch (err) {
    await store.dispatch("Notification/addNotification", {
      color: "error",
      text: "Error loading project",
    });
    next({ name: "Dashboard", replace: true });
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "Login • Urban AI", hideForAuth: true },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/SignUp.vue"),
    meta: { title: "Signup • Urban AI", hideForAuth: true },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () => import("@/views/ResetPassword.vue"),
    meta: { title: "Reset Password • Urban AI", hideForAuth: true },
    beforeEnter: (to, from, next) => {
      to.query.token ? next() : next({ name: "Login" });
    },
  },
  {
    path: "/dashboard",
    alias: "/",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: { title: "Dashboard • Urban AI", requiresAuth: true },
  },
  {
    path: "/setup",
    name: "Setup",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    props: true,
    redirect: () => ({
      name: "Project Details",
      params: { project_id: store.state.Project.activeProject.project_id },
    }),
    children: [
      {
        path: "create-project",
        name: "Create Project",
        component: () => import("@/views/CreateProject.vue"),
        meta: { title: "Create Project • Setup • Urban AI" },
      },
      {
        path: ":project_id/video-library",
        name: "Video Library",
        component: () => import("@/views/VideoLibrary.vue"),
        meta: { title: "Video Library • Setup • Urban AI" },
        beforeEnter: beforeEnterProjectRoute,
      },
      {
        path: ":project_id/video-upload",
        name: "Video Upload",
        component: () => import("@/views/VideoUpload.vue"),
        meta: { title: "Video Upload • Setup • Urban AI" },
        beforeEnter: beforeEnterProjectRoute,
      },
      {
        path: ":project_id/video-setup",
        name: "Video Setup",
        component: () => import("@/views/VideoSetup.vue"),
        meta: { title: "Video Setup • Setup • Urban AI" },
        beforeEnter: beforeEnterProjectRoute,
        // props: (route) => ({
        //   ...route.params,
        // }),
      },
      {
        path: ":project_id/details",
        name: "Project Details",
        component: () => import("@/views/ProjectDetails.vue"),
        meta: { title: "Project Details • Setup • Urban AI" },
        beforeEnter: beforeEnterProjectRoute,
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/config",
    name: "Config",
    redirect: () => ({
      name: "Camera Configuration",
      params: { project_id: store.state.Project.activeProject.project_id },
    }),
    component: {
      render(c) {
        return c("router-view");
      },
    },
    props: true,
    children: [
      {
        path: ":project_id/camera-configuration",
        name: "Camera Configuration",
        component: () => import("@/views/CameraConfiguration.vue"),
        meta: { title: "Camera Configuration • Config • Urban AI" },
      },
    ],
    meta: { requiresAuth: true },
    beforeEnter: beforeEnterProjectRoute,
  },
  {
    path: "/metrics",
    name: "Metrics",
    redirect: () => ({
      name: "Metric Configuration",
      params: { project_id: store.state.Project.activeProject.project_id },
    }),
    component: {
      render(c) {
        return c("router-view");
      },
    },
    props: true,
    children: [
      {
        path: ":project_id/metric-configuration",
        name: "Metric Configuration",
        component: () => import("@/views/MetricConfiguration.vue"),
        meta: { title: "Metric Configuration • Metrics • Urban AI" },
      },
    ],
    meta: { requiresAuth: true },
    beforeEnter: beforeEnterProjectRoute,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404Page"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const DEFAULT_TITLE = "Urban AI";
router.beforeEach((to, _from, next) => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title ?? DEFAULT_TITLE;
    next();
  });

  const requiresAuth = to.matched.some((record) => record?.meta?.requiresAuth);
  const hideForAuth = to.matched.some((record) => record?.meta?.hideForAuth);

  const validToken =
    isValidJwt(getJwt(ACCESS_TOKEN)) || isValidJwt(getJwt(REFRESH_TOKEN));

  // Navigation Guard for Protected Routes
  if (requiresAuth && !validToken) next({ name: "Login" });
  else if (hideForAuth && validToken) next({ name: "Dashboard" });
  else next();
});

export default router;
