<template>
  <svg
    fill="none"
    height="26"
    viewBox="0 0 26 26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M23.0164 9.36933H23.9843C24.3259 9.36933 24.6106 9.65401 24.6391 10.0241V10.8497C24.6391 11.1628 24.4113 11.419 24.0982 11.476L23.5858 11.5329C23.6427 11.7322 23.6712 11.9599 23.6712 12.1877V16.5717C23.6712 16.9134 23.358 17.198 23.0164 17.198H21.0236C20.7105 17.198 20.4543 16.9988 20.3973 16.6856L20.1127 15.2337H17.4936C17.5221 15.1483 17.5221 15.0345 17.5221 14.9491V14.1235C17.5221 13.2694 16.8388 12.5862 15.9848 12.5862H15.7286L15.4154 11.2197C15.33 10.7643 15.1308 10.3372 14.8745 9.99563H21.6784L21.1375 7.66125C21.0236 7.20576 20.6251 6.89261 20.1411 6.89261H13.8782C13.3942 6.89261 12.9957 7.20576 12.8818 7.66125L12.654 8.62916C12.4832 8.60069 12.3124 8.60069 12.1416 8.60069H11.1452L11.4299 7.31963C11.6861 6.18091 12.6825 5.38381 13.8497 5.38381H20.1127C21.2798 5.38381 22.2762 6.18091 22.5324 7.31963L23.0164 9.36933ZM22.2193 13.2979C22.3901 13.2979 22.5324 13.1556 22.5324 12.9848C22.5324 12.9065 22.5351 12.8148 22.538 12.7156C22.5445 12.4902 22.5522 12.2256 22.5324 11.9884C22.504 11.7891 22.3047 11.6468 22.1054 11.7037C21.8347 11.7775 21.5161 11.862 21.2094 11.9433C20.8066 12.0501 20.4243 12.1515 20.1981 12.2161C20.0842 12.2446 19.9988 12.33 19.9703 12.4439L19.828 12.9563C19.771 13.184 19.9419 13.3833 20.1696 13.3833L22.2193 13.2979Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="M15.0166 13.4687H15.9845C16.3546 13.4687 16.6393 13.7534 16.6108 14.095V14.9206C16.6108 15.2337 16.3831 15.49 16.0699 15.5469L15.5575 15.6323C15.6144 15.8316 15.6429 16.0593 15.6429 16.2871V20.6142C15.6429 20.9558 15.3582 21.2405 15.0166 21.2405H13.0238C12.7107 21.2405 12.4545 21.0412 12.3975 20.7281L12.1129 19.2762H5.84989L5.56521 20.7281C5.50827 21.0128 5.25206 21.2405 4.93891 21.2405H2.94615C2.60454 21.2405 2.31986 20.9558 2.31986 20.6142V16.2871C2.31986 16.0593 2.34832 15.8316 2.40526 15.6323L1.89284 15.5469C1.57969 15.5184 1.35194 15.2337 1.35194 14.9206V14.095C1.35194 13.7534 1.63662 13.4687 1.97824 13.4687H2.94615L3.43011 11.419C3.68632 10.2803 4.6827 9.48321 5.84989 9.48321H12.1129C13.28 9.48321 14.2764 10.2803 14.5326 11.419L15.0166 13.4687ZM5.87836 10.9351C5.3944 10.9351 4.99585 11.2767 4.88198 11.7322L4.31262 14.0666H13.6786L13.1377 11.7322C13.0238 11.2482 12.6253 10.9351 12.1413 10.9351H5.87836ZM3.77173 17.3973L5.82142 17.4827C6.04917 17.5112 6.21997 17.2834 6.19151 17.0272L6.04917 16.5433C6.0207 16.4294 5.93529 16.344 5.82142 16.3155C5.59514 16.2509 5.21285 16.1495 4.81002 16.0427C4.50335 15.9614 4.18477 15.8769 3.91407 15.8031C3.71479 15.7462 3.51551 15.8885 3.48704 16.0878C3.43011 16.4294 3.43011 16.828 3.45858 17.0842C3.45858 17.255 3.60092 17.3973 3.77173 17.3973ZM10.4332 17.3404C10.5471 17.3404 10.6325 17.255 10.6325 17.1411V16.771C10.6325 16.6572 10.5471 16.5717 10.4332 16.5717H7.55797C7.4441 16.5717 7.35869 16.6572 7.35869 16.771V17.1411C7.35869 17.255 7.4441 17.3404 7.55797 17.3404H10.4332ZM14.2195 17.3973C14.3903 17.3973 14.5326 17.255 14.5326 17.0842C14.5611 16.828 14.5611 16.4294 14.5326 16.1163C14.5042 15.917 14.3049 15.7746 14.1056 15.8316C13.8625 15.8979 13.5765 15.9728 13.2959 16.0462C12.8536 16.162 12.4247 16.2743 12.1983 16.344C12.0844 16.3725 11.999 16.4579 11.9705 16.5717L11.8282 17.0557C11.7712 17.2834 11.942 17.4827 12.1698 17.4827L14.2195 17.3973Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "ConflictIcon",
};
</script>

<style scoped></style>
