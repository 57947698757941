<template>
  <svg
    fill="none"
    height="26"
    viewBox="0 0 26 26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6558 20.6875L22.7132 11.772L14.5783 11.3147L9.27667 4.97136L5.71685 14.1443L13.6558 20.6875Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.08253"
    />
    <circle
      cx="5.81787"
      cy="14.2053"
      fill="currentColor"
      r="1.65596"
      transform="rotate(-149.101 5.81787 14.2053)"
    />
    <circle
      cx="9.05413"
      cy="5.1137"
      fill="currentColor"
      r="1.65596"
      transform="rotate(-149.101 9.05413 5.1137)"
    />
    <circle
      cx="14.4555"
      cy="10.8279"
      fill="currentColor"
      r="1.65596"
      transform="rotate(-149.101 14.4555 10.8279)"
    />
    <circle
      cx="22.5308"
      cy="12.0768"
      fill="currentColor"
      r="1.65596"
      transform="rotate(-149.101 22.5308 12.0768)"
    />
    <circle
      cx="13.4935"
      cy="20.7286"
      fill="currentColor"
      r="1.65596"
      transform="rotate(-149.101 13.4935 20.7286)"
    />
  </svg>
</template>

<script>
export default {
  name: "AreaIcon",
};
</script>
