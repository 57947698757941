<template>
  <v-container class="pa-0" fluid>
    <!--  Camera  -->
    <v-row class="py-1 px-4">
      <camera-icon :number="cameraIndex" :size="22" />
      <p class="px-2 my-0 body-1 font-weight-bold">{{ cameraName }}</p>
    </v-row>

    <!--  Camera Videos  -->
    <v-container class="pa-0" fluid>
      <video-upload-list-item
        v-for="({ filename, uploadPercentage, state }, index) in cameraVideos"
        :key="index"
        :label="filename"
        :state="state"
        :uploadProgress="uploadPercentage"
        class="my-5"
      />
    </v-container>
  </v-container>
</template>

<script>
import { VideoUploadListItem } from "@/components";
import { CameraIcon } from "@/components/icons";

export default {
  components: { VideoUploadListItem, CameraIcon },
  name: "VideoUploadList",
  props: {
    cameraName: {
      type: String,
      default: "",
    },
    cameraVideos: {
      type: Array,
      default: () => [],
    },
    cameraIndex: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>
