<template>
  <svg
    fill="none"
    height="26"
    viewBox="0 0 25 26"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3687 11.4374C12.6354 11.4795 12.9021 11.5357 13.1548 11.6199C13.239 11.648 13.3233 11.6059 13.3513 11.5357L13.9269 10.146C13.969 10.0477 13.9128 9.94944 13.8146 9.92137C13.3373 9.82311 12.86 9.76696 12.3828 9.76696C12.2985 9.76696 12.2143 9.83714 12.2143 9.93541V11.283C12.2283 11.3672 12.2985 11.4374 12.3687 11.4374Z"
      fill="currentColor"
    />
    <path
      d="M16.074 10.8619C15.6388 10.5811 15.1756 10.3565 14.6843 10.174C14.6 10.146 14.5158 10.1881 14.4737 10.2723L13.8701 11.7462C13.842 11.8305 13.8701 11.9147 13.9403 11.9568C14.1789 12.0831 14.4175 12.2235 14.6281 12.3779C14.6983 12.42 14.7825 12.42 14.8387 12.3639L16.088 11.1146C16.1722 11.0444 16.1582 10.918 16.074 10.8619Z"
      fill="currentColor"
    />
    <path
      d="M17.0007 11.5638C17.3657 11.8866 17.6885 12.2516 17.9693 12.6446C18.0254 12.7289 17.9974 12.8412 17.9131 12.8833L16.0181 13.6694C15.9479 13.7115 15.8637 13.6834 15.8215 13.6132C15.7092 13.4448 15.5969 13.2763 15.4566 13.1219C15.4004 13.0517 15.3443 12.9956 15.2881 12.9394C15.2179 12.8833 15.232 12.771 15.2881 12.7148L16.5936 11.4093C16.6498 11.3532 16.734 11.3392 16.8042 11.3953C16.8744 11.4515 16.9445 11.5076 17.0007 11.5638Z"
      fill="currentColor"
    />
    <path
      d="M19.1627 15.1714C19.0083 14.5537 18.7697 13.9642 18.4609 13.4167C18.4187 13.3465 18.3345 13.3184 18.2503 13.3465L16.285 14.1607C16.2008 14.1888 16.1587 14.287 16.2008 14.3713C16.3272 14.652 16.4114 14.9468 16.4816 15.2416C16.4956 15.3118 16.5658 15.3679 16.636 15.3679H18.9943C19.1066 15.3679 19.1768 15.2697 19.1627 15.1714Z"
      fill="currentColor"
    />
    <path
      d="M7.51145 11.8305C7.5676 11.7603 7.66586 11.7603 7.73605 11.8305L8.25543 12.3358C8.32562 12.406 8.31158 12.5183 8.2414 12.5745C8.19928 12.6025 8.14313 12.6446 8.10102 12.6868C7.86238 12.8973 7.65182 13.1219 7.4553 13.3606C7.41318 13.4167 7.34299 13.4307 7.27281 13.4027L6.73938 13.1781C6.64112 13.15 6.59901 13.0377 6.65516 12.9535C6.85168 12.6166 7.09032 12.2797 7.37107 11.9708L7.51145 11.8305Z"
      fill="currentColor"
    />
    <path
      d="M11.681 11.2268V9.99155C11.681 9.89328 11.5968 9.8231 11.4985 9.8231C11.0353 9.87925 10.5861 9.97751 10.1509 10.1319C10.0667 10.16 10.0246 10.2583 10.0526 10.3425L10.4738 11.3672C10.5018 11.4374 10.572 11.4795 10.6562 11.4655C10.937 11.4093 11.2318 11.3813 11.5125 11.3813C11.6108 11.3813 11.681 11.3111 11.681 11.2268Z"
      fill="currentColor"
    />
    <path
      d="M9.32266 10.4829C9.40689 10.4408 9.50515 10.4829 9.54726 10.5671L9.91224 11.4795C9.94031 11.5638 9.8982 11.662 9.81398 11.6901C9.51919 11.8024 9.2244 11.9287 8.94365 12.0972C8.87346 12.1253 8.80327 12.1253 8.74712 12.0691L8.14351 11.4655C8.07332 11.3953 8.08736 11.283 8.15755 11.2269C8.52252 10.9461 8.91557 10.6934 9.32266 10.4829Z"
      fill="currentColor"
    />
    <path
      d="M6.87971 13.8519L6.45859 13.6834C6.37436 13.6553 6.2761 13.6834 6.24802 13.7676C6.0515 14.2309 5.91112 14.7082 5.84094 15.1995C5.81286 15.2977 5.89709 15.396 5.99535 15.396H6.29014C6.36032 15.396 6.41647 15.3539 6.44455 15.2837C6.57089 14.8626 6.75337 14.4695 6.96394 14.0905C7.00605 14.0063 6.96394 13.894 6.87971 13.8519Z"
      fill="currentColor"
    />
    <path
      d="M12.3821 14.6099C12.9436 14.1747 13.7297 13.5711 14.3193 13.136C14.4456 13.0377 14.6141 13.1921 14.5018 13.3185C14.4585 13.3757 14.4135 13.4352 14.3672 13.4965C13.9481 14.0509 13.4197 14.75 13.0278 15.2556C13.084 15.382 13.1121 15.5223 13.1121 15.6627C13.1121 16.2944 12.6067 16.7998 11.975 16.7998C11.3433 16.7998 10.838 16.2944 10.838 15.6627C10.838 15.031 11.3433 14.5257 11.975 14.5257C12.1154 14.5257 12.2558 14.5678 12.3821 14.6099Z"
      fill="currentColor"
    />
    <path
      clip-rule="evenodd"
      d="M4.82767 4.83514C4.39916 4.83514 4.05178 5.18252 4.05178 5.61103V10.1086C4.05178 10.5371 3.7044 10.8845 3.27589 10.8845C2.84738 10.8845 2.5 10.5371 2.5 10.1086V5.61103C2.5 4.32549 3.54213 3.28336 4.82767 3.28336H9.32524C9.75376 3.28336 10.1011 3.63073 10.1011 4.05925C10.1011 4.48776 9.75376 4.83514 9.32524 4.83514H4.82767ZM14.8989 4.05925C14.8989 3.63073 15.2462 3.28336 15.6748 3.28336H20.1723C21.4579 3.28336 22.5 4.32549 22.5 5.61103V10.1086C22.5 10.5371 22.1526 10.8845 21.7241 10.8845C21.2956 10.8845 20.9482 10.5371 20.9482 10.1086V5.61103C20.9482 5.18252 20.6008 4.83514 20.1723 4.83514H15.6748C15.2462 4.83514 14.8989 4.48776 14.8989 4.05925ZM21.7241 15.6822C22.1526 15.6822 22.5 16.0296 22.5 16.4581V20.9557C22.5 22.2412 21.4579 23.2834 20.1723 23.2834H15.6748C15.2462 23.2834 14.8989 22.936 14.8989 22.5075C14.8989 22.079 15.2462 21.7316 15.6748 21.7316H20.1723C20.6008 21.7316 20.9482 21.3842 20.9482 20.9557V16.4581C20.9482 16.0296 21.2956 15.6822 21.7241 15.6822ZM3.27589 15.6822C3.7044 15.6822 4.05178 16.0296 4.05178 16.4581V20.9557C4.05178 21.3842 4.39916 21.7316 4.82767 21.7316H9.32524C9.75376 21.7316 10.1011 22.079 10.1011 22.5075C10.1011 22.936 9.75376 23.2834 9.32524 23.2834H4.82767C3.54214 23.2834 2.5 22.2412 2.5 20.9557V16.4581C2.5 16.0296 2.84738 15.6822 3.27589 15.6822Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "SpotSpeedIcon",
};
</script>
