<template>
  <v-card class="pa-4 py-8" rounded="lg" flat>
    <v-row>
      <!-- Navigate right -->
      <v-col align-self="center" class="pa-0" cols="auto">
        <u-button color="gray-6" icon @click="prev">
          <v-icon large>mdi-chevron-left</v-icon>
        </u-button>
      </v-col>
      <v-col class="pa-0">
        <!-- Banner image and placeholder -->
        <v-window v-model="index" continuous>
          <v-window-item v-for="(item, i) in items" :key="i">
            <v-container class="d-flex flex-column justify-center">
              <v-img :src="item.image" class="img rounded-lg">
                <template v-slot:placeholder>
                  <v-sheet
                    class="fill-height ma-0 d-flex align-center justify-center"
                    color="background"
                    rounded="lg"
                  >
                    <v-icon color="gray-6" x-large>fas fa-images</v-icon>
                  </v-sheet>
                </template>
              </v-img>
              <!-- Title and Description -->
              <v-row class="ma-0 px-0">
                <v-col class="pb-0" cols="12" md="8">
                  <h1 class="space-mono">{{ item.title }}</h1>
                  <p class="mb-0">{{ item.description }}</p>
                </v-col>
                <!-- Action Button -->
                <v-col
                  align-self="end"
                  class="pb-0 d-flex justify-end"
                  cols="12"
                  md="4"
                >
                  <u-button
                    :to="item.link"
                    class="text-capitalize"
                    color="secondary"
                  >
                    Know More
                  </u-button>
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>
      </v-col>
      <!-- Navigate right -->
      <v-col align-self="center" class="pa-0" cols="auto">
        <u-button color="gray-6" icon @click="next">
          <v-icon large>mdi-chevron-right</v-icon>
        </u-button>
      </v-col>
    </v-row>
    <!-- Toggle icon -->
    <v-card-actions class="justify-center pa-0">
      <v-item-group v-model="index" class="text-center" mandatory>
        <v-item
          v-for="n in length"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
          class="mx-1"
        >
          <v-icon
            :color="active ? 'secondary' : ''"
            :input-value="active"
            :size="active ? '14' : '10'"
            @click="toggle"
          >
            fas fa-circle
          </v-icon>
        </v-item>
      </v-item-group>
    </v-card-actions>
  </v-card>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "LoginCarousel",
  components: { UButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    length() {
      return this.items.length;
    },
  },
  data: () => ({
    index: 0,
  }),
  methods: {
    // Navigate right
    next: function () {
      this.index = this.index + 1 === this.length ? 0 : this.index + 1;
    },
    // Navigate left
    prev: function () {
      this.index = this.index - 1 < 0 ? this.length - 1 : this.index - 1;
    },
  },
};
</script>

<style scoped>
.img {
  height: 24em;
}
</style>
