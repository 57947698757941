import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"shadow ma-4",attrs:{"rounded":"lg","width":"300"}},[_c(VContainer,{staticClass:"pa-4"},[_c(VRow,{staticClass:"ma-2",attrs:{"align":"center"}},[_c('h1',{staticClass:"secondary--text"},[_vm._v("Set Time")]),_c(VSpacer),_c('u-button',{staticClass:"mx-2 py-4 rounded",attrs:{"color":"primary","x-small":""},on:{"click":_vm.submit}},[_c(VIcon,{attrs:{"color":"secondary","size":"18"}},[_vm._v("fas fa-check")])],1),_c('u-button',{staticClass:"py-4 rounded",attrs:{"color":"secondary","text":"","x-small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v("fas fa-times")])],1)],1),_c(VRow,{staticClass:"flex-nowrap ma-2 mt-0",attrs:{"align":"center"}},[_c(VContainer,{staticClass:"background pa-1 pb-0 rounded",attrs:{"fluid":""}},[_c('u-text-field',{attrs:{"large":"","max":"12","min":"1","placeholder":"hh","type":"number"},on:{"change":_vm.hourControl},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}})],1),_c('p',{staticClass:"mx-2 mb-1 text-h3 font-weight-bold"},[_vm._v(":")]),_c(VContainer,{staticClass:"background pa-1 pb-0 rounded",attrs:{"fluid":""}},[_c('u-text-field',{attrs:{"large":"","max":"60","min":"0","placeholder":"mm","type":"number"},on:{"change":_vm.minControl},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c(VCol,{staticClass:"pr-0"},[_c(VItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}},[_c(VItem,{attrs:{"value":"am"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('u-button',{staticClass:"rounded-t-lg secondary--text font-weight-bold body-1",attrs:{"color":active ? 'primary' : 'background',"small":"","tile":""},on:{"click":toggle}},[_vm._v(" AM ")])]}}])}),_c(VItem,{attrs:{"value":"pm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('u-button',{staticClass:"rounded-b-lg secondary--text font-weight-bold body-1",attrs:{"color":active ? 'primary' : 'background',"small":"","tile":""},on:{"click":toggle}},[_vm._v(" PM ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }