import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VTabs,{staticClass:"rounded-t-lg",attrs:{"center-active":"","color":"secondary","hide-slider":"","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._l((_vm.items),function(item,index){return _c(VTab,{key:index,staticClass:"d-flex justify-space-between background--gray-7 tab-border",class:{
        'rounded-tl-lg': index === 0,
        'rounded-tr-lg': index === _vm.items.length - 1,
      },attrs:{"href":_vm.identifier.length === 0
          ? ''
          : _vm.identifier === 'item'
          ? ("#" + item)
          : ("#" + (item[_vm.identifier])),"active-class":"background--secondary font-weight-bold"}},[_vm._t("tab",function(){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item))])]},null,{ item: item, index: index, active: _vm.isActive(item, index) })],2)}),_vm._t("additional-tabs")],2),_c(VTabsItems,{staticClass:"rounded-b-lg tab-item-border",model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._t("tab-items",function(){return _vm._l((_vm.items),function(item,index){return _c(VTabItem,{key:index,style:({
          height: _vm.contentHeight,
          overflowY: 'auto',
        }),attrs:{"value":_vm.identifier.length === 0
            ? ''
            : _vm.identifier === 'item'
            ? item
            : item[_vm.identifier]}},[_c(VSheet,{staticClass:"pa-4"},[_vm._t("tab-item",null,null,{ item: item, index: index })],2)],1)})},null,{ items: _vm.items }),_vm._t("additional-tab-items")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }