<template>
  <v-container class="ma-0 pa-0">
    <label
      :class="large ? 'body-1' : 'body-2'"
      class="font-weight-bold secondary--text"
    >
      {{ label }}
      <span v-if="required" class="accent--text font-weight-bold">*</span>
    </label>
    <v-textarea
      ref="textArea"
      v-model="model"
      :background-color="disabled ? 'disabled' : 'background'"
      :class="{ large }"
      class="input"
      color="secondary"
      flat
      hide-details="auto"
      no-resize
      solo
      v-bind="{
        rows,
        placeholder,
        readonly,
        disabled,
        large,
        ...$attrs,
      }"
    />
  </v-container>
</template>

<script>
export default {
  name: "UTextArea",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 4,
    },
    readonly: Boolean,
    disabled: Boolean,
    large: Boolean,
    required: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.textArea.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  font-weight: 600;
}

.large {
  font-size: 1.25em;
}

::v-deep textarea::placeholder {
  color: var(--v-placeholder-base) !important;
}
</style>
