import axios from "axios";
import { BASE_URL, ENDPOINTS } from "@/utils/const";
import { ACCESS_TOKEN, clearJwt, getJwt, setJwt } from "@/utils/index";
import ApiService from "@/services/ApiService";

const HTTP = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// List of endpoints that don't require access token
const noAuthHeaderList = [
  ENDPOINTS.LOGIN,
  ENDPOINTS.SIGNUP,
  ENDPOINTS.RESET_PASSWORD_LINK,
  ENDPOINTS.RESET_PASSWORD,
  ENDPOINTS.REFRESH, // This is the only endpoint that requires refresh token
];

HTTP.interceptors.request.use((config) => {
  // Add auth header to all authenticated requests
  if (!noAuthHeaderList.includes(config.url))
    config.headers["Authorization"] = AuthHeader();

  return config;
});

HTTP.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Original Request
    const config = error.config;

    // If status code was 401 and not refreshing token
    if (
      error.response.status === 401 &&
      !config._retry &&
      error.response.data.msg === "Token has expired"
    ) {
      config._retry = true;

      try {
        // API Call to refresh token
        let res = await ApiService.refreshToken();
        const accessToken = res.data["access_token"];
        // Update token
        if (accessToken) {
          setJwt(ACCESS_TOKEN, { accessToken });
          config.headers["Authorization"] = AuthHeader();
          // Retry original request with new token
          return axios.request(config);
        }
      } catch (e) {
        console.log(e);
        clearJwt();
        await this.$router.push({ name: "Login" });
      }
    }

    return Promise.reject(error);
  },
);

const AuthHeader = () => `Bearer ${getJwt(ACCESS_TOKEN)}`;

export { HTTP, AuthHeader };
