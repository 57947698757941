<template>
  <v-dialog
    v-model="model"
    class="ma-4"
    max-width="1024"
    persistent
    width="85%"
  >
    <v-card class="py-4" color="secondary" rounded="lg">
      <!--   Card Title   -->
      <v-card-title>
        <h2 class="font-weight-black white--text ma-0 space-mono text-h2">
          Are you sure?
        </h2>
        <v-spacer />
        <v-btn color="white" icon x-large @click="model = false">
          <v-icon color="white" large> fas fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <!--   Card Body   -->
      <v-card-text class="my-4 pb-0">
        <v-container class="pa-0">
          <p class="ma-0 white--text subtitle-2">
            Kindly cross-check entered date and time before starting upload. The
            following details can't be changed after starting upload.
          </p>
          <v-simple-table class="my-4 rounded-lg" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="{ text, border, innerClass, value } in tableColumns"
                    :key="value"
                    :class="[innerClass, border ? 'th-border' : '']"
                    class="subtitle-2 white--text"
                  >
                    {{ text }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(video, index) in videos" :key="index">
                  <td
                    v-for="{ border, innerClass, value } in tableColumns"
                    :key="value"
                    :class="[innerClass, border ? 'td-border' : '']"
                    class="subtitle-2"
                  >
                    {{ video[value] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card-text>

      <!-- Card Action Buttons -->
      <v-card-actions class="d-flex justify-end gap-1 mx-2">
        <u-button
          class="text-capitalize font-weight-bold white--text space-mono"
          color="white"
          large
          outlined
          @click="model = false"
        >
          Change Details
        </u-button>
        <u-button
          class="text-capitalize font-weight-bold secondary--text space-mono"
          color="white"
          large
          @click="$emit('click:startUpload')"
        >
          Start Upload
        </u-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";
import { mapGetters } from "vuex";

export default {
  name: "UploadConfirmationModal",
  components: { UButton },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      videos: "VideoUpload/filesToUpload",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    tableColumns: [
      {
        text: "Video Name",
        value: "filename",
        border: false,
        innerClass: "text-left",
      },
      {
        text: "Date",
        value: "date",
        border: true,
        innerClass: "text-center",
      },
      {
        text: "Time of Day",
        value: "time",
        border: false,
        innerClass: "text-center",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.th-border {
  border-right: 3px solid var(--v-gray-4-base);
  border-left: 3px solid var(--v-gray-4-base);
}

.td-border {
  border-left: 3px solid var(--v-disabled-base);
  border-right: 3px solid var(--v-disabled-base);
}

// Remove row separators in table
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

::v-deep .v-data-table__wrapper {
  border-radius: 7.5px;
}

::v-deep .v-data-table th {
  background-color: var(--v-gray-2-base) !important;
}
</style>
