<template>
  <v-expansion-panels flat multiple>
    <v-expansion-panel
      v-for="(camera, index) in sortedCameraVideos"
      :key="camera.camera_id"
    >
      <!--   Panel Header   -->
      <v-row align="center" justify="space-between">
        <v-col class="flex-grow-1" cols="auto">
          <v-expansion-panel-header>
            <p class="mb-0">
              <camera-icon :number="index" :size="22" />
              <span class="mx-2 subtitle-1 font-weight-bold">
                {{ camera.camera_name }}
              </span>
            </p>
            <template v-slot:actions>
              <v-icon color="secondary" small> $expand</v-icon>
            </template>
          </v-expansion-panel-header>
        </v-col>
        <v-col cols="auto">
          <!--   Action Buttons   -->
          <u-button
            class="rounded mr-2"
            color="gray-7"
            fab
            small
            tile
            @click="launchAssignPreviewModal(camera)"
          >
            <v-icon small>fas fa-pen</v-icon>
          </u-button>
          <u-button
            class="rounded mr-2"
            color="gray-7"
            fab
            small
            tile
            @click="launchDeleteConfirmationModal(camera)"
          >
            <v-icon small>fas fa-trash</v-icon>
          </u-button>
        </v-col>
      </v-row>

      <!--   Content (Videos)   -->
      <v-expansion-panel-content>
        <v-row>
          <u-camera-preview-tile
            :altText="camera.camera_name"
            :imageUrl="camera.camera_preview"
            class="my-4 pa-1"
          />
          <v-col
            v-for="(video, index) in camera.videos"
            :key="index"
            class="pa-0"
            cols="auto"
          >
            <u-camera-video-tile
              :altText="video.file_name"
              :date="video.start_date"
              :imageUrl="video.video_image_url"
              :time="video.start_time"
              :videoName="video.file_name"
              class="my-5"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!--  Modals  -->
    <assign-preview-modal
      v-if="showAssignPreviewModal"
      :selectedCameraId="selectedCamera.camera_id"
      @click:close="closePreviewModal"
    />
    <u-confirmation-modal
      v-model="showDeleteConfirmationModal"
      :body="`Are you sure you want to delete camera ${selectedCameraName}? This change cannot be undone`"
      action-name="delete"
      title="Delete Camera?"
      @click:confirmAction="deleteCamera"
    />
  </v-expansion-panels>
</template>

<script>
import { UButton } from "@/components/base";
import { CameraIcon } from "@/components/icons";
import { UCameraPreviewTile, UCameraVideoTile } from "@/components/common";
import { AssignPreviewModal, UConfirmationModal } from "@/components/modals";

export default {
  components: {
    UConfirmationModal,
    CameraIcon,
    UCameraPreviewTile,
    UCameraVideoTile,
    UButton,
    AssignPreviewModal,
  },

  name: "VideoLibraryCameraVideosList",
  props: {
    cameras: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    selectedCameraName() {
      return this.selectedCamera?.camera_name ?? "";
    },

    // sort videos of all cameras by video_start_time
    sortedCameraVideos() {
      const sortedVideosCameras = [...this.cameras];
      sortedVideosCameras.forEach((camera) => {
        camera.videos.sort((x, y) => {
          return new Date(x.video_start_time) - new Date(y.video_start_time);
        });
      });
      return sortedVideosCameras;
    },
  },
  data: () => ({
    selectedCamera: undefined,
    showAssignPreviewModal: false,
    showDeleteConfirmationModal: false,
  }),
  methods: {
    // Delete the selected camera
    deleteCamera() {
      this.$store.dispatch("Project/deleteCamera", this.selectedCamera);
      this.showDeleteConfirmationModal = false;
    },

    /**
     * Launches the assign preview modal
     * @param {Object} camera - Selected camera
     */
    launchAssignPreviewModal(camera) {
      this.selectedCamera = camera;
      this.showAssignPreviewModal = true;
    },

    /**
     * Launches the delete confirmation modal
     * @param camera - Selected camera
     */
    launchDeleteConfirmationModal(camera) {
      this.selectedCamera = camera;
      this.showDeleteConfirmationModal = true;
    },

    // Close the assign preview modal
    closePreviewModal() {
      this.selectedCamera = undefined;
      this.showAssignPreviewModal = false;
    },
  },
};
</script>
