import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"ma-4",attrs:{"max-width":"1024","persistent":"","width":"85%"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,{staticClass:"py-4",attrs:{"color":"secondary","rounded":"lg"}},[_c(VCardTitle,[_c('h2',{staticClass:"font-weight-black white--text ma-0 space-mono text-h2"},[_vm._v(" Are you sure? ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"white","icon":"","x-large":""},on:{"click":function($event){_vm.model = false}}},[_c(VIcon,{attrs:{"color":"white","large":""}},[_vm._v(" fas fa-times")])],1)],1),_c(VCardText,{staticClass:"my-4 pb-0"},[_c(VContainer,{staticClass:"pa-0"},[_c('p',{staticClass:"ma-0 white--text subtitle-2"},[_vm._v(" Kindly cross-check entered date and time before starting upload. The following details can't be changed after starting upload. ")]),_c(VSimpleTable,{staticClass:"my-4 rounded-lg",attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableColumns),function(ref){
var text = ref.text;
var border = ref.border;
var innerClass = ref.innerClass;
var value = ref.value;
return _c('th',{key:value,staticClass:"subtitle-2 white--text",class:[innerClass, border ? 'th-border' : '']},[_vm._v(" "+_vm._s(text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.videos),function(video,index){return _c('tr',{key:index},_vm._l((_vm.tableColumns),function(ref){
var border = ref.border;
var innerClass = ref.innerClass;
var value = ref.value;
return _c('td',{key:value,staticClass:"subtitle-2",class:[innerClass, border ? 'td-border' : '']},[_vm._v(" "+_vm._s(video[value])+" ")])}),0)}),0)]},proxy:true}])})],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end gap-1 mx-2"},[_c('u-button',{staticClass:"text-capitalize font-weight-bold white--text space-mono",attrs:{"color":"white","large":"","outlined":""},on:{"click":function($event){_vm.model = false}}},[_vm._v(" Change Details ")]),_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text space-mono",attrs:{"color":"white","large":""},on:{"click":function($event){return _vm.$emit('click:startUpload')}}},[_vm._v(" Start Upload ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }