<template>
  <v-container class="py-0 map-container" fluid>
    <UButton
      v-if="markers.length > 0"
      class="reset-action-btn secondary--text text-capitalize font-weight-bold"
      color="gray-7"
      @click="resetMap"
    >
      <v-icon class="mr-1" small>fas fa-undo</v-icon>
      Reset
    </UButton>
    <UButton
      class="
        style-toggle-btn
        secondary--text
        text-capitalize
        font-weight-bold
        rounded
      "
      color="background"
      fab
      x-small
      @click="toggleMapStyle"
    >
      <v-icon v-if="satelliteMap" size="12" small>fas fa-map</v-icon>
      <v-icon v-else size="12" small>fas fa-satellite</v-icon>
    </UButton>
    <v-row :id="`map-${uid}`" class="map fill-height"></v-row>
  </v-container>
</template>

<script>
import { default as MapboxGL } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { UButton } from "@/components/base";

export default {
  name: "UMap",
  components: { UButton },
  props: {
    center: {
      type: Array,
      default: () => [77.59796, 12.96991],
    },
    zoom: {
      type: [Number, String],
      default: 10,
    },
    allowMapSearch: Boolean,
    showSearchMarker: Boolean,
    showMarkerOnClick: Boolean,
    showNavigationControls: Boolean,
    showGeotagging: Boolean,
    mapAnnotations: {
      type: Array,
      default: () => [],
    },
    roiProjections: {
      type: Array,
      default: () => [],
    },

    // Camera
    showCameraMarker: Boolean,
    movableCameraMarker: {
      type: Boolean,
      default: false,
    },
    cameraCoordinates: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    center(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.map.setCenter(newValue);
        const markers = [...this.markers];
        markers.forEach((marker) => marker.remove());
        this.markers = [];
      }
    },
    cameraCoordinates(newValue) {
      if (this.showCameraMarker) {
        this.cameraMarker.setLngLat(newValue);
        this.map.setCenter(newValue);
      }
    },
  },
  computed: {
    mapStyle() {
      return this.satelliteMap
        ? "mapbox://styles/blkmamba7/cl2yqm8ew001f14p6zbys6r7p"
        : "mapbox://styles/blkmamba7/cl0tmynte000714pfonwnncuf";
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.map.remove();
  },
  data: () => ({
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY ?? "",
    map: undefined,
    geocoder: undefined,
    markers: [],
    uid: Date.now(),
    satelliteMap: true,
    cameraMarker: undefined,
  }),
  methods: {
    // Initialize MapBox map
    initMap() {
      MapboxGL.accessToken = this.accessToken;

      // Initialize MapBox Object
      this.map = new MapboxGL.Map({
        container: `map-${this.uid}`, // container ID
        style: this.mapStyle, // style URL
        center: this.center, // starting position [lng, lat]
        zoom: this.zoom, // starting zoom
        maxZoom: 24,
        pitch: 0,
        pitchWithRotate: false,
      });

      this.showNavigationControls && this.setupNavigationControls();

      this.allowMapSearch && this.initGeocoder();

      this.showSearchMarker && this.setupResultMarker();

      this.showMarkerOnClick && this.setupMapClickListener();

      this.showGeotagging && this.geotaggingFeature();

      this.showCameraMarker && this.setupCameraMarker();

      this.map.on("load", () => this.map.resize());
    },

    // Add Navigation Controls - Zoom buttons and compass
    setupNavigationControls() {
      const nav = new MapboxGL.NavigationControl();
      this.map.addControl(nav, "bottom-right");
    },

    // Initialize Geocoder (used for map searching)
    initGeocoder() {
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: MapboxGL,
        marker: false,
        placeholder: "Search",
        limit: 5,
        reverseGeocode: true,
        localGeocoder: this.coordinatesGeocoder,
      });
      this.map.addControl(this.geocoder);
    },

    // initialise Marker Config
    markerConfig() {
      return {
        color: this.$vuetify.theme.themes.light.secondary,
        scale: 0.8,
        draggable: true,
      };
    },

    // Setup Camera Marker
    setupCameraMarker() {
      this.cameraMarker = new MapboxGL.Marker({
        color: this.$vuetify.theme.themes.light.secondary,
        scale: 0.8,
        draggable: this.movableCameraMarker,
      })
        .setLngLat(this.cameraCoordinates)
        .addTo(this.map);

      this.cameraMarker.on("dragend", () => {
        const { lng, lat } = this.cameraMarker.getLngLat();
        this.onCameraMarkerUpdate([lng, lat]);
      });
    },

    // Setup Marker on Geocoder result
    setupResultMarker() {
      const geocoderMarker = new MapboxGL.Marker(this.markerConfig());

      this.geocoder.on("result", (e) => {
        geocoderMarker.setLngLat(e.result.center).addTo(this.map);
        const [lng, lat] = e.result.center;
        this.onSearchResult({ lng, lat });
      });

      geocoderMarker.on("dragend", () => {
        this.onSearchResult(geocoderMarker.getLngLat());
      });
    },

    // Draw marker on map
    drawMarker(markerData) {
      // Add marker at location
      const marker = new MapboxGL.Marker(this.markerConfig())
        .setLngLat(markerData.coordinates)
        .addTo(this.map);

      // Drag event listener for marker
      marker.on("dragend", () => {
        // Update target marker coordinates in markers list
        const index = this.markers.findIndex((ele) => ele.id === markerData.id);
        this.markers.splice(index, 1, {
          ...markerData,
          coordinates: marker.getLngLat(),
        });
        this.onMarkersUpdate();
      });
    },

    // Adds click event listener to the map
    setupMapClickListener() {
      this.map.on("click", (e) => {
        const markerData = { id: Date.now(), coordinates: e.lngLat };
        this.drawMarker(markerData);
        this.markers.push(markerData);
        this.onMarkersUpdate();
      });
    },

    // Setup GeoJSON to display geotagging
    geotaggingFeature() {
      this.map.on("style.load", () => {
        ["annotations", "projections"].forEach((type) => {
          this.map.addSource(type, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates:
                  type === "projections"
                    ? this.roiProjections
                    : this.mapAnnotations,
              },
            },
          });

          this.map.addLayer({
            id: type,
            type: "line",
            source: type,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color":
                type === "projections"
                  ? this.$vuetify.theme.themes.light.primary
                  : this.$vuetify.theme.themes.light.secondary,
              "line-width": 4,
            },
          });
        });
      });
    },

    /**
     * Show Lat, Long and Long,Lat options in map search results
     * @param query - MapBox Geocoder query string
     * @returns - GeocodeFeature in search results
     */
    coordinatesGeocoder(query) {
      // Match anything which looks like
      // decimal degrees coordinate pair.
      const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
      );
      if (!matches) {
        return null;
      }

      const coordinate1 = Number(matches[1]);
      const coordinate2 = Number(matches[2]);
      const geocodes = [];

      if (coordinate1 < -90 || coordinate1 > 90) {
        // must be lng, lat
        geocodes.push(this.coordinateFeature(coordinate1, coordinate2));
      }

      if (coordinate2 < -90 || coordinate2 > 90) {
        // must be lat, lng
        geocodes.push(this.coordinateFeature(coordinate2, coordinate1));
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(this.coordinateFeature(coordinate1, coordinate2));
        geocodes.push(this.coordinateFeature(coordinate2, coordinate1));
      }

      return geocodes;
    },

    /**
     * Generates MapBox feature for given coordinate
     * @param lng - Longitude
     * @param lat - Latitude
     * @returns {Object} GeoJSON
     */
    coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: "Lat: " + lat + " Lng: " + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    },

    /**
     * Bubble search result to parent
     * @param {Object} coordinates - LngLat object from Mapbox
     */
    onSearchResult(coordinates) {
      this.$emit("onSearchResult", coordinates);
    },

    // Bubble markers list to parent on update
    onMarkersUpdate() {
      this.$emit("markersUpdated", this.markers);
    },

    /**
     * Bubble camera coordinates to parent
     * @param {Object} coordinates - LngLat object from Mapbox
     */
    onCameraMarkerUpdate(coordinates) {
      this.$emit("onCameraMarkerUpdate", coordinates);
    },

    resetMap() {
      this.markers = [];
      this.onMarkersUpdate();
      this.map.remove();
      this.initMap();
    },

    updateMapAnnotations(markers) {
      this.map.remove();
      this.initMap();
      this.markers = markers;
      markers.forEach((marker) => this.drawMarker(marker));
    },

    // Toggle map style
    toggleMapStyle() {
      this.satelliteMap = !this.satelliteMap;
      this.map.setStyle(this.mapStyle);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  position: relative;
}

.reset-action-btn {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 3;
}

.style-toggle-btn {
  position: absolute;
  bottom: 2.5rem;
  left: 0.5rem;
  z-index: 3;
}

.map {
  min-height: 250px;
}

::v-deep .mapboxgl-ctrl-attrib-inner {
  display: none;
}

::v-deep .mapboxgl-ctrl-geocoder {
  font-family: "Urbanist", sans-serif;
}
</style>
