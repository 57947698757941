<template>
  <v-container
    :class="landscape ? 'landscape' : 'portrait'"
    class="mx-0 card-border"
  >
    <v-date-picker
      v-model="picker"
      :landscape="landscape"
      color="secondary"
      full-width
      header-color="secondary"
      v-on="$listeners"
    />
  </v-container>
</template>

<script>
export default {
  name: "UDatePicker",
  inheritAttrs: false,
  props: {
    landscape: Boolean,
  },
  data() {
    return {
      picker: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border-radius: 4px;
  outline: 2px solid var(--v-gray-7-base);
  padding: 0;
}

.portrait {
  width: 300px;
}

.landscape {
  width: 500px;
}
</style>
