<template>
  <v-sheet :color="color" class="pa-0" rounded="lg">
    <!-- Banner Image -->
    <v-img
      :max-height="'30vh'"
      :src="project.image_url"
      :width="'100%'"
      class="rounded-t-lg"
      min-height="300"
    >
      <template v-slot:placeholder>
        <v-sheet
          class="ma-0 fill-height d-flex align-center justify-center"
          color="background"
          rounded="t-lg"
        >
          <v-icon color="gray-6" large>fas fa-map</v-icon>
        </v-sheet>
      </template>
    </v-img>

    <v-container class="white--text">
      <!-- Title and Progress Bar -->
      <v-row class="ma-2 mb-0">
        <v-col class="pa-0" cols="12" sm="6">
          <h1 class="project-title text-wrap text-truncate">
            {{ project.name }}
          </h1>
        </v-col>
        <v-col align-self="center" class="pa-0 mb-2" cols="12" sm="6">
          <p class="mb-1 d-flex justify-space-between">
            <small> {{ project.description }}(ETA: ) </small>
            <small>60%</small>
          </p>
          <v-progress-linear color="light" height="8" rounded value="60" />
        </v-col>
      </v-row>
      <!-- end -->
      <!-- Activity and Tasks -->
      <v-row class="ma-2 mt-0">
        <v-col align-self="center" class="pa-0" cols="12" sm="6">
          <p class="mb-sm-0 small-line">
            <small>Last activity done: {{ project.created_at }}</small>
            <br />
            <small>3 widgets added and 2 excel sheets generated</small>
          </p>
        </v-col>
        <v-col align-self="center" class="pa-0" cols="12" sm="6">
          <v-row
            align="center"
            class="my-sm-1"
            justify="space-between"
            justify-md="end"
          >
            <p class="mb-0 small-line mx-2">
              <small>
                3/5 tasks completed
                <br />
                Time Spent: 13h 23m
              </small>
            </p>
            <u-button
              :class="`${color}--text`"
              class="text-capitalize font-weight-bold space-mono mx-2"
              color="light"
              @click="resumeProject"
            >
              Resume Project
            </u-button>
          </v-row>
        </v-col>
      </v-row>
      <!-- end -->
    </v-container>
  </v-sheet>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UProjectCard",
  components: { UButton },
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    resumeProject() {
      this.$store.dispatch("Project/clearProjectState");
      this.$store.commit("Project/storeProjectDetails", this.project);
      this.$store.dispatch(
        "Project/getProjectCameras",
        this.project.project_id,
      );
      this.$router.push({
        name: "Project Details",
        params: { project_id: this.project.project_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.project-title {
  font-size: 2.25rem;
}

.small-line {
  line-height: 1.1em;
}
</style>
