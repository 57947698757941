<template>
  <svg
    fill="none"
    height="26"
    viewBox="0 0 26 26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M21.2717 1.25183C21.9622 1.25183 22.522 1.81033 22.5222 2.50133V6.25183C22.5222 6.94258 21.9622 7.50183 21.2717 7.50133C20.5817 7.50183 20.0222 6.94258 20.022 6.25183V5.51933L9.80696 15.7338C9.56371 15.9771 9.24371 16.0996 8.92346 16.0996C8.60396 16.0996 8.28396 15.9776 8.03971 15.7328C7.55146 15.2446 7.55121 14.4536 8.03921 13.9653L18.254 3.75133H17.522C16.8315 3.75183 16.2712 3.19133 16.2712 2.50183C16.2712 1.81083 16.8312 1.25233 17.5212 1.25183H21.2717ZM1.63872 20.7336C1.15047 20.2453 1.14997 19.4548 1.63822 18.9661L4.28996 16.3143C4.77821 15.8261 5.56972 15.8261 6.05696 16.3148C6.54571 16.8021 6.54571 17.5936 6.05796 18.0823L5.53996 18.5998H22.393C22.7375 18.5998 23.0502 18.7396 23.2762 18.9661C23.5025 19.1921 23.6425 19.5046 23.6425 19.8503C23.6425 20.5408 23.0832 21.1003 22.3925 21.1003H5.53971L6.05721 21.6178C6.54571 22.1056 6.54572 22.8981 6.05847 23.3853C5.56972 23.8741 4.77871 23.8736 4.29071 23.3853L1.63872 20.7336ZM8.52272 9.75183C9.21372 9.75158 9.77222 9.19183 9.77222 8.50133V4.75083C9.77172 4.06083 9.21322 3.50083 8.52222 3.50083C7.83272 3.50083 7.27222 4.06108 7.27272 4.75158V5.48358L3.41547 1.62558C2.92722 1.13758 2.13622 1.13783 1.64797 1.62608C1.40322 1.87033 1.28122 2.19033 1.28122 2.50983C1.28122 2.83008 1.40372 3.15008 1.64697 3.39333L5.50497 7.25158H4.77247C4.08172 7.25183 3.52247 7.81133 3.52297 8.50133C3.52247 9.19183 4.08147 9.75183 4.77247 9.75183H8.52272ZM23.4055 12.2163C23.8937 12.7046 23.8947 13.4951 23.4065 13.9841L20.7547 16.6356C20.266 17.1243 19.475 17.1236 18.9867 16.6351C18.499 16.1476 18.4985 15.3563 18.9867 14.8678L19.5047 14.3501L16.62 14.3498C16.2757 14.3496 15.9627 14.2101 15.7362 13.9838C15.5107 13.7578 15.3705 13.4453 15.3705 13.0996C15.371 12.4088 15.93 11.8496 16.6205 11.8493L19.5042 11.8496L18.9867 11.3321C18.4985 10.8446 18.4985 10.0518 18.9862 9.56433C19.4745 9.07558 20.2655 9.07683 20.7537 9.56433L23.4055 12.2163Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "FlowIcon",
};
</script>
