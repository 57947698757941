<template>
  <v-sheet
    color="gray-1"
    min-width="305"
    rounded="lg"
    style="position: relative"
  >
    <!--  Static Map  -->
    <v-img
      :lazy-src="lazy_source"
      :max-height="200"
      :min-height="150"
      :src="source"
      class="rounded-t-lg"
    >
      <template v-slot:placeholder>
        <v-sheet
          class="fill-height ma-0 d-flex align-center justify-center"
          color="background"
          rounded="t-lg"
        >
          <v-icon color="gray-7" x-large>fas fa-map</v-icon>
        </v-sheet>
      </template>
    </v-img>

    <!--  Overlay  -->
    <div v-if="active" class="custom-overlay rounded-t-lg">
      <v-icon color="white" size="72">fas fa-check</v-icon>
    </div>

    <!--  Project Details  -->
    <v-container class="white--text">
      <v-row class="flex-nowrap" justify="space-between">
        <v-col cols="auto">
          <h3 class="space-mono text-truncate">{{ projectName }}</h3>
          <p class="mt-1 mb-0 body-2">Created : {{ createdOn }}</p>
        </v-col>
        <v-col cols="auto">
          <v-icon color="white">fas fa-chevron-right</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "UProjectTile",
  props: {
    source: {
      type: String,
      default: "",
      required: true,
    },
    lazy_source: {
      type: String,
      default: "",
    },
    projectName: {
      type: String,
      default: "Project Name",
      required: true,
    },
    active: Boolean,
    createdOn: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.custom-overlay {
  position: absolute;
  inset: 0;
  max-height: 200px;
  display: grid;
  place-items: center;
  z-index: 2;
  background: rgba(3, 23, 39, 0.6);
}
</style>
