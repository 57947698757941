<template>
  <v-container class="ma-0 pa-0">
    <label
      :class="large ? 'body-1' : 'body-2'"
      class="font-weight-bold secondary--text"
    >
      {{ label }}
      <span v-if="required" class="accent--text font-weight-bold">*</span>
    </label>
    <v-text-field
      ref="textField"
      v-model="model"
      :background-color="disabled ? 'disabled' : 'background'"
      :class="{ large }"
      :disabled="disabled"
      class="input"
      color="secondary"
      flat
      solo
      v-bind="{
        placeholder,
        type,
        hideDetails,
        prependInnerIcon,
        appendIcon,
        dense,
        readonly,
        error,
        ...$attrs,
      }"
      v-on="$listeners"
    >
      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>
      <template #append>
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </v-container>
</template>

<script>
export default {
  name: "UTextField",
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: [Boolean, String],
      default: "auto",
    },
    large: Boolean,
    dense: Boolean,
    readonly: Boolean,
    error: Boolean,
    disabled: Boolean,
    required: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.textField.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  font-weight: 600;
}

.large {
  font-size: 1.25rem;
}

::v-deep input::placeholder {
  color: var(--v-placeholder-base) !important;
  font-weight: 500;
}

::v-deep .theme--light.v-input input {
  color: var(--v-secondary-base);
}
</style>
