import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VItemGroup,{attrs:{"multiple":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.clusterItems),function(item,index){return _c(VItem,{key:index,attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('u-button',{staticClass:"ma-1",attrs:{"color":active ? 'secondary' : 'gray-7'},on:{"click":toggle}},[_c(VIcon,{attrs:{"color":active ? 'white' : 'secondary',"size":"18"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-bold mx-2 text-capitalize body-1",class:active ? 'white--text' : 'secondary--text'},[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }