<template>
  <v-dialog
    v-model="model"
    width="500"
    overlay-color="rgb(244,244,244)"
    overlay-opacity="0.75"
    persistent
  >
    <v-card color="white" rounded="lg">
      <v-row class="ma-0" justify="space-between" align="center">
        <v-col cols="auto">
          <h1
            class="font-weight-bold secondary--text py-3 px-5 ma-0 space-mono"
          >
            Summary
          </h1>
        </v-col>

        <v-col cols="auto" class="mx-2">
          <v-btn @click="model = false" icon color="secondary">
            <v-icon color="secondary" size="30"> fas fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text class="secondary--text">
        <v-row
          class="px-4 py-2 body-2 font-weight-bold"
          justify="space-between"
          align="center"
        >
          <p class="mb-0">Camera Name</p>
          <p class="mb-0 pb-0">Credits</p>
        </v-row>

        <v-row
          v-for="(camera, index) in cameras"
          :key="index"
          class="font-weight-bold subtitle-1 px-2 border"
        >
          <v-col>
            {{ camera.activeCamera.camera_name }}
            <p class="body-1 mb-1 font-weight-regular">
              {{ camera.videoList.length }} videos included
            </p>
            <v-list-item-group
              class="body-2 font-weight-medium"
              v-for="(video, index) in camera.videoList"
              :key="index"
            >
              {{ video.file_name }}
            </v-list-item-group>
          </v-col>

          <v-col cols="auto" class="px-0"> 50C </v-col>
        </v-row>
        <v-row justify="end" class="font-weight-bold my-2 border">
          <v-col cols="4" class="body-2" align-self="center">
            Total credits used
          </v-col>
          <v-col cols="2" class="subtitle-1"> 105C </v-col>
        </v-row>
        <!-- Action Buttons -->
        <v-row justify="end" class="py-2">
          <u-button
            class="
              text-capitalize
              font-weight-bold
              secondary--text
              space-mono
              mx-2
            "
            color="white"
            large
            outlined
            @click="model = false"
          >
            Cancel
          </u-button>
          <u-button
            class="text-capitalize font-weight-bold space-mono"
            :color="processLoader ? 'gray-1' : 'primary'"
            :loadState="processLoader"
            :disbled="processLoader"
            :class="processLoader ? 'white--text' : 'secondary--text'"
            large
            @click="$emit('click:start-processing')"
          >
            Start Processing
          </u-button>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "ProcessSummaryModal",
  components: {
    UButton,
  },
  data: () => ({
    // Placeholder data to be replaced by API call
    videos: ["Video 1", "Video 2", "Video 3"],
  }),
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    cameras: {
      type: Array,
      default: () => [],
    },
    processLoader: { type: Boolean, default: false },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.border {
  border-bottom: 2.5px solid var(--v-background-base);
}
</style>
