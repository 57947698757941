<template>
  <v-breadcrumbs :items="items" class="pa-0">
    <template v-slot:divider>
      <v-icon color="secondary" small>fas fa-caret-right</v-icon>
    </template>

    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.href"
        active-class="font-weight-bold"
        class="text-link"
      >
        <span class="secondary--text body-1">{{ item.text }}</span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "UBreadcrumbs",
  props: {
    items: {
      // Array of Objects - { href: "", title: "" }
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>

<style scoped>
.text-link:hover {
  text-decoration: underline;
}
</style>
