import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":'75%',"max-width":"800","overlay-color":"#969ea5","overlay-opacity":"0.5","persistent":"","value":"true"}},[_c('u-button',{staticClass:"my-2 float-end",attrs:{"color":"secondary","fab":"","flat":"","small":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_c(VIcon,[_vm._v("fas fa-times")])],1),_c(VSheet,{staticClass:"overflow-hidden px-8",attrs:{"color":"transparent","rounded":"lg"}},[_c(VDataTable,{attrs:{"footer-props":{
        'items-per-page-options': [5, 10, 15],
      },"headers":_vm.headers,"items":_vm.metricResults,"fixed-header":"","flat":"","multi-sort":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }