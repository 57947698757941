<template>
  <v-sheet
    class="
      card-border
      pa-4
      py-6
      rounded-lg
      secondary--text
      d-flex
      flex-column
      justify-space-between
    "
    outlined
  >
    <!--    Title    -->
    <v-row align="center" class="mx-2 flex-nowrap">
      <v-icon class="icon" color="secondary">fas fa-film</v-icon>
      <p class="mx-4 mb-0 filename text-truncate">
        {{ filename }}
      </p>
      <v-spacer></v-spacer>
      <u-button color="secondary" icon @click="$emit('delete-video')">
        <v-icon small>fas fa-trash</v-icon>
      </u-button>
    </v-row>

    <!--    Map   -->
    <p class="ma-2 mt-8 body-2 font-weight-bold secondary--text">
      Location assigned
    </p>
    <v-sheet
      class="ma-2 mb-4 fill-height overflow-hidden flex-grow-1"
      color="background"
      rounded
    >
      <u-map
        :camera-coordinates="cameraCoordinates"
        :center="mapCenter"
        class="fill-height"
        show-camera-marker
        zoom="16"
      ></u-map>
    </v-sheet>

    <!--    Camera Selector    -->
    <v-row align="end" class="ma-2 flex-nowrap" justify="space-between">
      <u-dropdown
        v-model="assignedCamera"
        :items="projectCameras"
        class="mr-2"
        dense
        item-text="camera_name"
        label="Camera assigned"
        offset-y
        placeholder="Select a camera"
        return-object
      />
      <u-button
        class="text-capitalize font-weight-bold secondary--text rounded"
        color="gray-7"
        @click="$emit('click:newCamera')"
      >
        <v-icon class="mr-2" small>fas fa-video</v-icon>
        Add New
      </u-button>
    </v-row>

    <v-row
      align="center"
      class="ma-2 flex-nowrap gap-2"
      justify="space-between"
    >
      <!--    Date    -->
      <u-date-field
        ref="date"
        v-model="videoDate"
        :custom-rules="customRules"
        dense
      />

      <!--    Time    -->
      <u-time-field
        ref="time"
        v-model="videoTime"
        :custom-rules="customRules"
        dense
      />
    </v-row>

    <v-row class="ma-1" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="gray-7"
        @click="save"
      >
        Save
      </u-button>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  UButton,
  UDateField,
  UDropdown,
  UMap,
  UTimeField,
} from "@/components/base";
import { mapGetters, mapState } from "vuex";

export default {
  name: "VideoSetupVideoDetails",
  components: {
    UMap,
    UTimeField,
    UDropdown,
    UButton,
    UDateField,
  },
  props: {
    videoDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    videoDetails(newValue) {
      this.init(newValue);
    },
    validationDependencies() {
      this.checkDuplicateDateTime();
    },
  },
  computed: {
    ...mapGetters("VideoUpload", ["getFilesToUploadByCamera"]),
    ...mapState("Project", ["activeProject"]),
    projectCameras() {
      return this.$store.state.Project.projectCameras ?? [];
    },
    mapCenter() {
      return [
        this.activeProject.coordinates.long,
        this.activeProject.coordinates.lat,
      ];
    },
    cameraCoordinates() {
      const { lat = this.mapCenter[1], lon = this.mapCenter[0] } =
        this.projectCameras.find(
          (camera) => camera.camera_id === this.assignedCamera.camera_id,
        )?.coordinates ?? {};
      return [lon, lat];
    },
    validationDependencies() {
      const { videoDate, videoTime, assignedCamera, filename } = this;
      return { videoDate, videoTime, assignedCamera, filename };
    },
  },
  mounted() {
    this.init(this.videoDetails);
  },
  data() {
    return {
      // Local variables
      filename: "",
      assignedCamera: "",
      image: "",
      videoDate: undefined,
      videoTime: undefined,
      error: false,
      customRules: [
        () =>
          this.validDateTime ||
          "Another video exists with the same date and time",
      ],
      validDateTime: true,
    };
  },
  methods: {
    // Save changes in video details
    save() {
      this.validateForm();
      !this.error && this.updateSessionStore();
    },

    // Check if a video with the same date and time exists
    checkDuplicateDateTime() {
      let validDateTime = true;
      if (this.videoDate.length > 0 && this.videoTime.length > 0) {
        const dateObj = new Date(`${this.videoDate} ${this.videoTime}`);
        // Check duplicate date-time in uploaded videos
        const selectedCamera = this.$store.state.Project.projectVideos.find(
          (camera) => camera.camera_id === this.assignedCamera.camera_id,
        );
        if (selectedCamera) {
          for (const video of selectedCamera.videos) {
            const videoDate = new Date(video.video_start_time);
            if (videoDate.getTime() === dateObj.getTime()) {
              validDateTime = false;
              break;
            }
          }
        }
        // Check date-time duplicates in other videos to be uploaded
        const filesToUpload = this.getFilesToUploadByCamera[
          selectedCamera.camera_id
        ]?.videos.filter((video) => video.filename !== this.filename);
        if (filesToUpload) {
          for (const video of filesToUpload) {
            const fileDate = new Date(`${video.date} ${video.time}`);
            if (fileDate.getTime() === dateObj.getTime()) {
              validDateTime = false;
              break;
            }
          }
        }
      }
      this.validDateTime = validDateTime;
      this.validateForm();
    },
    // Validate Date and Time fields
    validateForm() {
      const dateError = !this.$refs.date.$refs.form.validate();
      const timeError = !this.$refs.time.$refs.form.validate();
      this.error = dateError || timeError || !this.validDateTime;
    },

    // Update Vuex with changes
    updateSessionStore() {
      let payload = { ...this.videoDetails };
      payload.date = this.videoDate;
      payload.time = this.videoTime;
      payload.camera = this.assignedCamera.camera_name;
      payload.camera_id = this.assignedCamera.camera_id;
      payload.error = this.error;
      this.$store.commit("VideoUpload/updateFileToUpload", payload);
    },

    /**
     * Initialize local variables with prop value
     * @param newValue - values to initialize with
     */
    init(newValue) {
      this.filename = newValue.filename;
      this.assignedCamera = {
        camera_name: newValue.camera,
        camera_id: newValue.camera_id,
      };
      this.image = newValue.imageUrl;
      this.videoDate = newValue.date;
      this.videoTime = newValue.time;
      if (newValue.error) {
        this.validateForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}

.filename {
  font-size: clamp(1.6rem, 1.75vw, 1.75rem);
  font-weight: 600;
  line-height: 110%;
}

.icon {
  font-size: clamp(36px, 3vw, 48px);
}
</style>
