import { getStaticMapImageUrl } from "@/utils";
import ApiService from "@/services/ApiService";

const getInitialState = () => ({
  projects: [],
  selectedTemplate: {},
});

const state = getInitialState();

const mutations = {
  SET_PROJECTS(state, data) {
    state.projects = data;
  },
  // Store selected template object
  SET_SELECTED_TEMPLATE(state, payload) {
    state.selectedTemplate = payload;
  },

  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  // Get all projects of the user
  async getProjects({ commit, dispatch }) {
    try {
      const res = await ApiService.getAllProjects();
      const projectList = res.data.map((project) => ({
        ...project,
        image_url: getStaticMapImageUrl(project.coordinates, "300x200"),
      }));
      // Sort projects by modified date
      projectList.sort(
        (a, b) => new Date(b.modified_at) - new Date(a.modified_at),
      );
      commit("SET_PROJECTS", projectList);
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        { text: "Error fetching projects!", color: "error" },
        { root: true },
      );
    }
  },

  async createProject({ commit, dispatch }, payload) {
    try {
      const res = await ApiService.createProject(payload);
      const newProject = {
        project_id: res.data.project_id,
        ...payload,
      };
      await commit("Project/storeProjectDetails", newProject, { root: true });
      dispatch(
        "Notification/addNotification",
        { text: `Project ${newProject.name} created!`, color: "success" },
        { root: true },
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "Notification/addNotification",
        { text: "Error creating project!", color: "error" },
        { root: true },
      );
    }
  },

  /**
   * Delete projects
   * @param dispatch - Vuex dispatch
   * @param {Array} projects - Array of projects to delete
   */
  async deleteProjects({ dispatch }, projects) {
    const promises = projects.map(({ project_id }) =>
      ApiService.deleteProject(project_id)
        .then(() => {
          dispatch(
            "Notification/addNotification",
            { text: `Project ${name} deleted!`, color: "success" },
            { root: true },
          );
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            "Notification/addNotification",
            { text: `Error deleting project ${name}!`, color: "error" },
            { root: true },
          );
        }),
    );
    await Promise.allSettled(promises);
    dispatch("getProjects");
  },

  // Reset state
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
