<template>
  <v-sheet :color="active ? 'secondary' : 'background'" class="my-2" rounded>
    <v-row
      align="center"
      align-content="center"
      class="ma-1 font-weight-bold subtitle-2"
      justify="space-between"
    >
      <!-- Video Name -->
      <v-col :class="{ 'white--text': active }" class="pa-2" cols="auto">
        <v-icon :color="active ? 'white' : 'secondary'" medium>
          fas fa-film
        </v-icon>
        {{ label }}
      </v-col>

      <!--  Date Time Validation Indicators  -->
      <v-col class="pa-0" cols="auto">
        <v-row class="ma-0">
          <v-sheet
            :color="dateColor"
            class="d-flex justify-center align-items-center"
            height="50"
            rounded
            width="50"
          >
            <v-icon :color="dateIconColor"> fas fa-calendar-day</v-icon>
          </v-sheet>
          <v-sheet
            :color="timeColor"
            class="d-flex justify-center align-items-center"
            height="50"
            rounded
            width="50"
          >
            <v-icon :color="timeIconColor"> fas fa-clock</v-icon>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "VideoSetupCameraVideosListItem",
  data: () => ({}),
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    date: {
      type: String,
      default: "",
      required: true,
    },
    time: {
      type: String,
      default: "",
      required: true,
    },
    validation: Boolean,
    active: Boolean,
  },
  computed: {
    dateColor() {
      if (this.validation) {
        return this.date.length ? "primary" : "error";
      } else {
        return this.active ? "secondary" : "background";
      }
    },
    timeColor() {
      if (this.validation) {
        return this.time.length ? "primary" : "error";
      } else {
        return this.active ? "secondary" : "background";
      }
    },
    dateIconColor() {
      if (this.validation || (!this.active && this.date.length)) {
        return "secondary";
      }
      return this.active ? (this.date.length ? "white" : "gray-4") : "gray-6";
    },
    timeIconColor() {
      if (this.validation || (!this.active && this.time.length)) {
        return "secondary";
      }
      return this.active ? (this.time.length ? "white" : "gray-4") : "gray-6";
    },
  },
};
</script>
