<template>
  <v-dialog
    :width="'75%'"
    height="80%"
    max-height="800"
    max-width="1200"
    overlay-color="#969ea5"
    overlay-opacity="0.5"
    persistent
    value="true"
  >
    <v-sheet
      class="pa-6 px-8 secondary--text"
      color="white"
      height="80%"
      max-height="800"
      rounded="lg"
    >
      <!--   Title   -->
      <v-row align="center" class="mx-2">
        <v-icon
          v-if="$vuetify.breakpoint.lgAndUp"
          class="mr-4"
          color="secondary"
          size="44"
        >
          fas fa-video
        </v-icon>
        <h1 class="heading-4 space-mono text-wrap text-break">
          Assign Preview
        </h1>
        <v-spacer />
        <u-button color="secondary" icon @click="$emit('click:close')">
          <v-icon>fas fa-times</v-icon>
        </u-button>
      </v-row>

      <p class="pa-2 mb-0 body-1 font-weight-bold">
        Browse and select the ideal camera preview here
      </p>

      <v-row>
        <!--  Video List  -->
        <v-sheet
          :class="$vuetify.breakpoint.mdAndDown ? 'py-4 mx-4' : 'my-4 px-4'"
          :max-height="'60vh'"
          class="overflow-auto"
          cols="12"
          lg="auto"
        >
          <v-item-group
            v-model="model"
            :class="{
              'd-flex flex-row': $vuetify.breakpoint.mdAndDown,
            }"
            mandatory
          >
            <v-item
              v-for="video in videos"
              :key="video.id"
              v-slot="{ active, toggle }"
              :value="video"
              class="ma-2"
            >
              <u-camera-preview-tile
                :active="active"
                :image-url="video.camera_image"
                hide-label
                @click.native="toggle"
              >
              </u-camera-preview-tile>
            </v-item>
          </v-item-group>
        </v-sheet>

        <!--  Selected Image Preview  -->
        <v-col>
          <v-img
            :aspect-ratio="16 / 9"
            :src="model.camera_image"
            class="rounded-lg ma-4"
          >
            <!-- Image placeholder-->
            <template #placeholder>
              <v-sheet
                class="fill-height d-flex align-center justify-center"
                color="background"
                max-height="150"
                rounded="lg"
              >
                <v-icon color="gray-5" x-large>fa-photo-video</v-icon>
              </v-sheet>
            </template>
          </v-img>
          <v-row class="mt-2 mx-4" justify="space-between">
            <p class="body-1 font-weight-bold mb-0">
              {{ model.video_file_name }}
            </p>

            <!--  Action Button  -->
            <u-button
              class="secondary--text text-capitalize font-weight-bold"
              color="primary"
              @click="assignCameraImage"
            >
              Assign
            </u-button>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";
import { UCameraPreviewTile } from "@/components/common";
import ApiService from "@/services/ApiService";

export default {
  name: "AssignPreviewModal",
  components: { UCameraPreviewTile, UButton },
  props: {
    selectedCameraId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    model: {},
    videos: [],
  }),
  mounted() {
    this.fetchAllVideoImages();
  },
  methods: {
    /**
     * Assign preview image to the selected camera
     */
    async assignCameraImage() {
      const params = { video_id: this.model.video_id };
      try {
        await ApiService.assignCameraImage(this.selectedCameraId, params);
        await this.$store.dispatch("Project/getProjectVideos");
        await this.$store.dispatch("Notification/addNotification", {
          text: "Camera preview updated!",
          color: "success",
        });
      } catch (e) {
        console.log(e);
        this.store.dispatch("Notification/addNotification", {
          text: "Failed to update camera preview!",
          color: "error",
        });
      }
      this.$emit("click:close");
    },

    /**
     * Fetch all video images for the selected camera
     */
    async fetchAllVideoImages() {
      try {
        const res = await ApiService.getCameraVideoImages(
          this.selectedCameraId,
        );
        this.videos = res.data;
      } catch (e) {
        console.log(e);
        this.store.dispatch("Notification/addNotification", {
          text: "Failed to fetch video previews!",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: 0 0 30px rgba(3, 23, 39, 0.15);
}
</style>
