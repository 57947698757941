<template>
  <v-menu class="menu" max-width="175" offset-y>
    <template v-slot:activator="{ on }">
      <v-card
        id="nav-menu"
        :ripple="false"
        class="mx-1 px-4 d-flex align-center fill-height"
        elevation="0"
        tile
        v-on="on"
      >
        <p class="mb-0 mr-2 text-right dropdown-text">
          <span class="font-weight-black">{{ name }}</span>
          <br />
          <small>{{ credits }}</small>
        </p>
        <v-avatar class="mr-2 avatar" size="36"></v-avatar>
        <v-icon class="icon" x-small>fas fa-chevron-down</v-icon>
      </v-card>
    </template>

    <v-list class="border py-0 rounded-b-lg">
      <v-list-item
        v-for="(item, index) in menuOptions"
        :key="index"
        :to="item.link"
        link
      >
        <v-list-item-title class="list-item-text">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        class="accent-link list-item-text rounded-b-lg"
        link
        @click="logout"
      >
        <span>Log Out</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { clearJwt } from "@/utils";
import { mapActions } from "vuex";

export default {
  name: "UTopNavMenu",
  data: () => ({
    menuOptions: [
      {
        title: "Account",
        link: "",
      },
      {
        title: "Manage Projects",
        link: "",
      },
      {
        title: "Credits",
        link: "",
      },
      {
        title: "Preferences",
        link: "",
      },
    ],
    name: "John Doe",
    credits: "3500C",
  }),
  methods: {
    ...mapActions(["resetAllState"]),
    logout() {
      clearJwt();
      this.resetAllState();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
#nav-menu {
  background-color: var(--v-secondary-base);
  color: white;
  border-inline: 2px solid var(--v-secondary-base);

  .icon {
    color: white;
  }

  .avatar {
    background-color: var(--v-primary-base);
  }

  &:hover {
    color: white;
    background-color: var(--v-gray-2-base);
  }

  &:focus {
    color: var(--v-secondary-base);
    background-color: var(--v-primary-base);

    .icon {
      color: var(--v-secondary-base);
    }

    .avatar {
      background-color: var(--v-secondary-base);
    }
  }
}

.border {
  border: 1px solid var(--v-gray-6-base);
}

.list-item-text {
  font-weight: 500;
}

.dropdown-text {
  line-height: 1em;
}

.accent-link {
  background-color: var(--v-accent-base);
}
</style>
