import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"widget-grid"},[_vm._l((_vm.widgets),function(widget,index){return _c('u-widget-tile',_vm._b({key:index},'u-widget-tile',{
      label: widget.label,
      link: widget.link,
      icon: widget.icon,
    },false))}),_c('u-widget-tile',{staticClass:"add-tile",attrs:{"icon":"fas fa-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var icon = ref.icon;
return [_c(VIcon,{staticClass:"icon"},[_vm._v(_vm._s(icon))])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }