/**
 * Type of JWT
 * @typedef {string} JWT_TYPE - [ACCESS_TOKEN, REFRESH_TOKEN, BOTH_TOKENS]
 */
const ACCESS_TOKEN = "access";
const REFRESH_TOKEN = "refresh";
const BOTH_TOKENS = "both";

/**
 * Save JWT Token in localStorage
 * @param {JWT_TYPE} type - Type of token
 * @param {Object} payload - Tokens to be saved
 */
const setJwt = (type, payload) => {
  switch (type) {
    case ACCESS_TOKEN:
      localStorage.accessToken = payload.accessToken;
      break;
    case REFRESH_TOKEN:
      localStorage.accessToken = payload.refreshToken;
      break;
    case BOTH_TOKENS:
      localStorage.accessToken = payload.accessToken;
      localStorage.refreshToken = payload.refreshToken;
      break;
  }
};

/**
 * Returns JWT Token from localStorage
 * @param {JWT_TYPE} type - Type of token
 * @returns {String} - JWT Token
 */
const getJwt = (type) => {
  switch (type) {
    case ACCESS_TOKEN:
      return localStorage.accessToken;
    case REFRESH_TOKEN:
      return localStorage.refreshToken;
  }
};

/**
 * Clear JWT Tokens from localStorage
 */
const clearJwt = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

/**
 * Check if JWT Token is valid
 * @param {String} jwt - JWT Token
 * @returns {boolean} - Validity
 */
const isValidJwt = (jwt) => {
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000); // JS deals with dates in milliseconds since epoch
  const now = new Date();
  return now < exp;
};

/**
 * Generate Static Map Image for specified point
 * @param {Number} lat - Latitude for the center point of the static map
 * @param {Number} long - Longitude for the center point of the static map
 * @param {String} resolution - Resolution of the image {width}x{height}
 * @param {Number} zoom - Map zoom level
 * @param {Boolean} showMarker - Show marker on the map
 * @param {String} markerColor - Marker color
 * @param {Boolean} showAttribution
 * @param {Boolean} showLogo
 * @param {Boolean} satelliteMap - Should the image be a satellite map. default: true
 * @returns {String} image URL
 */
const getStaticMapImageUrl = (
  { lat, long },
  resolution = "600x400",
  zoom = 16,
  showMarker = false,
  markerColor = "031727",
  showAttribution = true,
  showLogo = true,
  satelliteMap = true,
) => {
  const baseUrl = satelliteMap
    ? "https://api.mapbox.com/styles/v1/blkmamba7/cl2yqm8ew001f14p6zbys6r7p/static/"
    : "https://api.mapbox.com/styles/v1/blkmamba7/cl0tmynte000714pfonwnncuf/static/";
  const markerOverlay = showMarker
    ? `pin-s+${markerColor}(${long},${lat})/`
    : "";
  const attribution = showAttribution ? "" : "&attribution=false";
  const logo = showLogo ? "" : "&logo=false";
  const accessToken = `?access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`;

  return `${baseUrl}${markerOverlay}${long},${lat},${zoom},0/${resolution}@2x${accessToken}${attribution}${logo}`;
};

// Generate Random Color (HSL value)
const randomColor = () => {
  const rand = (min, max) => min + Math.random() * (max - min);

  return `hsl(${rand(0, 360)}, 90%, 50%)`;
};

/**
 * Convert 24-hour time to 12-hour time
 * @param {string} timeString - time in 24-hour format
 */
const convertTo12hTimeString = (timeString) => {
  let [hours, minutes] = timeString.split(":");
  let ampm = hours >= 12 ? "pm" : "am";

  if (hours > 12) hours -= 12;
  else if (parseInt(hours) === 0) hours = 12;

  return `${hours}:${minutes} ${ampm}`;
};

/**
 * Handle window close/refresh event
 * @param {BeforeUnloadEvent} e - Window unload event
 */
const beforeWindowUnload = (e) => {
  e.preventDefault();
  e.returnValue = "";
};

export {
  setJwt,
  getJwt,
  clearJwt,
  isValidJwt,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  BOTH_TOKENS,
  getStaticMapImageUrl,
  randomColor,
  convertTo12hTimeString,
  beforeWindowUnload,
};
