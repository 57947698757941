<template>
  <v-dialog v-model="model" max-width="600" persistent>
    <v-card class="ma-0 pa-6" color="accent" rounded="lg">
      <!--   Modal Title   -->
      <v-card-title
        class="
          py-0
          secondary--text
          text-h3
          flex
          justify-space-between
          flex-nowrap
        "
      >
        <p class="text-truncate mb-0">Uh oh!</p>
        <u-button color="secondary" icon @click="model = false">
          <v-icon color="secondary" size="25"> fas fa-times</v-icon>
        </u-button>
      </v-card-title>

      <!--   Modal Content   -->
      <v-card-text class="py-4 secondary--text">
        <p class="mb-0 body-1">
          Date and Time Information have not been specified for all videos.
          <br />
          Kindly fill these out to continue to proceed.
        </p>
        <!--   Video List   -->
        <v-row class="py-3">
          <!-- Videos missing date -->
          <v-col>
            <p class="subtitle-2 font-weight-bold mb-1">
              Videos with missing date
              <v-icon class="pl-1 pb-1" color="secondary" x-small>
                fas fa-chevron-down
              </v-icon>
            </p>
            <v-list-item-group
              v-for="{ filename } in videosMissingDate"
              :key="filename"
              class="body-1 video-name"
            >
              {{ filename }}
            </v-list-item-group>
          </v-col>
          <!-- Videos missing time -->
          <v-col>
            <p class="subtitle-2 font-weight-bold mb-1">
              Videos with missing time
              <v-icon class="pl-1 pb-1" color="secondary" x-small>
                fas fa-chevron-down
              </v-icon>
            </p>
            <v-list-item-group
              v-for="{ filename } in videosMissingTime"
              :key="filename"
              class="body-1 video-name"
            >
              {{ filename }}
            </v-list-item-group>
          </v-col>
        </v-row>

        <p class="ma-0 pt-2 body-1 font-weight-bold">
          Missing fields are highlighted to make it easier to identify.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "VideoSetupErrorModal",
  components: { UButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    videosMissingDate: {
      type: Array,
      default: () => [],
    },
    videosMissingTime: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.video-name {
  line-height: 1.2rem;
}
</style>
