<template>
  <v-dialog
    v-model="model"
    class="ma-4"
    max-width="540px"
    @click:outside="model = false"
  >
    <v-card v-if="!success" class="pa-2 py-4" color="white" rounded="lg">
      <!--   Title   -->
      <v-card-title class="px-4 pt-0">
        <h3 class="text-h3 secondary--text mb-0">Reset your password</h3>
      </v-card-title>

      <!--   Content   -->
      <v-card-text class="py-0 px-4">
        <p class="mb-0 body-1 font-weight-medium secondary--text">
          A reset link will be sent to your registered email ID.
        </p>
        <v-form ref="form" class="py-2" @submit.prevent="sendEmail">
          <u-text-field
            v-model="email"
            :error="error"
            :rules="[rules.required, rules.email]"
            large
            lazy-validation
            placeholder="Email"
            type="email"
          />
        </v-form>

        <v-alert
          v-if="error"
          class="text-center"
          color="accent"
          text
          type="error"
        >
          <strong>{{ errorMessage }}</strong>
        </v-alert>
      </v-card-text>

      <!-- Action Buttons -->
      <v-card-actions>
        <u-button
          block
          class="text-capitalize font-weight-bold white--text"
          color="secondary"
          large
          @click="sendEmail"
        >
          Send email
        </u-button>
      </v-card-actions>
    </v-card>

    <!--  Success Message  -->
    <v-card v-else class="pa-4" color="white" rounded="lg">
      <p class="text-center">
        <v-icon color="secondary" large>fas fa-envelope</v-icon>
      </p>
      <p class="subtitle-1 font-weight-bold text-center">
        A link has been sent to your registered email.
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton, UTextField } from "@/components/base";

import { rules } from "@/utils/formValidation";
import ApiService from "@/services/ApiService";

export default {
  name: "ForgotPasswordModal",
  components: { UTextField, UButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      email: "",
      rules,

      success: false,
      error: false,
      errorMessage: "",
    };
  },
  methods: {
    async sendEmail() {
      this.error = false;
      this.success = false;

      if (!this.$refs.form.validate()) return;

      try {
        await ApiService.forgotPassword({ email: this.email });
        this.success = true;
      } catch (e) {
        this.error = true;
        this.errorMessage = e.response.data?.message ?? e.response.data;
      }
    },
  },
};
</script>
