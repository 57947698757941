<template>
  <v-sheet
    :max-width="'30vw'"
    class="tile cursor-pointer font-weight-bold ma-2 pa-6 d-flex"
    min-height="200"
    min-width="350"
    rounded="lg"
  >
    <!-- Illustration and Label -->
    <v-col align-self="center">
      <v-icon class="icon" size="64">{{ icon }}</v-icon>
      <p class="space-mono mb-0 text-wrap">
        {{ label }}
      </p>
    </v-col>
    <!-- Icon -->
    <v-col align-self="start" cols="auto">
      <v-icon class="arrow">fas fa-chevron-right</v-icon>
    </v-col>
  </v-sheet>
</template>

<script>
export default {
  name: "UTemplateTile",
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    icon: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  background-color: var(--v-primary-base);
  color: var(--v-gray-1-base);
  font-size: 1.5rem;

  .icon {
    color: var(--v-gray-1-base);
  }

  .arrow {
    color: var(--v-primary-base);
  }

  // Targets tile hover state
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

    .arrow {
      color: var(--v-gray-1-base);
    }
  }

  // Targets tile active state
  &:active {
    box-shadow: inset 0 0 15px black;
    background-color: var(--v-gray-1-base);
    color: var(--v-primary-base);

    .icon {
      color: var(--v-primary-base);
    }
  }
}
</style>
