import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"ma-4",attrs:{"max-width":"540px"},on:{"click:outside":function($event){_vm.model = false}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(!_vm.success)?_c(VCard,{staticClass:"pa-2 py-4",attrs:{"color":"white","rounded":"lg"}},[_c(VCardTitle,{staticClass:"px-4 pt-0"},[_c('h3',{staticClass:"text-h3 secondary--text mb-0"},[_vm._v("Reset your password")])]),_c(VCardText,{staticClass:"py-0 px-4"},[_c('p',{staticClass:"mb-0 body-1 font-weight-medium secondary--text"},[_vm._v(" A reset link will be sent to your registered email ID. ")]),_c(VForm,{ref:"form",staticClass:"py-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('u-text-field',{attrs:{"error":_vm.error,"rules":[_vm.rules.required, _vm.rules.email],"large":"","lazy-validation":"","placeholder":"Email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(_vm.error)?_c(VAlert,{staticClass:"text-center",attrs:{"color":"accent","text":"","type":"error"}},[_c('strong',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()],1),_c(VCardActions,[_c('u-button',{staticClass:"text-capitalize font-weight-bold white--text",attrs:{"block":"","color":"secondary","large":""},on:{"click":_vm.sendEmail}},[_vm._v(" Send email ")])],1)],1):_c(VCard,{staticClass:"pa-4",attrs:{"color":"white","rounded":"lg"}},[_c('p',{staticClass:"text-center"},[_c(VIcon,{attrs:{"color":"secondary","large":""}},[_vm._v("fas fa-envelope")])],1),_c('p',{staticClass:"subtitle-1 font-weight-bold text-center"},[_vm._v(" A link has been sent to your registered email. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }