<template>
  <v-dialog
    :width="'75%'"
    max-width="800"
    overlay-color="#969ea5"
    overlay-opacity="0.5"
    persistent
    value="true"
  >
    <u-button
      class="my-2 float-end"
      color="secondary"
      fab
      flat
      small
      @click="$emit('click:close')"
    >
      <v-icon>fas fa-times</v-icon>
    </u-button>

    <!--  Table  -->
    <v-sheet class="overflow-hidden px-8" color="transparent" rounded="lg">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
        }"
        :headers="headers"
        :items="metricResults"
        fixed-header
        flat
        multi-sort
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "TestMetricResultsModal",
  components: { UButton },
  props: {
    metricResults: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      const keys = Object.keys(this.metricResults[0]);
      return keys.map((key) => ({
        text: key,
        value: key,
        class: "secondary white--text body-1",
        dark: true,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: none;
}

::v-deep .v-data-table__wrapper {
  border-radius: 5px;
}

::v-deep .v-data-table-header__icon,
::v-deep .v-data-table-header__sort-badge {
  color: white !important;
}

::v-deep .v-data-table-header__icon {
  margin-left: 0.5rem;
}
</style>
