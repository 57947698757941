<template>
  <v-sheet v-if="projects.length" color="light">
    <v-row>
      <!--  Carousel Navigation Button  -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        align-self="center"
        class="pa-2"
        cols="auto"
      >
        <u-button :disabled="index <= 0" color="gray-6" icon @click="index--">
          <v-icon>{{ index > 0 ? "fas fa-chevron-left" : "" }}</v-icon>
        </u-button>
      </v-col>

      <!--  Carousel Window  -->
      <v-col class="px-2 px-sm-0">
        <v-window v-model="index">
          <!--  First Window  -->
          <v-window-item>
            <u-project-card :project="firstProject" />
          </v-window-item>
          <!--  Second Window  -->
          <v-window-item v-if="secondProject || thirdProject">
            <v-row v-if="thirdProject" align="stretch">
              <v-col cols="12" md="8">
                <u-project-card :project="secondProject" color="gray-1" />
              </v-col>
              <v-col
                class="d-flex flex-md-column justify-space-between"
                cols="12"
                md="4"
              >
                <u-project-card-mini
                  :project="thirdProject"
                  class="col mr-2 mb-md-2 mr-md-0"
                />
                <v-sheet
                  :width="'100%'"
                  class="white--text col ml-2 mt-md-2 ml-md-0 cursor-pointer"
                  color="secondary"
                  rounded="lg"
                  @click.native="viewAllProjects"
                >
                  <v-container class="fill-height">
                    <v-row
                      align="center"
                      class="pa-2 py-4 mx-auto"
                      justify="center"
                    >
                      <v-icon class="col-12 col-sm-4" color="white" size="84">
                        fas fa-file
                      </v-icon>
                      <h2 class="col-12 col-sm-8 text-wrap space-mono">
                        View all projects
                      </h2>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
            <u-project-card v-else :project="secondProject" />
          </v-window-item>
        </v-window>
      </v-col>

      <!--  Carousel Navigation Button  -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        align-self="center"
        class="pa-2"
        cols="auto"
      >
        <u-button
          :disabled="index >= windows - 1"
          color="gray-6"
          icon
          @click="index++"
        >
          <v-icon>
            {{
              windows - 1 > index && windows > 1 ? "fas fa-chevron-right" : ""
            }}
          </v-icon>
        </u-button>
      </v-col>
    </v-row>

    <!--  Carousel Window Indicators  -->
    <v-row class="pa-2" justify="center">
      <v-item-group
        v-if="projects.length > 1"
        v-model="index"
        class="text-center"
        mandatory
      >
        <v-item
          v-for="n in windows"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
          class="mx-1"
        >
          <v-icon
            :color="active ? 'secondary' : ''"
            :input-value="active"
            :small="!active"
            @click="toggle"
          >
            mdi-record
          </v-icon>
        </v-item>
      </v-item-group>
    </v-row>

    <!-- project selector modal-->
    <all-projects-modal v-model="showAllProjectsModal" />
  </v-sheet>
</template>

<script>
import { UButton } from "@/components/base";
import { UProjectCard, UProjectCardMini } from "@/components/common";
import { AllProjectsModal } from "@/components/modals";
import { mapState } from "vuex";

export default {
  name: "ProjectCarousel",
  components: {
    UProjectCardMini,
    UProjectCard,
    UButton,
    AllProjectsModal,
  },
  computed: {
    ...mapState("User", ["projects"]),
    firstProject() {
      return this.projects[0];
    },
    secondProject() {
      return this.projects[1];
    },
    thirdProject() {
      return this.projects[2];
    },
    windows() {
      return this.projects.length > 1 ? 2 : 1;
    },
  },
  data: () => ({
    showAllProjectsModal: false,
    index: 0,
  }),
  methods: {
    viewAllProjects() {
      this.showAllProjectsModal = true;
      this.$store.dispatch("User/getProjects");
    },
  },
};
</script>
