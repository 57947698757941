<template>
  <u-base-nav>
    <v-spacer></v-spacer>
    <slot></slot>
    <u-top-nav-menu class="mx-1" />
    <v-avatar class="mx-1" color="light" size="32">
      <h3>?</h3>
    </v-avatar>
  </u-base-nav>
</template>

<script>
import { UBaseNav } from "@/components/base";
import UTopNavMenu from "@/components/common/UTopNavMenu";

export default {
  name: "UTopNav",
  components: { UTopNavMenu, UBaseNav },
};
</script>

<style lang="scss" scoped>
::v-deep div.v-toolbar__content {
  padding-block: 0;
}
</style>
