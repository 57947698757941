<template>
  <v-container class="widget-grid">
    <!-- Dashboard Widgets Grid -->
    <u-widget-tile
      v-for="(widget, index) in widgets"
      :key="index"
      v-bind="{
        label: widget.label,
        link: widget.link,
        icon: widget.icon,
      }"
    />
    <u-widget-tile class="add-tile" icon="fas fa-plus">
      <template #default="{ icon }">
        <v-icon class="icon">{{ icon }}</v-icon>
      </template>
    </u-widget-tile>
  </v-container>
</template>

<script>
import { UWidgetTile } from "@/components/common";

export default {
  name: "DashboardWidgetContainer",
  components: { UWidgetTile },
  data: () => ({
    widgets: [
      {
        icon: "fas fa-chart-line",
        label: "Analytics",
        link: "",
      },
      {
        icon: "fas fa-satellite-dish",
        label: "Live Feed",
        link: "",
      },
      {
        icon: "fas fa-film",
        label: "Video Library",
        link: "",
      },
      {
        icon: "fas fa-cog",
        label: "Settings",
        link: "",
      },
      {
        icon: "fas fa-archive",
        label: "Archive",
        link: "",
      },
      {
        icon: "fas fa-photo-video",
        label: "Playback",
        link: "",
      },
      {
        icon: "fas fa-comment-alt",
        label: "Notifications",
        link: "",
      },
      {
        icon: "fas fa-money-check-alt",
        label: "Credit Usage",
        link: "",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.widget-grid {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 0.4rem));
  grid-gap: 0.8rem;
  grid-auto-rows: 1fr;
  background-color: var(--v-background-base);

  &::before {
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}

.add-tile {
  display: grid;
  place-items: center;

  .icon {
    color: var(--v-gray-1-base);
    font-size: 2.8vw;
  }

  &:active .icon {
    color: white;
  }
}
</style>
