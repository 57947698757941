<template>
  <v-container class="pa-0" fluid>
    <h3 class="heading-3 mb-2">Select the metrics to add</h3>
    <!-- Metric Group -->
    <v-item-group
      v-model="selectedMetrics"
      class="d-flex flex-wrap justify-lg-space-between"
    >
      <!-- Metric Tile -->
      <v-item
        v-for="(item, index) in allMetrics"
        :key="index"
        v-slot="{ active, toggle }"
        :value="item.name"
        class="ma-1"
      >
        <v-sheet
          :class="active ? 'primary--text' : 'secondary--text'"
          :color="active ? 'secondary' : 'primary'"
          min-height="150"
          min-width="250"
          rounded="lg"
          @click="toggle"
        >
          <!-- Illustration and Label -->
          <v-col align-self="center">
            <v-icon :color="active ? 'primary' : 'secondary'" size="64">
              fas fa-car
            </v-icon>
            <p class="space-mono mb-0 text-wrap">
              {{ item.name }}
            </p>
          </v-col>
          <!-- end of col -->
        </v-sheet>
      </v-item>
    </v-item-group>
    <!-- Action Button -->
    <v-row class="ma-2" justify="end">
      <u-button
        class="font-weight-bold text-capitalize ml-1"
        color="secondary"
        outlined
      >
        Cancel
      </u-button>
      <u-button
        class="font-weight-bold text-capitalize ml-1 secondary--text"
        color="primary"
        @click="this.$emit('addMetric', selectedMetrics)"
      >
        Add
      </u-button>
    </v-row>
  </v-container>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "NewMetricCard",
  components: { UButton },
  data: () => ({
    allMetrics: [
      {
        name: "Near-Miss Analysis",
      },
      {
        name: "Gap Detection",
      },
      {
        name: "Intersection Analysis",
      },
      {
        name: "Throughput",
      },
    ],
    selectedMetrics: [],
  }),
};
</script>
