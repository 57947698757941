<template>
  <v-sheet style="position: relative">
    <v-img
      width="150"
      height="150"
      :src="imageUrl"
      :alt="altText"
      class="rounded-lg"
    >
      <!-- Image placeholder-->
      <template #placeholder>
        <v-sheet
          class="fill-height d-flex align-center justify-center"
          color="background"
          rounded="lg"
          max-height="150"
        >
          <v-icon color="gray-5" x-large>fa-photo-video</v-icon>
        </v-sheet>
      </template>
    </v-img>

    <!--  Overlay  -->
    <div v-if="active" class="custom-overlay rounded-lg">
      <v-icon color="white" x-large>fas fa-check</v-icon>
    </div>

    <!--  Label  -->
    <p v-if="!hideLabel" class="pa-1 ma-0 body-1 font-weight-bold">Preview</p>
  </v-sheet>
</template>

<script>
export default {
  name: "UCameraPreviewTile",
  props: {
    imageUrl: {
      type: String,
      default: "",
      required: true,
    },
    altText: {
      type: String,
      default: "",
    },
    active: Boolean,
    hideLabel: Boolean,
  },
};
</script>

<style>
.custom-overlay {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  z-index: 2;
  background: rgba(3, 23, 39, 0.34);
}
</style>
