<template>
  <v-container
    ref="canvasContainer"
    class="pa-0 fill-height canvas-container"
    fluid
  >
    <UButton
      v-if="markers.length || roi.length"
      class="reset-action-btn secondary--text text-capitalize font-weight-bold"
      color="gray-7"
      @click="clearCanvas"
    >
      <v-icon class="mr-1" small>fas fa-undo</v-icon>
      Reset
    </UButton>
    <canvas ref="canvas" class="canvas" @click="canvasClickHandler"></canvas>
  </v-container>
</template>

<script>
import { UButton } from "@/components/base";
import { CANVAS_ACTIONS } from "@/utils/const";

export default {
  name: "UCanvas",
  components: { UButton },
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    actionType: {
      type: String,
      default: "pin",
    },
  },
  mounted() {
    this.loadCanvas();
  },
  data: () => ({
    canvas: undefined,
    canvasContainer: undefined,
    canvasContext: undefined,
    image: undefined,
    markers: [],
    roi: [],
    metricRoi: [],
    scaledMarkers: [],
    scaledRoi: [],
    scaledMetricRoi: [],
  }),
  watch: {
    scaledMarkers(newValue) {
      this.$emit("updateMarkers", newValue);
    },
    scaledRoi(newValue) {
      this.$emit("updateArea", newValue);
    },
    scaledMetricRoi(newValue) {
      this.$emit("updateMetricArea", newValue);
    },
  },
  methods: {
    // Initialize canvas and set background image, markers and area
    loadCanvas() {
      const canvasContainer = this.$refs.canvasContainer;
      const canvasElement = this.$refs.canvas;
      this.canvasContext = canvasElement.getContext("2d");

      canvasElement.width = canvasContainer.offsetWidth;
      canvasElement.height = canvasContainer.clientHeight;

      this.canvas = canvasElement;
      this.canvasContainer = canvasContainer;
      this.updateAnnotations();
    },

    // Handle click events, sets action to pin or line
    canvasClickHandler(event) {
      const x = event.offsetX;
      const y = event.offsetY;
      const coordinate = { x, y };
      // this.canvasContext.save();

      switch (this.actionType) {
        case CANVAS_ACTIONS.PIN:
          this.addPin(this.canvasContext, coordinate);
          break;
        case CANVAS_ACTIONS.ROI:
          this.addPolyline(
            this.canvasContext,
            coordinate,
            this.roi,
            this.scaledRoi,
            this.$vuetify.theme.themes.light.primary,
          );
          break;
        case CANVAS_ACTIONS.METRIC_ROI:
          this.addPolyline(
            this.canvasContext,
            coordinate,
            this.metricRoi,
            this.scaledMetricRoi,
            this.$vuetify.theme.themes.light.accent,
          );
          break;
        default:
          break;
      }
    },

    /**
     * Draws a marker pin at given point on the canvas
     * @param ctx - Canvas Context
     * @param pin - Coordinates of the pin { x, y }
     */
    drawPin(ctx, pin) {
      ctx.save();
      ctx.fillStyle = this.$vuetify.theme.themes.light.secondary;
      ctx.translate(pin.x, pin.y);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.bezierCurveTo(2, 0, -25, -25, 0, -30);
      ctx.bezierCurveTo(25, -25, -2, 0, 0, 0);
      ctx.fill();
      ctx.strokeStyle = this.$vuetify.theme.themes.light.secondary;
      ctx.lineWidth = 1.5;
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(0, -20, 5, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.restore();
      // this.markers.push([pin.x, pin.y]);
    },

    /**
     * Adds a pin to the canvas
     * @param ctx - Canvas Context
     * @param pin - Coordinates of the pin { x, y }
     */
    addPin(ctx, pin) {
      this.drawPin(ctx, pin);
      this.markers.push([pin.x, pin.y]);
      this.scaledMarkers.push(this.scaleToImage([pin.x, pin.y]));
    },

    /**
     * Draw a polyline
     * @param ctx - Canvas Context
     * @param location - The coordinates of the point to which the line is to be drawn
     * @param points - List of all points in the polyline
     * @param color - Color of the Area
     */
    drawLine(
      ctx,
      location,
      points,
      color = this.$vuetify.theme.themes.light.primary,
    ) {
      const { x, y } = location;

      // Draw the point
      ctx.beginPath();
      ctx.arc(x, y, 3, 0, Math.PI * 2, true);
      ctx.fillStyle = "white";
      ctx.closePath();
      ctx.fill();

      if (points.length > 0) {
        const [origin_x, origin_y] = points.slice(-1)[0];
        ctx.beginPath();
        ctx.moveTo(origin_x, origin_y);

        // draw line
        ctx.lineWidth = 2;
        ctx.strokeStyle = "white";
        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.closePath();

        // draw polygon
        ctx.beginPath();
        ctx.moveTo(origin_x, origin_y);
        ctx.lineTo(x, y);
        ctx.lineTo(points[0][0], points[0][1]);
        ctx.fillStyle = `${color}55`;
        ctx.closePath();
        ctx.fill();

        // save context and vars
        ctx.save();
      }
      // this.roi.push([x, y]);
    },

    /**
     * Adds a point to the polyline on canvas
     * @param ctx - Canvas Context
     * @param location - The coordinates of the point to which the line is to be drawn
     * @param points - List of all points in the polyline
     * @param scaledPoints - List of all points in the polyline scaled to original image
     * @param color - Color of the Area
     */
    addPolyline(ctx, location, points, scaledPoints, color) {
      this.drawLine(ctx, location, points, color);
      const { x, y } = location;
      points.push([x, y]);
      scaledPoints.push(this.scaleToImage([x, y]));
    },

    // Clear all annotations on the canvas
    clearCanvas() {
      this.roi = [];
      this.metricRoi = [];
      this.markers = [];

      this.scaledMarkers = [];
      this.scaledRoi = [];
      this.scaledMetricRoi = [];

      this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvasContext.beginPath();
      this.redrawCanvas();
    },

    // Redraw Canvas
    redrawCanvas() {
      const { width, height } = this.canvas;
      this.canvasContext.drawImage(this.image, 0, 0, width, height);
    },

    // Redraw Canvas Markers
    redrawPoints() {
      this.markers.forEach((marker) => {
        const [x, y] = marker;
        this.drawPin(this.canvasContext, { x, y });
      });
    },

    /**
     * Redraws the area on the canvas
     * @param {Array} roi - Roi points of the area
     * @param color - Color of the area
     */
    redrawArea(roi, color) {
      const points = [];
      const ctx = this.canvasContext;
      roi.forEach((point) => {
        const [x, y] = point;
        this.drawLine(ctx, { x, y }, [...points], color);
        points.push(point);
      });
    },

    /**
     * Draw a movement line on canvas
     * @param ctx - Canvas Context
     * @param src - Start coordinates for the arrow
     * @param dest - End coordinates for the arrow
     * @param color - Arrow color
     */
    drawArrow(ctx, { src, dst }, color) {
      const [fromX, fromY] = src;
      const [toX, toY] = dst;

      const headLength = 10;
      const angle = Math.atan2(toY - fromY, toX - fromX);

      const headLengthCos = headLength * Math.cos(angle - Math.PI / 7);
      const headLengthSin = headLength * Math.sin(angle - Math.PI / 7);

      //starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath();
      ctx.moveTo(fromX, fromY);
      ctx.lineTo(toX, toY);
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.stroke();

      //starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath();
      ctx.moveTo(toX, toY);
      ctx.lineTo(toX - headLengthCos, toY - headLengthSin);

      //path from the side point of the arrow, to the other side point
      ctx.lineTo(
        toX - headLength * Math.cos(angle + Math.PI / 7),
        toY - headLength * Math.sin(angle + Math.PI / 7),
      );

      //path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(toX, toY);
      ctx.lineTo(toX - headLengthCos, toY - headLengthSin);

      //draws the paths created above
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.fillStyle = color;
      ctx.fill();
      ctx.save();
    },

    /**
     * Draw arrows for a group
     * @param color - Color for arrow group (HSL Value)
     * @param arrowData - Coordinates for arrows
     */
    drawArrows({ color, arrowData }) {
      arrowData.map((arrow) =>
        this.drawArrow(this.canvasContext, arrow, color),
      );
    },

    /**
     * Draw Movement Arrows for selected groups
     * @param movementGroups - Selected Movement Groups
     */
    drawMovementArrows(movementGroups) {
      this.redrawCanvas();
      this.redrawArea(this.roi, this.$vuetify.theme.themes.light.primary);
      this.redrawArea(this.metricRoi, this.$vuetify.theme.themes.light.accent);
      movementGroups.map((group) => this.drawArrows(group));
    },

    /**
     * Update Canvas Annotations
     * @param {Array} markers - Markers / Pins on canvas
     * @param roi - Area / Polyline on canvas
     * @param {Array} metricRoi - Additional Area / Polyline on canvas
     * @param image
     */
    updateAnnotations(
      markers = [],
      roi = [],
      metricRoi = [],
      image = this.imageUrl,
    ) {
      this.canvasContext.fillStyle = "white";
      this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const img = new Image();
      img.src = image;

      img.onload = () => {
        this.canvas.width = this.canvasContainer.offsetWidth;
        this.canvas.height = img.height * (this.canvas.width / img.width);
        this.canvasContext.drawImage(
          img,
          0,
          0,
          this.canvas.width,
          this.canvas.height,
        );

        this.scaledRoi = roi;
        this.scaledMetricRoi = metricRoi;
        this.scaledMarkers = markers;

        this.markers = markers.map((point) => this.scaleToCanvas(point));
        this.roi = roi.map((point) => this.scaleToCanvas(point));
        this.metricRoi = metricRoi.map((point) => this.scaleToCanvas(point));
        this.redrawPoints();
        this.redrawArea(this.roi, this.$vuetify.theme.themes.light.primary);
        this.redrawArea(
          this.metricRoi,
          this.$vuetify.theme.themes.light.accent,
        );
      };
      this.image = img;
    },

    /**
     * Scale coordinates to image dimensions
     * @param {Array} point - Coordinates of point
     */
    scaleToImage(point) {
      const [x, y] = point;

      const scaledX = (x / this.canvas.width) * this.image.width;
      const scaledY = (y / this.canvas.height) * this.image.height;

      return [scaledX, scaledY];
    },

    /**
     * Scale coordinates to canvas dimensions
     * @param {Array} point - Coordinates of point
     */
    scaleToCanvas(point) {
      const [x, y] = point;

      const scaledX = (x / this.image.width) * this.canvas.width;
      const scaledY = (y / this.image.height) * this.canvas.height;

      return [scaledX, scaledY];
    },
  },
};
</script>

<style scoped>
.canvas-container {
  position: relative;
}

.reset-action-btn {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.canvas {
  cursor: crosshair;
}
</style>
