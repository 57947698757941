<template>
  <v-container class="pa-0 ma-0">
    <label class="body-2 font-weight-bold secondary--text">
      {{ label }}
    </label>
    <v-select
      v-model="model"
      :menu-props="{ top, offsetY }"
      background-color="white"
      class="font-weight-bold"
      color="secondary"
      flat
      hide-details="auto"
      item-color="secondary"
      solo
      v-bind="{
        items,
        itemText,
        placeholder,
        itemValue,
        dense,
        multiple,
        returnObject,
        prependInnerIcon,
      }"
    >
      <!--  List Item for multiselect  -->
      <template v-if="multiple" v-slot:item="{ active, item, attrs, on }">
        <v-list-item #default="{ active }" v-bind="attrs" v-on="on">
          <v-list-item-title>
            {{ itemText ? item[itemText] : item }}
          </v-list-item-title>
          <v-spacer></v-spacer>
          <v-list-item-action>
            <v-checkbox :input-value="active" dense></v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </template>
      <!--  List Item for single select  -->
      <template v-else v-slot:item="{ active, item, attrs, on }">
        <slot name="item" v-bind="{ active, item, attrs, on }">
          <v-list-item-title>
            {{ itemText ? item[itemText] : item }}
          </v-list-item-title>
        </slot>
      </template>

      <!--  Input inner prepend slot  -->
      <template #prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
    </v-select>
  </v-container>
</template>

<script>
export default {
  name: "UDropdownAlt",
  inheritAttrs: false,
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item {
  border-bottom: 2px solid var(--v-background-base);
}

::v-deep .v-list-item:first-child {
  border-top: 2px solid var(--v-background-base);
}

::v-deep input::placeholder {
  color: var(--v-placeholder-base) !important;
  font-weight: 500;
}

::v-deep .v-icon.v-icon {
  font-size: 16px;
}
</style>
