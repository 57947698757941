import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":'85vw',"max-width":"600","overlay-color":"rgb(244,244,244)","overlay-opacity":"0.75","persistent":"","value":"true"}},[_c(VSheet,{staticClass:"pa-2 px-4 secondary--text",attrs:{"color":"white","rounded":"lg"}},[_c(VRow,{staticClass:"ma-2 flex-nowrap",attrs:{"align":"center"}},[_c('h1',{staticClass:"modal-title space-mono text-wrap text-break"},[_vm._v(" Check Geotagging ")]),_c(VSpacer),_c('u-button',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_c(VIcon,[_vm._v("fas fa-times")])],1)],1),_c('p',{staticClass:"pa-2 mb-0"},[_vm._v(" Please check the geotagging on the map below and choose to proceed or redo. ")]),_c(VContainer,{attrs:{"fluid":""}},[_c(VSheet,{staticClass:"overflow-hidden",attrs:{"height":"350","rounded":""}},[_c('u-map',{staticClass:"fill-height",attrs:{"center":_vm.mapCenter,"map-annotations":_vm.mapAnnotations,"roi-projections":_vm.roiProjections,"show-geotagging":"","zoom":"17"}})],1)],1),_c(VRow,{staticClass:"ma-2",attrs:{"justify":"end"}},[_c('u-button',{staticClass:"secondary--text font-weight-bold text-capitalize mr-2",attrs:{"color":"background","large":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_vm._v(" Redo ")]),_c('u-button',{staticClass:"secondary--text font-weight-bold text-capitalize",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.$emit('click:proceed')}}},[_vm._v(" Proceed ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }