<template>
  <v-sheet class="shadow ma-4" rounded="lg" width="300">
    <v-container class="pa-4">
      <!--   Title and Action Buttons   -->
      <v-row align="center" class="ma-2">
        <h1 class="secondary--text">Set Time</h1>
        <v-spacer />
        <!--   Action Buttons   -->
        <u-button
          class="mx-2 py-4 rounded"
          color="primary"
          x-small
          @click="submit"
        >
          <v-icon color="secondary" size="18">fas fa-check</v-icon>
        </u-button>
        <u-button
          class="py-4 rounded"
          color="secondary"
          text
          x-small
          @click="$emit('close')"
        >
          <v-icon size="22">fas fa-times</v-icon>
        </u-button>
      </v-row>
      <!--   Time   -->
      <v-row align="center" class="flex-nowrap ma-2 mt-0">
        <!--   Hours   -->
        <v-container class="background pa-1 pb-0 rounded" fluid>
          <u-text-field
            v-model="hours"
            large
            max="12"
            min="1"
            placeholder="hh"
            type="number"
            @change="hourControl"
          />
        </v-container>

        <p class="mx-2 mb-1 text-h3 font-weight-bold">:</p>
        <!--   Minutes   -->
        <v-container class="background pa-1 pb-0 rounded" fluid>
          <u-text-field
            v-model="minutes"
            large
            max="60"
            min="0"
            placeholder="mm"
            type="number"
            @change="minControl"
          />
        </v-container>
        <!--   AM / PM   -->
        <v-col class="pr-0">
          <v-item-group v-model="selectedPeriod" mandatory>
            <v-item v-slot="{ active, toggle }" value="am">
              <u-button
                :color="active ? 'primary' : 'background'"
                class="rounded-t-lg secondary--text font-weight-bold body-1"
                small
                tile
                @click="toggle"
              >
                AM
              </u-button>
            </v-item>
            <v-item v-slot="{ active, toggle }" value="pm">
              <u-button
                :color="active ? 'primary' : 'background'"
                class="rounded-b-lg secondary--text font-weight-bold body-1"
                small
                tile
                @click="toggle"
              >
                PM
              </u-button>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { UButton, UTextField } from "@/components/base";

export default {
  name: "UTimePicker",
  components: { UTextField, UButton },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(newValue) {
      if (newValue.length > 0) this.convertTo12hFormat();
    },
  },
  data: () => ({
    hours: undefined,
    minutes: undefined,
    selectedPeriod: "am",
  }),
  methods: {
    minControl() {
      let nMinutes = parseInt(this.minutes);

      // Sets default minutes if out of range
      if (nMinutes < 0 || isNaN(nMinutes)) {
        nMinutes = 0;
      } else if (nMinutes > 59) {
        nMinutes = 59;
      }

      // Convert minutes to mm format
      this.minutes = nMinutes < 10 ? "0" + nMinutes : nMinutes.toString();
    },
    hourControl() {
      const nHours = parseInt(this.hours);
      // Sets default hours if out of range
      if (nHours <= 0 || nHours > 12 || isNaN(nHours)) {
        this.hours = "12";
      }
    },
    /**
     * Sets default time if out of range
     */
    timeControl() {
      this.hourControl();
      this.minControl();
    },
    submit() {
      this.timeControl();

      // Convert to 24h format
      let hours = parseInt(this.hours);

      if (this.selectedPeriod === "pm" && hours < 12) hours += 12;
      else if (this.selectedPeriod === "am" && hours === 12) hours -= 12;
      // Convert hours to hh format
      if (hours < 10) hours = "0" + hours;

      const time = `${hours}:${this.minutes}`;

      this.$emit("input", time);
    },
    convertTo12hFormat() {
      let [hours, minutes] = this.value.split(":").map((x) => parseInt(x));
      this.selectedPeriod = hours >= 12 ? "pm" : "am";

      if (hours > 12) hours -= 12;
      else if (hours === 0) hours = 12;

      // Set time in text fields
      this.hours = hours.toString();
      this.minutes = minutes.toString();

      // Ensure time in range
      this.timeControl();
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0 0 15px rgba(3, 23, 39, 0.15);
}

::v-deep input {
  font-size: 36px;
  text-align: center;

  &::placeholder {
    font-size: 28px;
  }
}

/* Removed the arrows for number input */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
