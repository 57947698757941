import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"564","min-height":"200","min-width":"470","scrollable":"","value":"true"},on:{"click:outside":function($event){return _vm.$emit('click:close')}}},[_c(VCard,{staticClass:"ma-0 overflow-y-auto pa-1",attrs:{"color":"white","max-height":"360","rounded":"lg"}},[_c(VCardTitle,{staticClass:"mb-2"},[_c('h1',{staticClass:"font-weight-black secondary--text space-mono"},[_vm._v(" Add videos to ")]),_c(VSpacer),_c('u-button',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.$emit('click:close')}}},[_c(VIcon,[_vm._v("fas fa-times")])],1)],1),_c(VCardText,[_c(VRow,[_vm._l((_vm.cameras),function(camera){return _c(VCol,{key:camera.id,attrs:{"align-self":"center","cols":"3"}},[_c('u-camera-tile',{attrs:{"cameraName":camera.camera_name},nativeOn:{"click":function($event){return _vm.$emit('click:camera', camera)}}})],1)}),_c(VCol,{attrs:{"align-self":"center"}},[_c('u-camera-tile',{attrs:{"cameraName":"New Camera"},nativeOn:{"click":function($event){return _vm.$emit('click:newCamera')}},scopedSlots:_vm._u([{key:"plus",fn:function(){return [_c(VIcon,{staticClass:"pb-10",attrs:{"color":"secondary","x-small":""}},[_vm._v(" fas fa-plus ")])]},proxy:true}])})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }