import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('u-base-nav',{attrs:{"extended":_vm.extended},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('u-icon-logo',{staticClass:"fill-height nav-logo",nativeOn:{"click":function($event){return _vm.$router.push({ path: '/dashboard' })}}})]},proxy:true},{key:"extension",fn:function(){return [_vm._t("extension")]},proxy:true}],null,true)},[_c(VDivider,{staticClass:"nav-divider",attrs:{"vertical":"","inset":""}}),[_c(VRow,{staticClass:"mx-2 fill-height",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"3","md":"4"}},[_c('h1',{staticClass:"white--text mx-2 text-truncate"},[_vm._v(_vm._s(_vm.title))])]),_c(VSpacer),_vm._l((_vm.navLinks),function(item){return _c(VCard,{key:item.text,staticClass:"px-8 nav-tile d-flex align-center fill-height",attrs:{"to":item.to,"active-class":"active-nav-tile","elevation":"0","tile":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),_c('u-top-nav-menu',{staticClass:"mx-1"}),_c(VAvatar,{staticClass:"mx-2",attrs:{"color":"light","size":"32"}},[_c('h2',[_vm._v("?")])])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }