<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.15855 15.6729L6.26131 14.7756C5.8814 14.3957 5.8814 13.7814 6.26131 13.4055L12.1515 7.51137C12.5314 7.13146 13.1457 7.13146 13.5216 7.51137L19.5485 13.5383C19.9284 13.9182 19.9284 14.5325 19.5485 14.9084L18.6512 15.8056C18.2673 16.1896 17.6408 16.1815 17.2649 15.7894L14.4552 12.7493V21.7647C14.4552 22.3023 14.0227 22.7347 13.4852 22.7347H12.1919C11.6543 22.7347 11.2219 22.3023 11.2219 21.7647V12.7493L8.54483 15.6567C8.16896 16.0528 7.54251 16.0609 7.15855 15.6729Z"
      fill="#031727"
    />
    <rect
      x="4.67139"
      y="3.52979"
      width="16.3298"
      height="3.18604"
      rx="1"
      fill="#031727"
    />
  </svg>
</template>

<script>
export default {
  name: "AreaIcon",
};
</script>
