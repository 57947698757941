<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    bottom
    offset-y
    right
    transition="scale-transition"
  >
    <template #activator="{ on, attrs }">
      <!--    Text field to display selected time    -->
      <v-form ref="form" v-model="valid" class="flex-grow-1" lazy-validation>
        <u-text-field
          v-model="model"
          :dense="dense"
          :hide-details="false"
          :label="label"
          :outlined="!valid"
          :rules="[() => !!model || 'Field cannot be blank', ...customRules]"
          append-icon="fas fa-clock"
          placeholder="14:36"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:append="showMenu = true"
        >
          <template #append>
            <v-icon v-if="!valid" class="mr-2" color="accent" small>
              fas fa-exclamation-triangle
            </v-icon>
            <v-icon color="secondary" small>fas fa-clock</v-icon>
          </template>
        </u-text-field>
      </v-form>
    </template>
    <!--    Time Picker    -->
    <u-time-picker
      v-model="model"
      color="secondary"
      @close="showMenu = false"
      @input="showMenu = false"
    />
  </v-menu>
</template>

<script>
import { UTimePicker, UTextField } from "@/components/base";

export default {
  name: "UTimeField",
  components: {
    UTimePicker,
    UTextField,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: "Time of Day (24h)",
    },
    dense: Boolean,
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMenu: false,
      valid: undefined,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
