import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticStyle:{"position":"relative"},attrs:{"color":"gray-1","min-width":"305","rounded":"lg"}},[_c(VImg,{staticClass:"rounded-t-lg",attrs:{"lazy-src":_vm.lazy_source,"max-height":200,"min-height":150,"src":_vm.source},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VSheet,{staticClass:"fill-height ma-0 d-flex align-center justify-center",attrs:{"color":"background","rounded":"t-lg"}},[_c(VIcon,{attrs:{"color":"gray-7","x-large":""}},[_vm._v("fas fa-map")])],1)]},proxy:true}])}),(_vm.active)?_c('div',{staticClass:"custom-overlay rounded-t-lg"},[_c(VIcon,{attrs:{"color":"white","size":"72"}},[_vm._v("fas fa-check")])],1):_vm._e(),_c(VContainer,{staticClass:"white--text"},[_c(VRow,{staticClass:"flex-nowrap",attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"space-mono text-truncate"},[_vm._v(_vm._s(_vm.projectName))]),_c('p',{staticClass:"mt-1 mb-0 body-2"},[_vm._v("Created : "+_vm._s(_vm.createdOn))])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("fas fa-chevron-right")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }