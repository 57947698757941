<template>
  <v-item-group v-model="model" multiple>
    <v-container fluid>
      <v-row>
        <v-item
          v-for="(item, index) in clusterItems"
          :key="index"
          v-slot="{ active, toggle }"
          :value="item.value"
        >
          <u-button
            :color="active ? 'secondary' : 'gray-7'"
            class="ma-1"
            @click="toggle"
          >
            <v-icon :color="active ? 'white' : 'secondary'" size="18">
              {{ item.icon }}
            </v-icon>
            <span
              :class="active ? 'white--text' : 'secondary--text'"
              class="font-weight-bold mx-2 text-capitalize body-1"
            >
              {{ item.text }}
            </span>
          </u-button>
        </v-item>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UClusterTagToggles",
  components: { UButton },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    // Items must include icon to display
    clusterItems: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
