<template>
  <v-app>
    <v-main>
      <ToastContainer />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { ToastContainer } from "@/components";

export default {
  name: "App",
  components: { ToastContainer },
  mounted() {
    this.$store.dispatch("Notification/clearAllNotifications");
  },
};
</script>
