import Vue from "vue";
import { groupVideosByCamera } from "@/utils/videos";
import ApiService from "@/services/ApiService";

const getInitialState = () => ({
  videoFiles: [],
  validateFilesToUpload: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    // Get list of files to upload, sorted by camera
    getFilesToUploadByCamera(state) {
      const videos = state.videoFiles.filter((video) => video.state === "");
      return groupVideosByCamera(videos);
    },

    // Get list of files staged, uploading or errored sorted by camera
    getStagedFilesByCamera(state) {
      const videos = state.videoFiles.filter((video) => video.state !== "");
      return groupVideosByCamera(videos);
    },

    filesToUpload(state) {
      return state.videoFiles.filter((video) => video.state === "");
    },

    stagedFiles(state) {
      return state.videoFiles.filter((video) => video.state !== "");
    },

    videosMissingDate(state) {
      return state.videoFiles.filter((video) => video.date.length === 0);
    },

    videosMissingTime(state) {
      return state.videoFiles.filter((video) => video.time.length === 0);
    },

    videosMissingDetails(state) {
      return state.videoFiles.filter(
        (video) =>
          video.error || video.date.length === 0 || video.time.length === 0,
      );
    },
  },
  mutations: {
    /**
     * Set files to upload
     * @param state - Vuex state
     * @param {Array} payload - List of files to upload
     */
    setFilesToUpload(state, payload) {
      state.videoFiles = [...payload];
    },

    /**
     * Add files to be uploaded
     * @param state
     * @param {Array} payload - Files to be added to upload
     */
    addFilesToUpload(state, payload) {
      state.videoFiles = [...state.videoFiles, ...payload];
    },

    /**
     * Update single file details
     * @param state
     * @param {Object} payload - Updated details of file
     */
    updateFileToUpload(state, payload) {
      const itemIndex = state.videoFiles.findIndex(
        (video) => video.filename === payload.filename,
      );
      Vue.set(state.videoFiles, itemIndex, payload);
    },

    /**
     * Remove selected file from list of files to upload
     * @param state - vuex state
     * @param payload - selected file
     */
    removeFile(state, payload) {
      const videos = state.videoFiles.filter(
        (video) => video.filename !== payload.filename,
      );
      Vue.set(state, "videoFiles", videos);
    },

    /**
     * Reset state to initial state
     * @param state - vuex state
     */
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    deleteCameraVideos({ state, commit }, payload) {
      const videos = state.videoFiles.filter(
        (video) => video.camera_id !== payload.camera_id,
      );
      commit("setFilesToUpload", videos);
    },

    clearUploadedVideos({ state, commit }) {
      const filteredVideos = state.videoFiles.filter(
        (video) => video.state !== "uploaded",
      );
      commit("setFilesToUpload", filteredVideos);
    },

    async uploadVideos({ getters, dispatch }) {
      const videos = getters.filesToUpload;
      const promises = videos.map((video) => {
        video.state = "queued";

        // Form Data
        let formData = new FormData();
        formData.append("file", video.file);
        const videoParams = {
          video_start_time: `${video.date} ${video.time}`,
        };
        formData.append("video_params", JSON.stringify([videoParams]));

        video.state = "uploading";
        // API Call
        return ApiService.uploadVideo(video.camera_id, formData, {
          onUploadProgress: function (progressEvent) {
            video.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
          },
        })
          .then(() => {
            video.state = "uploaded";
          })
          .catch((err) => {
            video.state = "error";
            console.error(err);
          });
      });
      await Promise.allSettled(promises);
      dispatch("Project/getProjectVideos", null, { root: true });
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
