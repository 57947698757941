<template>
  <v-container fluid>
    <v-row align="center" class="py-2 mx-1 mb-2 secondary--text">
      <!--   Camera Name   -->
      <camera-icon :number="cameraIndex" :size="22" />
      <p class="px-2 mb-0 subtitle-1 font-weight-bold">{{ cameraName }}</p>

      <v-spacer />

      <!--   Action Buttons   -->
      <u-button
        class="rounded"
        color="gray-7"
        fab
        small
        tile
        @click="$emit('click:delete')"
      >
        <v-icon color="secondary" small>fas fa-trash</v-icon>
      </u-button>
    </v-row>

    <!--  Video List  -->
    <v-item
      v-for="(video, index) in cameraVideos"
      :key="index"
      v-slot="{ active, toggle }"
      :value="video"
    >
      <video-setup-camera-videos-list-item
        :active="active"
        :date="video.date"
        :label="video.filename"
        :time="video.time"
        :validation="
          $store.state.VideoUpload.validateFilesToUpload || video.error
        "
        @click.native="toggle"
      />
    </v-item>
  </v-container>
</template>

<script>
import { UButton } from "@/components/base";
import { CameraIcon } from "@/components/icons";
import { VideoSetupCameraVideosListItem } from "@/components";

export default {
  components: { UButton, VideoSetupCameraVideosListItem, CameraIcon },
  name: "VideoSetupCameraVideosList",
  props: {
    cameraName: {
      type: String,
      default: "",
    },
    cameraVideos: {
      type: Array,
      default: () => [],
    },
    cameraIndex: {
      type: [String, Number],
      default: "",
    },
    validation: Boolean,
  },
};
</script>
