const getInitialState = () => ({
  notifications: [],
});

const state = getInitialState();

let nextId = 1;

const getters = {
  // Show only the first 5 notifications
  displayNotifications: (state) => state.notifications.slice(0, 5),
};

const mutations = {
  // Store notifications
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  // Add new notification
  ADD_NOTIFICATION(state, notification) {
    state.notifications.push(notification);
  },
  // Remove notification
  REMOVE_NOTIFICATION(state, id) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id,
    );
  },

  // Reset state to initial state
  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  // Add notification
  addNotification({ commit }, notification) {
    const id = nextId++;
    commit("ADD_NOTIFICATION", { ...notification, id });
  },
  // Remove notification
  removeNotification({ commit }, id) {
    commit("REMOVE_NOTIFICATION", id);
  },
  // Clear all notifications
  clearAllNotifications({ commit }) {
    commit("SET_NOTIFICATIONS", []);
  },
  // Reset state to initial state
  resetState({ commit }) {
    commit("RESET_STATE");
    nextId = 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
