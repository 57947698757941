import ApiService from "@/services/ApiService";
import { convertTo12hTimeString } from "@/utils";

const getInitialState = () => ({
  projectCameras: [],
  activeProject: undefined,
  tags: [],
  projectVideos: [],
});

const state = getInitialState();

const getters = {
  activeProjectName(state) {
    return state.activeProject?.name ?? "Untitled";
  },
  activeProjectId(state) {
    return state.activeProject?.project_id;
  },
};

const mutations = {
  // Update projectCameras state object
  setProjectCameras(state, project) {
    state.projectCameras = project;
  },
  // Store active project details
  storeProjectDetails(state, payload) {
    state.activeProject = payload;
  },
  // Update tagList state object
  setTags(state, tags) {
    state.tags = tags;
  },
  // Store project videos
  SET_PROJECT_VIDEOS(state, videos) {
    state.projectVideos = videos;
  },

  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  // Fetch all project cameras
  async getProjectCameras({ commit, dispatch }, projectId) {
    try {
      const res = await ApiService.getProjectCameras(projectId);
      commit("setProjectCameras", res.data);
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        { text: "Error getting project cameras!", color: "error" },
        { root: true },
      );
    }
  },

  // List tags
  async listTags({ commit, dispatch }, projectId) {
    try {
      const res = await ApiService.listTags(projectId);
      commit("setTags", res.data);
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        { text: "Error getting tags!", color: "error" },
        { root: true },
      );
    }
  },

  /**
   * Create Tag
   * @param state
   * @param dispatch
   * @param params - Tag details
   */
  async createTag({ state, dispatch }, params) {
    try {
      const activeProjectId = state.activeProject.project_id;
      await ApiService.createTag(activeProjectId, params);
      await dispatch("listTags", activeProjectId);
      await dispatch(
        "Notification/addNotification",
        {
          text: `Created Tag ${params.tag_name}`,
          color: "success",
        },
        { root: true },
      );
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        {
          text: "Failed to create tag!",
          color: "error",
        },
        { root: true },
      );
    }
  },

  /**
   * Update project details
   * @param dispatch - Vuex dispatch
   * @param {Object} payload - Project details
   */
  async updateProject({ dispatch }, payload) {
    try {
      await ApiService.updateProject(payload.project_id, payload);
      dispatch("User/getProjects", {}, { root: true });
      dispatch(
        "Notification/addNotification",
        {
          text: `Updated project ${payload.name} successfully!`,
          color: "success",
        },
        { root: true },
      );
    } catch (e) {
      console.error(e);
      dispatch(
        "Notification/addNotification",
        {
          text: `Error updating project ${payload.name}!`,
          color: "error",
        },
        { root: true },
      );
    }
  },

  // Create a new camera
  async createCamera({ state, dispatch }, params) {
    try {
      const res = await ApiService.createCamera(params);
      await dispatch("getProjectCameras", state.activeProject.project_id);
      dispatch(
        "Notification/addNotification",
        { text: `Created camera ${params.camera_name}`, color: "success" },
        { root: true },
      );
      return res.data.camera_id;
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        { text: `Error creating camera!`, color: "error" },
        { root: true },
      );
    }
  },

  /**
   * Delete selected camera
   * @param {state, dispatch} - Vuex
   * @param {Object} camera - Camera to be deleted
   */
  async deleteCamera({ state, dispatch }, camera) {
    try {
      await ApiService.deleteCamera(camera.camera_id);
      dispatch("getProjectCameras", state.activeProject.project_id);
      dispatch(
        "Notification/addNotification",
        {
          text: `Updated camera ${camera.camera_name} successfully!`,
          color: "success",
        },
        { root: true },
      );
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        {
          text: `Error updating camera ${camera.camera_name}!`,
          color: "error",
        },
        { root: true },
      );
    }
  },

  /**
   * Get all videos from project
   * @param { commit } - Vuex
   */
  async getProjectVideos({ state, commit, dispatch }) {
    try {
      const projectId = state.activeProject?.project_id;
      const res = await ApiService.getProjectVideos(projectId);
      const data = res.data;

      // Format data
      await data.forEach((camera) => {
        camera.videos.forEach((video) => {
          let [date, time] = video.video_start_time.split(" ");

          // Reformat date
          video.start_date = new Date(date).toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          // Convert time to 12-hour format
          video.start_time = convertTo12hTimeString(time);
        });
      });

      commit("SET_PROJECT_VIDEOS", data);
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        { text: "Error getting videos!", color: "error" },
        { root: true },
      );
    }
  },

  resetState({ commit }) {
    commit("RESET_STATE");
  },

  // Clear all project data from state
  clearProjectState({ dispatch }) {
    dispatch("resetState");
    dispatch("VideoUpload/resetState", {}, { root: true });
    dispatch("CameraConfig/resetState", {}, { root: true });
    dispatch("MetricConfig/resetState", {}, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
