<template>
  <v-app-bar
    :extended="extended"
    app
    class="overflow-y-hidden"
    color="secondary"
    extension-height="64"
    flat
  >
    <slot name="logo">
      <router-link to="/">
        <v-img class="nav-icon" contain src="@/assets/logo-wide.svg" />
      </router-link>
    </slot>
    <slot></slot>
    <template v-if="extended" #extension>
      <slot name="extension"></slot>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "UBaseNav",
  props: {
    extended: Boolean,
  },
};
</script>

<style scoped>
.nav-icon {
  height: 100%;
  width: 12em;
  max-width: 12em;
}
</style>
