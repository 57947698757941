<template>
  <v-container class="pa-0" fluid>
    <!-- Area -->
    <v-container v-if="false" class="mb-4" fluid>
      <u-text-field
        dense
        label="Assign configurable area"
        placeholder="Create an area"
      />
      <u-button class="text-capitalize font-weight-bold" color="gray-7">
        <v-icon class="mr-2" small>fas fa-draw-polygon</v-icon>
        Add area
      </u-button>
    </v-container>

    <!-- Movements -->
    <v-container v-if="false" class="mb-4" fluid>
      <p class="font-weight-bold mb-0 body-2">Movements Drawn</p>
      <!-- Placeholder for list component -->
      <v-sheet class="pa-2 py-4 my-2" color="background" rounded>
        <p class="body-2 placeholder--text mb-0">
          Click here to draw movement (placeholder)
        </p>
      </v-sheet>
      <!-- --- -->
      <u-button class="text-capitalize font-weight-bold" color="gray-7">
        <v-icon class="mr-2" small>fas fa-route</v-icon>
        Add Movements
      </u-button>
    </v-container>

    <!--  Temporary V1 UI  -->
    <!-- Tags -->
    <v-row align="end" class="ma-2">
      <u-dropdown
        v-model="selectedTag"
        :items="projectTags"
        class="mr-2 col"
        dense
        item-text="tag_name"
        label="Tag"
        mandatory
        placeholder="Select a tag"
        return-object
      />
      <!-- Create Tag Menu -->
      <v-menu
        :close-on-content-click="false"
        bottom
        offset-y
        right
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <u-button
            class="text-capitalize font-weight-bold secondary--text rounded"
            color="gray-7"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2" small>fas fa-tag</v-icon>
            Add New
          </u-button>
        </template>
        <v-sheet class="shadow ma-4 pa-4" rounded="lg" width="300">
          <u-text-field v-model="newTag" dense placeholder="Tag Name" />
          <v-row class="my-2 mx-0" justify="end">
            <u-button
              class="text-capitalize font-weight-bold rounded"
              color="gray-7"
              @click="createTag"
            >
              Create
            </u-button>
          </v-row>
        </v-sheet>
      </v-menu>
    </v-row>

    <v-container class="d-flex flex-column gap-1" fluid>
      <u-text-field
        v-model.number="pedConflictDistanceThreshold"
        dense
        label="PED conflict distance threshold"
        placeholder="0.001"
        type="number"
      />
      <u-text-field
        v-model.number="vehicleConflictDistanceThreshold"
        dense
        label="Vehicle conflict distance threshold"
        placeholder="0.001"
        type="number"
      />
    </v-container>

    <!--  ---  -->

    <!-- Action Buttons -->
    <v-row class="ma-4" justify="space-between">
      <u-simple-checkbox
        v-model="showAnnotations"
        class="mt-1"
        label="Show Annotations"
      />
      <u-button
        :class="testLoader ? 'white--text' : 'secondary--text'"
        :color="testLoader ? 'gray-1' : 'gray-7'"
        :disbled="testLoader"
        :loadState="testLoader"
        class="text-capitalize font-weight-bold"
        @click="testConfig"
      >
        Test
      </u-button>
    </v-row>

    <!--  Test Metric Results Modal  -->
    <test-metric-results-modal
      v-if="showTestMetricResults"
      :metric-results="metric_results"
      @click:close="showTestMetricResults = false"
    />
  </v-container>
</template>

<script>
import {
  UButton,
  UTextField,
  USimpleCheckbox,
  UDropdown,
} from "@/components/base";
import { TestMetricResultsModal } from "@/components/modals";

import ApiService from "@/services/ApiService";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    TestMetricResultsModal,
    USimpleCheckbox,
    UTextField,
    UButton,
    UDropdown,
  },
  name: "ConflictParametersCard",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters("MetricConfig", ["getActiveConfig"]),
    ...mapState({
      activeCameraId: (state) => state.MetricConfig.activeCamera?.camera_id,
      activeProjectId: (state) => state.Project.activeProject?.project_id,
      activeMetric: (state) => state.MetricConfig.activeMetric,
      projectTags: (state) => state.Project.tags,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    // Conflict Parameters
    pedConflictDistanceThreshold: {
      get() {
        return this.model?.pedConflictDistanceThreshold;
      },
      set(value) {
        this.$set(this.model, "pedConflictDistanceThreshold", value);
      },
    },
    vehicleConflictDistanceThreshold: {
      get() {
        return this.model?.vehicleConflictDistanceThreshold;
      },
      set(value) {
        this.$set(this.model, "vehicleConflictDistanceThreshold", value);
      },
    },
  },

  watch: {
    selectedTag(newValue) {
      this.$store.commit("MetricConfig/SET_ACTIVE_TAG", newValue);
    },
  },

  mounted() {
    this.selectedTag =
      this.$store.state.MetricConfig.activeTag ?? this.projectTags[0];
  },
  data: () => ({
    showAnnotations: true,

    // Create tag input value
    newTag: "",
    selectedTag: "",

    // Test metric results
    metric_results: [],
    showTestMetricResults: false,

    // Button loader
    testLoader: false,
  }),
  methods: {
    /**
     * Test Metric Configuration for current metric
     */
    async testConfig() {
      this.testLoader = true;
      const { canvasArea = [] } = this.getActiveConfig;

      const params = {
        metric: this.activeMetric,
        direction_roi: canvasArea,
      };
      try {
        const res = await ApiService.testConfig(
          this.activeProjectId,
          this.activeCameraId,
          params,
        );
        this.metric_results = res.data["metrics_result"];
        this.showTestMetricResults = true;
      } catch (e) {
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to test metric configuration!",
          color: "error",
        });
      } finally {
        this.testLoader = false;
      }
    },

    /**
     * Create Tag
     */
    async createTag() {
      await this.$store.dispatch("Project/createTag", {
        tag_name: this.newTag,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}

.shadow {
  box-shadow: 0 0 15px rgba(3, 23, 39, 0.15);
}
</style>
