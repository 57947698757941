<template>
  <v-container>
    <!--  Camera Selector  -->
    <camera-selector-modal
      v-if="showCameraSelectorModal"
      :cameras="projectCameras"
      @click:newCamera="addFilesToNewCamera"
      @click:camera="addFilesToCamera"
      @click:close="showCameraSelectorModal = false"
    />

    <!--  New Camera Modal  -->
    <camera-details-modal
      v-if="showNewCameraModal"
      :videos="newCameraVideos"
      @click:apply="newCameraApply"
      @click:close="showNewCameraModal = false"
    />

    <!--  Input  -->
    <input
      ref="fileInput"
      accept="video/*"
      class="d-none"
      multiple
      type="file"
      @change="selectFiles"
    />
  </v-container>
</template>

<script>
import { CameraDetailsModal, CameraSelectorModal } from "@/components/modals";
import { createVideoObjects } from "@/utils/videos";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AddVideos",
  components: { CameraDetailsModal, CameraSelectorModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // Navigate to the next step after completion
    autoNavigate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("Project", ["projectCameras"]),
    ...mapGetters("Project", ["activeProjectId"]),
    showCameraSelectorModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      newCameraFlag: false,
      showNewCameraModal: false,
      newCameraVideos: [],
      selectedCamera: undefined,
    };
  },
  methods: {
    // Trigger file select
    browseFiles() {
      this.$refs.fileInput.click();
    },

    // Handle file select input
    selectFiles(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.newCameraVideos = createVideoObjects(files);
        this.showNewCameraModal = this.newCameraFlag;
        !this.newCameraFlag && this.updateUploadFileList();
      }
    },

    // Opens file select when uploading videos to a new camera
    addFilesToNewCamera() {
      this.showCameraSelectorModal = false;
      this.newCameraFlag = true;
      this.browseFiles();
    },

    /**
     * Opens file select when uploading videos to existing camera
     * @param camera - Selected camera for file upload
     */
    addFilesToCamera(camera) {
      this.showCameraSelectorModal = false;
      this.newCameraFlag = false;
      this.browseFiles();
      this.selectedCamera = camera;
    },

    // Update the list of files to upload in vuex
    updateUploadFileList() {
      this.newCameraVideos.forEach((video) => {
        video.camera = this.selectedCamera.camera_name;
        video.camera_id = this.selectedCamera.camera_id;
      });
      this.$store.commit("VideoUpload/addFilesToUpload", this.newCameraVideos);
      this.autoNavigate && this.navigateToVideoSetup();
    },

    // Triggered when the user clicks apply on the new camera modal
    newCameraApply() {
      this.showNewCameraModal = false;
      this.autoNavigate && this.navigateToVideoSetup();
    },

    // Navigate to Video Setup
    navigateToVideoSetup() {
      this.$router.push({
        name: "Video Setup",
        params: { project_id: this.activeProjectId },
      });
    },
  },
};
</script>

<style scoped></style>
