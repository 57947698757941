import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c('h3',{staticClass:"heading-3 mb-2"},[_vm._v("Select the metrics to add")]),_c(VItemGroup,{staticClass:"d-flex flex-wrap justify-lg-space-between",model:{value:(_vm.selectedMetrics),callback:function ($$v) {_vm.selectedMetrics=$$v},expression:"selectedMetrics"}},_vm._l((_vm.allMetrics),function(item,index){return _c(VItem,{key:index,staticClass:"ma-1",attrs:{"value":item.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VSheet,{class:active ? 'primary--text' : 'secondary--text',attrs:{"color":active ? 'secondary' : 'primary',"min-height":"150","min-width":"250","rounded":"lg"},on:{"click":toggle}},[_c(VCol,{attrs:{"align-self":"center"}},[_c(VIcon,{attrs:{"color":active ? 'primary' : 'secondary',"size":"64"}},[_vm._v(" fas fa-car ")]),_c('p',{staticClass:"space-mono mb-0 text-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)})}),1),_c(VRow,{staticClass:"ma-2",attrs:{"justify":"end"}},[_c('u-button',{staticClass:"font-weight-bold text-capitalize ml-1",attrs:{"color":"secondary","outlined":""}},[_vm._v(" Cancel ")]),_c('u-button',{staticClass:"font-weight-bold text-capitalize ml-1 secondary--text",attrs:{"color":"primary"},on:{"click":function($event){return this.$emit('addMetric', _vm.selectedMetrics)}}},[_vm._v(" Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }