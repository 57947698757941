const BASE_URL = process.env.VUE_APP_BASE_URL;

const ROUTES = {
  AUTH: "/auth",
  PROJECT_MANAGEMENT: "backend/project-management",
  CAMERA_MANAGEMENT: "backend/video-management",
  TEMPLATE_MANAGEMENT: "backend/template-management",
  CONFIG_MANAGEMENT: "backend/config-management",
  PROCESS_MANAGEMENT: "backend/processing-management",
  VIDEO_MANAGEMENT: "backend/video-management",
};

const ENDPOINTS = {
  // Auth
  LOGIN: ROUTES.AUTH + "/login",
  REFRESH: ROUTES.AUTH + "/refresh",
  SIGNUP: ROUTES.AUTH + "/signup",
  RESET_PASSWORD: ROUTES.AUTH + "/reset_password",
  RESET_PASSWORD_LINK: ROUTES.AUTH + "/request_reset_password_link",

  // Project
  CREATE_PROJECTS: ROUTES.PROJECT_MANAGEMENT + "/projects",
  LIST_USER_PROJECTS: ROUTES.PROJECT_MANAGEMENT + "/projects",
  PROJECT_DETAILS: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + `/projects/${projectId}`,
  EDIT_PROJECT: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + "/projects/" + projectId,
  DELETE_PROJECT: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + "/projects/" + projectId,

  // Cameras
  LIST_PROJECT_CAMERAS: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + "/projects/" + projectId + "/cameras",
  CREATE_CAMERAS: ROUTES.CAMERA_MANAGEMENT + "/cameras",
  ASSIGN_CAMERA_IMAGE: (cameraId) =>
    ROUTES.CAMERA_MANAGEMENT + `/${cameraId}/image`,
  DELETE_CAMERA: (cameraId) => ROUTES.CAMERA_MANAGEMENT + `/${cameraId}`,

  // Videos
  LIST_PROJECT_VIDEOS: (projectId) =>
    ROUTES.VIDEO_MANAGEMENT + `/projects/${projectId}/videos`,
  GET_CAMERA_VIDEO_IMAGES: (cameraId) =>
    ROUTES.VIDEO_MANAGEMENT + `/${cameraId}/image/all`,
  UPLOAD_VIDEO: (cameraId) => ROUTES.VIDEO_MANAGEMENT + `/${cameraId}/videos`,
  CAMERA_VIDEOS: (cameraId) => ROUTES.VIDEO_MANAGEMENT + `/${cameraId}/videos`,

  // Templates
  FETCH_ALL_TEMPLATES: ROUTES.TEMPLATE_MANAGEMENT + "/templates",

  // Tags
  LIST_TAGS: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + `/projects/${projectId}/tags`,
  CREATE_TAG: (projectId) =>
    ROUTES.PROJECT_MANAGEMENT + `/projects/${projectId}/tags`,

  // Processing
  PROCESS_VIDEOS: ROUTES.PROCESS_MANAGEMENT + "/processing",
  PROCESS_METRIC: (projectId) =>
    ROUTES.PROCESS_MANAGEMENT + `/projects/${projectId}/configs/run`,

  // Configurations
  GEOTAG: (cameraId) => ROUTES.CAMERA_MANAGEMENT + `/${cameraId}/configs`,
  SAVE_METRIC_CONFIG: (projectId) =>
    ROUTES.CONFIG_MANAGEMENT + `/${projectId}/configs`,
  GET_DIRECTION_MOVEMENTS: (projectId, cameraId) =>
    ROUTES.CONFIG_MANAGEMENT +
    `/projects/${projectId}/cameras/${cameraId}/directions`,
  TEST_CONFIG: (projectId, cameraId) =>
    ROUTES.CONFIG_MANAGEMENT +
    `/projects/${projectId}/cameras/${cameraId}/test`,
  GET_METRIC_CONFIG: (projectId) =>
    ROUTES.CONFIG_MANAGEMENT + `/${projectId}/configs`,
  GET_DETECTION_STATUSES: (projectId) =>
    ROUTES.CONFIG_MANAGEMENT + `/project/${projectId}/status/detection`,
};

const ALL_METRICS = [
  {
    label: "Flow",
    value: "flow",
  },
  {
    label: "Spot Speed",
    value: "spot_speed",
  },
  {
    label: "Clearance Time",
    value: "clearance_time",
  },
  {
    label: "Clearance Speed",
    value: "clearance_speed",
  },
  {
    label: "Conflict",
    value: "conflict",
  },
];

const CANVAS_ACTIONS = {
  PIN: "pin",
  ROI: "roi",
  METRIC_ROI: "metric_roi",
};

export { BASE_URL, ROUTES, ENDPOINTS, ALL_METRICS, CANVAS_ACTIONS };
