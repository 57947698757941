import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"ma-2",attrs:{"width":"150"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VSheet,{attrs:{"width":"150","height":"150","color":"gray-2","rounded":"lg"}},[(hover)?_c(VImg,{staticClass:"rounded-lg",attrs:{"width":"150","height":"150","src":_vm.imageUrl,"alt":_vm.altText},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VSheet,{staticClass:"fill-height d-flex align-center justify-center",attrs:{"color":"background","rounded":"lg","max-height":"150"}},[_c(VIcon,{attrs:{"color":"gray-5","x-large":""}},[_vm._v("fas fa-images ")])],1)]},proxy:true}],null,true)}):_c(VSheet,{staticClass:"rounded-lg d-flex flex-column justify-center white--text",attrs:{"width":"150","height":"150","color":"secondary"}},[_c('h4',{staticClass:"text-h4 ma-0 px-2 text-wrap"},[_vm._v(_vm._s(_vm.time))]),_c('p',{staticClass:"body-1 px-2 mb-0"},[_vm._v(_vm._s(_vm.date))])])],1)]}}])}),_c('p',{staticClass:"py-1 ma-0 body-1 text-break video-label"},[_vm._v(" "+_vm._s(_vm.videoName.replace(/\.[^/.]+$/, ""))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }