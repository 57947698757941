<template>
  <v-sheet
    min-height="115"
    max-height="115"
    max-width="115"
    color="background"
    class="tile cursor-pointer py-5 px-5"
    rounded
  >
    <!-- icon -->
    <v-icon color="secondary" size="50"> fas fa-video </v-icon>
    <slot name="plus"></slot>
    <slot></slot>
    <!-- Title -->
    <p class="text-wrap ma-0 font-weight-bold icon-text">{{ cameraName }}</p>
  </v-sheet>
</template>

<script>
export default {
  name: "UCameraTile",
  props: {
    cameraName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-text {
  font-size: 12px;
}
.tile {
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  }
}
</style>
