<template>
  <v-checkbox v-model="model" :color="color" :ripple="false" hide-details>
    <template #label>
      <p class="body-1 mb-0 secondary--text font-weight-bold">
        {{ label }}
      </p>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "USimpleCheckbox",
  props: {
    value: Boolean,
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
