<template>
  <!-- Progress bar for Sub-Step -->
  <v-app-bar class="tile" color="gray-1" flat height="64" min-width="50vw">
    <v-row align="center" class="mx-2">
      <v-avatar class="mx-2" color="white" size="40">
        <p class="gray-1--text mb-0 font-weight-black sub-step text-h5">
          {{ step }}
        </p>
      </v-avatar>

      <v-col align-self="center" cols="auto">
        <h2 class="space-mono white--text title-height">{{ title }}</h2>
        <p class="white--text body-2 mb-0">{{ subtitle }}</p>
      </v-col>

      <v-spacer></v-spacer>
      <u-button color="white" icon @click="$emit('click:eye-icon')">
        <v-icon>fas fa-eye</v-icon>
      </u-button>
      <!-- Navigate to Previous and Next sub-step -->
      <u-button color="white" icon @click="$emit('click:prev')">
        <v-icon>fas fa-chevron-left</v-icon>
      </u-button>

      <p class="mb-0 font-weight-bold white--text">{{ subStep }}</p>

      <u-button color="white" icon @click="$emit('click:next')">
        <v-icon>fas fa-chevron-right</v-icon>
      </u-button>

      <u-button color="white" icon @click="$emit('click:close')">
        <v-icon>fas fa-times</v-icon>
      </u-button>
    </v-row>
  </v-app-bar>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UStepperProgressionVariant",
  components: { UButton },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
      required: true,
    },
    step: {
      type: String,
      default: "",
      required: true,
    },
    subStep: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title-height {
  line-height: 1.75rem;
}
</style>
