<template>
  <v-container class="pa-0" fluid>
    <!-- Tab Headers -->
    <v-tabs
      v-model="model"
      center-active
      class="rounded-t-lg"
      color="secondary"
      hide-slider
      show-arrows
    >
      <v-tab
        v-for="(item, index) in items"
        :key="index"
        :class="{
          'rounded-tl-lg': index === 0,
          'rounded-tr-lg': index === items.length - 1,
        }"
        :href="
          identifier.length === 0
            ? ''
            : identifier === 'item'
            ? `#${item}`
            : `#${item[identifier]}`
        "
        active-class="background--secondary font-weight-bold"
        class="d-flex justify-space-between background--gray-7 tab-border"
      >
        <!--  Tab header content slot  -->
        <slot
          name="tab"
          v-bind="{ item, index, active: isActive(item, index) }"
        >
          <span class="text-capitalize">{{ item }}</span>
        </slot>
      </v-tab>

      <!--  Additional Tab Headers  -->
      <slot name="additional-tabs"></slot>
    </v-tabs>

    <!-- Tab Items -->
    <v-tabs-items v-model="model" class="rounded-b-lg tab-item-border">
      <!-- Slot for custom tab items -->
      <slot name="tab-items" v-bind="{ items }">
        <v-tab-item
          v-for="(item, index) in items"
          :key="index"
          :style="{
            height: contentHeight,
            overflowY: 'auto',
          }"
          :value="
            identifier.length === 0
              ? ''
              : identifier === 'item'
              ? item
              : item[identifier]
          "
        >
          <v-sheet class="pa-4">
            <!--  Slot Content for default tab item  -->
            <slot name="tab-item" v-bind="{ item, index }"></slot>
          </v-sheet>
        </v-tab-item>
      </slot>

      <!--  Additional Tab Items  -->
      <slot name="additional-tab-items"></slot>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: "UTabs",
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    // Item list for tabs
    items: {
      type: Array,
      default: () => [],
    },
    // Value to select for v-model
    identifier: {
      type: String,
      // Empty String - index
      // item - item value
      // Specific key - item["key"]
      default: "",
    },
    // height with units
    contentHeight: {
      type: String,
      default: "max-content",
    },
  },
  computed: {
    // v-model
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    /**
     * Checks if the item is active or not
     * @param {Object} item - Specified tab data
     * @param {Number} index - Index of the tab
     * @returns {boolean} - Whether the tab is active or not
     */
    isActive(item, index) {
      let active = false;
      switch (this.identifier) {
        case "":
          active = index === this.model;
          break;
        case "item":
          active = item === this.model;
          break;
        default:
          active = item[this.identifier] === this.model;
      }
      return active;
    },
  },
};
</script>

<style lang="scss" scoped>
.background--gray-7 {
  background-color: var(--v-gray-7-base);
}

.background--secondary {
  background-color: var(--v-secondary-base);
  color: white !important;
}

.tab-border {
  border-inline: 1px solid white;
}

.tab-item-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
