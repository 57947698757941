<template>
  <v-dialog
    overlay-color="rgb(244,244,244)"
    overlay-opacity="0.75"
    persistent
    value="true"
    width="600"
  >
    <v-sheet class="pa-2 px-4 secondary--text" color="white" rounded="lg">
      <!--   Title   -->
      <v-row align="center" class="ma-2 flex-nowrap">
        <v-icon class="mr-4" color="secondary" size="44">fas fa-video</v-icon>
        <h1 class="modal-title space-mono text-wrap text-break text-truncate">
          {{ cameraName || "Untitled Camera" }}
        </h1>
        <v-spacer />
        <u-button color="secondary" icon @click="$emit('click:close')">
          <v-icon>fas fa-times</v-icon>
        </u-button>
      </v-row>

      <!--   Map Placeholder   -->
      <v-container fluid>
        <p class="mb-0 body-2 font-weight-bold secondary--text">
          Location assigned
        </p>
        <v-sheet class="fill-height overflow-hidden" height="250" rounded>
          <u-map
            ref="map"
            :camera-coordinates="cameraCoordinates"
            :center="mapCenter"
            class="fill-height"
            movable-camera-marker
            show-camera-marker
            zoom="16"
            @onCameraMarkerUpdate="updateCoords"
          ></u-map>
        </v-sheet>
      </v-container>

      <!--   Camera Name   -->
      <u-text-field
        ref="cameraName"
        v-model="cameraName"
        :hide-details="false"
        :rules="[rules.required, rules.noRepeat]"
        class="my-2 px-2"
        label="Camera name"
        placeholder="Untitled Camera 1"
        required
      />

      <!--   Video List   -->
      <v-container fluid>
        <p class="mb-0 body-2 font-weight-bold secondary--text">Videos</p>
        <v-list
          class="overflow-y-auto background py-1 my-2"
          dense
          max-height="200"
        >
          <v-list-item v-for="(item, index) in videos" :key="index">
            <v-row
              align="center"
              class="ml-1 flex-nowrap overflow-hidden"
              justify="space-between"
            >
              <p class="body-1 mb-0 pr-2 font-weight-medium text-truncate">
                {{ item.filename }}
              </p>

              <v-col class="white px-1" cols="auto">
                <u-button
                  color="secondary"
                  icon
                  small
                  @click="removeItem(index)"
                >
                  <v-icon color="secondary" small>fas fa-times</v-icon>
                </u-button>
              </v-col>
            </v-row>
          </v-list-item>
          <!--   Placeholder List item   -->
          <v-list-item v-if="videos.length === 0" class="justify-center">
            <p class="body-2 mb-0 placeholder--text font-weight-medium">
              No Videos Selected
            </p>
          </v-list-item>
        </v-list>
      </v-container>

      <!--   Action Button   -->
      <v-row class="ma-2" justify="end">
        <u-button
          :disabled="isDisabled"
          class="secondary--text font-weight-bold text-capitalize"
          color="primary"
          large
          @click="createCamera"
        >
          Apply
        </u-button>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { UButton, UMap, UTextField } from "@/components/base";
import { mapState } from "vuex";

export default {
  name: "CameraDetailsModal",
  components: { UMap, UButton, UTextField },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.cameraCoordinates = [...this.mapCenter] ?? [0, 0];
  },
  computed: {
    ...mapState("Project", ["activeProject", "projectCameras"]),
    mapCenter() {
      return [
        this.activeProject.coordinates.long,
        this.activeProject.coordinates.lat,
      ];
    },
    // Validation Required
    isDisabled() {
      return !this.cameraName || this.cameraExists(this.cameraName);
    },
  },
  data() {
    return {
      cameraName: "",
      searchCoordinates: {},
      coordinateString: "",
      rules: {
        required: (value) => !!value || "Required.",
        noRepeat: (value) => {
          return !this.cameraExists(value) || "Camera name must be unique.";
        },
      },
      cameraCoordinates: [0, 0],
    };
  },
  methods: {
    /**
     * Remove item from video list
     * @param index - index of item to remove
     */
    removeItem(index) {
      this.videos.splice(index, 1);
    },

    /**
     * Queue videos for setup
     * @param camera_id
     */
    apply(camera_id) {
      this.videos.map((video) => {
        video.camera = this.cameraName;
        video.camera_id = camera_id;
      });
      this.$store.commit("VideoUpload/addFilesToUpload", this.videos);
      this.$emit("click:apply");
    },

    /**
     * Updates the camera coordinates
     * @param coords
     */
    updateCoords(coords) {
      this.cameraCoordinates = [...coords];
      this.searchCoordinates = {
        lat: coords[1],
        lng: coords[0],
      };
    },

    // Create a new camera
    async createCamera() {
      if (!this.$refs.cameraName.validate()) return;
      const params = {
        camera_name: this.cameraName,
        location: "",
        coordinates: {
          lat: this.searchCoordinates.lat,
          long: this.searchCoordinates.lng,
        },
        project_id: this.$store.state.Project.activeProject.project_id,
      };
      try {
        const newCameraId = await this.$store.dispatch(
          "Project/createCamera",
          params,
        );
        this.apply(newCameraId);
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Check if the camera name is unique
     * @param {string} cameraName - The camera name to check
     * @returns {boolean} - True if the camera name is unique
     */
    cameraExists(cameraName) {
      return this.projectCameras.some(
        (camera) => camera.camera_name === cameraName,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 2.75rem;
}
</style>
