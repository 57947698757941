import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";

import { CameraIcon } from "@/components/icons";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Brand Colors
        primary: "#36C4B7",
        secondary: "#031727",
        accent: "#FD5765",
        background: "#F4F4F4",
        light: "#FFFFFF",
        // Status Colors
        success: "#36C4B7",
        error: "#FD5765",
        disabled: "#EDEDED",
        // Blue Gray Colors
        "gray-1": "#233442",
        "gray-2": "#42515D",
        "gray-3": "#626E78",
        "gray-4": "#818B93",
        "gray-5": "#A1A8AE",
        "gray-6": "#C0C5C9",
        "gray-7": "#E0E2E4",
        // Other Colors
        placeholder: "#707070",
      },
    },
    options: { customProperties: true },
  },
  icons: {
    iconfont: "fa",
    values: {
      cameraIcon: {
        component: CameraIcon,
        props: {
          number: 5,
        },
      },
      area: {
        component: () => import("@/components/icons/AreaIcon"),
      },
      movement: {
        component: () => import("@/components/icons/MovementIcon"),
      },
      queued: {
        component: () => import("@/components/icons/QueuedIcon"),
      },
      // Metric Icons
      flow: {
        component: () => import("@/components/icons/FlowIcon"),
      },
      spotSpeed: {
        component: () => import("@/components/icons/SpotSpeedIcon"),
      },
      clearanceSpeed: {
        component: () => import("@/components/icons/ClearanceSpeedIcon"),
      },
      conflict: {
        component: () => import("@/components/icons/ConflictIcon"),
      },
    },
  },
});
