import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"ma-0 pa-0"},[_c('label',{staticClass:"font-weight-bold secondary--text",class:_vm.large ? 'body-1' : 'body-2'},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"accent--text font-weight-bold"},[_vm._v("*")]):_vm._e()]),_c(VTextarea,_vm._b({ref:"textArea",staticClass:"input",class:{ large: _vm.large },attrs:{"background-color":_vm.disabled ? 'disabled' : 'background',"color":"secondary","flat":"","hide-details":"auto","no-resize":"","solo":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-textarea',Object.assign({}, {rows: _vm.rows,
      placeholder: _vm.placeholder,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      large: _vm.large},
      _vm.$attrs),false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }