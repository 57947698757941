<template>
  <v-dialog
    max-width="564"
    min-height="200"
    min-width="470"
    scrollable
    value="true"
    @click:outside="$emit('click:close')"
  >
    <v-card
      class="ma-0 overflow-y-auto pa-1"
      color="white"
      max-height="360"
      rounded="lg"
    >
      <!--  Card Title  -->
      <v-card-title class="mb-2">
        <h1 class="font-weight-black secondary--text space-mono">
          Add videos to
        </h1>
        <v-spacer />
        <u-button color="secondary" icon @click="$emit('click:close')">
          <v-icon>fas fa-times</v-icon>
        </u-button>
      </v-card-title>

      <!--  Card Body  -->
      <v-card-text>
        <v-row>
          <!-- Cameras List -->
          <v-col
            v-for="camera in cameras"
            :key="camera.id"
            align-self="center"
            cols="3"
          >
            <u-camera-tile
              :cameraName="camera.camera_name"
              @click.native="$emit('click:camera', camera)"
            />
          </v-col>
          <!-- New Camera -->
          <v-col align-self="center">
            <u-camera-tile
              cameraName="New Camera"
              @click.native="$emit('click:newCamera')"
            >
              <template #plus>
                <v-icon class="pb-10" color="secondary" x-small>
                  fas fa-plus
                </v-icon>
              </template>
            </u-camera-tile>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton } from "@/components/base";
import { UCameraTile } from "@/components/common";

export default {
  name: "CameraSelectorModal",
  components: { UButton, UCameraTile },
  props: {
    cameras: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
