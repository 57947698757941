<template>
  <svg
    id="logo"
    aria-labelledby="Urban AI Logo"
    viewBox="0 0 578.5 578.5"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect class="rect" height="578.5" width="578.5" />
      <g>
        <path
          class="var-1"
          d="M278.91,188.08a46.45,46.45,0,0,1,2.19,29L211.46,147.3,188.07,90.79,266.2,169a59.28,59.28,0,0,1,12.71,19.05Z"
        />
        <path
          class="var-2"
          d="M188.07,90.79l23.39,56.51v98.56a46.46,46.46,0,0,1-18.92-22,58.46,58.46,0,0,1-4.45-22.46Z"
        />
        <path
          class="var-2"
          d="M298,200.86a62.69,62.69,0,0,1-4.43,28.64l44.25,44.31,89,89,41.81,8.67L301,203.86Z"
        />
        <path
          class="var-1"
          d="M227.21,185.34l26.4,63.78a46.52,46.52,0,0,1-26.4,2.76Z"
        />
        <path
          class="var-2"
          d="M274.22,232.42a46.46,46.46,0,0,1-20.61,16.7l-26.4-63.78Z"
        />
        <path
          class="var-2"
          d="M227.17,428l56.42-85.89-31.43-75.94a62,62,0,0,1-24.94,1.6v58.3Z"
        />
        <path
          class="var-1"
          d="M211.47,263.48a62.56,62.56,0,0,1-23.37-17.13L188,487.71,211.42,452Z"
        />
        <path
          class="var-1"
          d="M326.68,284.94l-41.2-41.25a62,62,0,0,1-18.78,16.5l31.44,75.94L398.76,357Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLogo",
};
</script>

<style lang="scss" scoped>
.rect {
  fill: var(--v-secondary-base);
}

.var-1 {
  fill: var(--v-accent-base);
}

.var-2 {
  fill: var(--v-primary-base);
}

#logo:hover {
  .rect {
    fill: var(--v-primary-base);
  }
}

#logo:active {
  .rect {
    fill: var(--v-accent-base);
  }
}

#logo:hover,
#logo:active {
  .var-1,
  .var-2 {
    fill: var(--v-secondary-base);
  }
}
</style>
