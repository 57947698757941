<template>
  <v-dialog
    v-model="model"
    class="ma-4"
    max-width="1200px"
    scrollable
    width="80%"
  >
    <v-card class="pa-2" color="white" rounded="lg">
      <!--   Title   -->
      <v-card-title>
        <v-row
          align="center"
          class="ma-0 py-2 flex-nowrap justify-space-between"
        >
          <h1 class="modal-title space-mono secondary--text">
            Process Metrics
          </h1>
          <u-button color="secondary" icon @click="model = false">
            <v-icon>fas fa-times</v-icon>
          </u-button>
        </v-row>
      </v-card-title>

      <!--   Content   -->
      <v-card-text class="pa-5">
        <h2 class="text-h4 secondary--text">Configured Metrics</h2>
        <process-metric-selector
          v-model="selectedMetricsList"
          :data="configuredMetrics"
        />

        <!-- Date and Time Field -->
        <h2 class="text-h4 secondary--text mb-4">Time Period</h2>
        <v-row class="pa-1">
          <v-col class="my-0 py-0 d-flex gap-1 align-end" cols="12" lg="6">
            <u-date-field v-model="startDate" label="Start Period" />
            <u-time-field v-model="startTime" label="" />
          </v-col>
          <v-col class="my-0 py-0 d-flex gap-1 align-end" cols="12" lg="6">
            <u-date-field v-model="endDate" label="End Period" />
            <u-time-field v-model="endTime" label="" />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Action Buttons -->
      <v-card-actions>
        <v-row class="pa-3 gap-1" justify="end">
          <u-button
            class="text-capitalize font-weight-bold white--text"
            color="secondary"
            outlined
            @click="model = false"
          >
            Cancel
          </u-button>
          <u-button
            :class="processLoader ? 'white--text' : 'secondary--text'"
            :color="processLoader ? 'gray-1' : 'primary'"
            :disbled="processLoader"
            :loadState="processLoader"
            class="text-capitalize font-weight-bold"
            @click="process"
          >
            Process
          </u-button>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UButton, UDateField, UTimeField } from "@/components/base";
// Barrel import not used as it will cause circular dependency
import ProcessMetricSelector from "@/components/ProcessMetricSelector";
import { mapState } from "vuex";
import { ALL_METRICS } from "@/utils/const";

export default {
  name: "ProcessMetricModal",
  components: {
    ProcessMetricSelector,
    UButton,
    UTimeField,
    UDateField,
  },
  data: () => ({
    // Input data
    startTime: "",
    startDate: "",
    endTime: "",
    endDate: "",
    selectedMetricsList: [],
  }),
  props: {
    value: { type: Boolean, default: false },
    processLoader: { type: Boolean, default: false },
  },
  computed: {
    ...mapState("Project", ["projectCameras"]),
    ...mapState("MetricConfig", ["existingMetricConfig"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    configuredMetrics() {
      const projectCameras = this.projectCameras;
      const configJson = this.existingMetricConfig?.config_data;

      let configuredMetrics = [];
      configJson &&
        Object.entries(configJson).forEach(([cameraId, cameraConfig]) => {
          let camera = projectCameras.find(
            (camera) => camera.camera_id.toString() === cameraId.toString(),
          );

          let cameraName = camera?.camera_name;
          let metrics = ALL_METRICS.filter(({ value }) =>
            Object.keys(cameraConfig).includes(value),
          );

          configuredMetrics.push({
            id: cameraId,
            name: cameraName,
            metrics: metrics,
          });
        });
      return configuredMetrics;
    },
  },
  methods: {
    process() {
      this.$emit(
        "click:process",
        this.startDate,
        this.startTime,
        this.endDate,
        this.endTime,
        this.selectedMetricsList,
      );
    },
  },
};
</script>
<style>
.modal-title {
  font-size: 2.75rem;
}
</style>
