import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VContainer,[_c(VRow,_vm._l((_vm.items),function(item,index){return _c(VItem,{key:index,attrs:{"value":_vm.itemValue.length === 0 ? item : item[_vm.itemValue]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('u-button',{staticClass:"ma-1 rounded",class:active ? '' : 'outline',attrs:{"color":active ? 'secondary' : 'white',"fab":""},on:{"click":toggle}},[_vm._t("default",function(){return [_c(VIcon,{attrs:{"color":active ? 'white' : 'secondary'}},[_vm._v(" "+_vm._s(item.icon || "fas fa-video")+" ")])]},null,{ item: item, index: index, active: active })],2)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }