<template>
  <v-container class="ma-0 pa-2 status-indicator" fluid>
    <v-row class="ma-0 justify-center align-center flex-nowrap">
      <!--   Status Icon   -->
      <v-avatar
        :color="statusStyle.color"
        class="status-shadow"
        rounded
        size="50"
      >
        <v-icon color="secondary">{{ statusStyle.icon }}</v-icon>
      </v-avatar>

      <!--   Status Text   -->
      <v-col class="pa-2 d-flex flex-column justify-center">
        <v-row class="mx-2 py-2 secondary--text align-center gap-1">
          <camera-icon :number="cameraIndex" size="24" />
          <p class="mb-0 subtitle-1 font-weight-bold text-capitalize">
            {{ status }}
          </p>
        </v-row>
        <p class="mb-0 mx-2 mt-1 body-2 secondary--text">
          {{ timestamp }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CameraIcon } from "@/components/icons";

const CONFIG_STATUSES = {
  PROCESSED: "processed",
  CONFIGURED: "configured",
  RECONFIGURED: "reconfigured",
  PENDING: "pending",
  ERROR: "error",
};

export default {
  name: "UStatusIndicator",
  components: { CameraIcon },
  props: {
    status: {
      type: String,
      default: CONFIG_STATUSES.PENDING,
    },
    cameraIndex: {
      type: [String, Number],
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
  },
  computed: {
    statusStyle() {
      switch (this.status) {
        case CONFIG_STATUSES.CONFIGURED:
        case CONFIG_STATUSES.RECONFIGURED:
        case CONFIG_STATUSES.PROCESSED:
          return { color: "primary", icon: "fas fa-check" };
        case CONFIG_STATUSES.ERROR:
          return { color: "error", icon: "fas fa-exclamation-triangle" };
        case CONFIG_STATUSES.PENDING:
        default:
          return { color: "gray-7", icon: "fas fa-minus" };
      }
    },
  },
};
</script>

<style scoped>
.status-indicator {
  width: fit-content;
  max-width: 300px;
  overflow: hidden;
}

.status-shadow {
  box-shadow: inset -4.62438px -4.62438px 11.561px rgba(255, 255, 255, 0.3),
    inset 3.46829px 3.46829px 4.62438px rgba(3, 23, 39, 0.05);
}
</style>
