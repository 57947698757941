import Vue from "vue";
import ApiService from "@/services/ApiService";

const getInitialState = () => ({
  activeCamera: undefined,
  activeMetric: undefined,
  activeTag: undefined,
  configs: {},
  metrics: {},
  existingMetricConfig: {},
});

const state = getInitialState();

const getters = {
  // Returns the config for the selected camera-metric combo
  getActiveConfig(state) {
    const camera = state.activeCamera?.camera_id;
    const metric = state.activeMetric;
    const tag = state.activeTag?.tag_id;
    return metric && camera
      ? state.configs[camera]?.[metric]?.[tag]
      : undefined;
  },
  // Get list of all metrics for active camera
  getCameraMetrics(state) {
    const camera = state.activeCamera?.camera_name;
    return state.metrics?.[camera] ?? [];
  },
};

const mutations = {
  // Set Canvas Area of camera in configs state
  SET_CANVAS_AREA(state, { cameraId, metric, tag, area }) {
    const config = state.configs[cameraId][metric][tag.tag_id];
    Vue.set(state.configs[cameraId][metric], tag.tag_id, {
      ...config,
      canvasArea: area,
    });
  },

  SET_CANVAS_METRIC_AREA(state, { cameraId, metric, tag, area }) {
    const config = state.configs[cameraId][metric][tag.tag_id];
    Vue.set(state.configs[cameraId][metric], tag.tag_id, {
      ...config,
      canvasMetricArea: area,
    });
  },

  // Set Active Camera State
  SET_ACTIVE_CAMERA(state, camera) {
    state.activeCamera = camera;
  },

  // Set Active metric
  SET_ACTIVE_METRIC(state, metric) {
    state.activeMetric = metric;
  },

  SET_ACTIVE_TAG(state, tag) {
    state.activeTag = tag;
  },

  // Update metric config object
  UPDATE_METRIC_CONFIG(state, { cameraId, metric, tag, metric_config }) {
    Vue.set(state.configs[cameraId][metric], tag.tag_id, {
      ...state.configs[cameraId][metric][tag.tag_id],
      ...metric_config,
    });
  },

  // Initialize Camera in configs state
  INIT_CAMERA(state) {
    const cameraId = String(state.activeCamera.camera_id);
    state.configs = { ...state.configs, [cameraId]: {} };
  },

  // Initialize Metric in configs state
  INIT_METRIC(state) {
    const cameraId = String(state.activeCamera.camera_id);
    const cameraConfig = {
      ...state.configs[cameraId],
      [state.activeMetric]: {},
    };
    state.configs = { ...state.configs, [cameraId]: { ...cameraConfig } };
  },

  INIT_TAG(state) {
    const { activeCamera, activeMetric, activeTag } = state;
    const cameraId = String(activeCamera.camera_id);
    const tagId = String(activeTag.tag_id);
    const cameraMetricConfig = {
      ...state.configs[cameraId][activeMetric],
      [tagId]: {
        tag_id: activeTag.tag_id,
        tag_name: activeTag.tag_name,
        camera_id: activeCamera.camera_id,
        camera_name: activeCamera.camera_name,
      },
    };
    Vue.set(state.configs[cameraId], activeMetric, cameraMetricConfig);
  },

  SET_METRICS(state, metrics) {
    state.metrics = metrics;
  },

  SET_EXISTING_METRIC_CONFIG(state, payload) {
    state.existingMetricConfig = payload;
  },

  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  /**
   * Update the config for the current camera-metric combo
   * @param state
   * @param commit
   * @param {String} type - Type of Action
   * @param {Object, Array} payload - data to update state with
   */
  async updateCurrentConfig({ state, commit }, { type = "", payload }) {
    const { configs, activeCamera, activeMetric, activeTag } = state;
    const cameraId = activeCamera?.camera_id.toString();
    const tagId = activeTag?.tag_id.toString();

    if (activeCamera && !Object.keys(configs).includes(cameraId))
      await commit("INIT_CAMERA");

    if (
      cameraId &&
      activeMetric &&
      !Object.keys(configs[cameraId] ?? {}).includes(activeMetric)
    )
      await commit("INIT_METRIC");

    if (
      cameraId &&
      activeMetric &&
      activeTag &&
      !Object.keys(configs[cameraId][activeMetric] ?? {}).includes(tagId)
    )
      await commit("INIT_TAG");

    const res = {
      cameraId: activeCamera.camera_id,
      metric: activeMetric,
      tag: activeTag,
    };

    if (cameraId && activeMetric && tagId)
      switch (type) {
        case "CANVAS_AREA":
          commit("SET_CANVAS_AREA", { ...res, area: payload });
          break;
        case "CANVAS_METRIC_AREA":
          commit("SET_CANVAS_METRIC_AREA", { ...res, area: payload });
          break;
        default:
          commit("UPDATE_METRIC_CONFIG", { ...res, metric_config: payload });
          break;
      }
  },

  /**
   * Update the list of metrics for active camera
   * @param cameraMetrics - List of camera metrics
   */
  updateCameraMetrics({ state, commit }, { cameraMetrics }) {
    const camera = state.activeCamera.camera_name;
    const metrics = { ...state.metrics, [camera]: [...cameraMetrics] };
    commit("SET_METRICS", metrics);
  },

  /**
   * Delete config for a particular metric
   * @param cameraId - Camera ID of the config to be deleted
   * @param metric - Metric name whose config is to be deleted
   */
  deleteConfig({ state }, { cameraId, metric }) {
    Vue.delete(state.configs[cameraId], metric);
    console.log({ ...state.configs });
  },

  /**
   * Get existing metric config for the project
   */
  async getMetricConfig({ rootState, commit, dispatch }) {
    try {
      const res = await ApiService.getMetricConfig(
        rootState.Project.activeProject.project_id,
      );
      commit("SET_EXISTING_METRIC_CONFIG", res.data);
    } catch (e) {
      console.log(e);
      dispatch(
        "Notification/addNotification",
        {
          text: "Failed to fetch metric config!",
          color: "error",
        },
        { root: true },
      );
    }
  },

  // Reset Metric Config state
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
