/**
 * Create Video Objects for upload files
 * @param files: FileList - List of files to upload
 * @returns [...videoObjs] - Array of objects containing data on files to upload
 */
const createVideoObjects = (files) => {
  let videos = Array.from(files);
  let videoObjects = [];
  videos.forEach((file) => {
    let videoObj = {
      file: file,
      filename: file.name,
      error: false,
      uploadPercentage: 0,
      imageUrl: "",
      camera: "",
      camera_id: null,
      state: "",
      date: "",
      time: "",
    };
    videoObjects.push(videoObj);
  });
  return videoObjects;
};

/**
 * Groups videos by camera
 * @param videos - List of all videos
 * @returns {Object} - Videos grouped by camera
 */
const groupVideosByCamera = (videos) =>
  videos.reduce((acc, curr) => {
    acc[curr.camera_id] = acc[curr.camera_id] || {
      camera_id: curr.camera_id,
      camera_name: curr.camera,
      videos: [],
    };
    acc[curr.camera_id].videos.push(curr);
    return acc;
  }, {});

export { groupVideosByCamera, createVideoObjects };
