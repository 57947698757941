<template>
  <v-sheet :width="'100%'" color="background" rounded>
    <v-row
      align="center"
      class="pa-1 font-weight-bold subtitle-2 flex-nowrap"
      justify="space-between"
    >
      <!-- Upload List item name -->
      <v-col align-self="center" class="pa-1 text-truncate" cols="4" lg="auto">
        <v-icon class="ml-2 mr-1 pa-1" color="secondary" medium>
          fas fa-film
        </v-icon>
        {{ label }}
      </v-col>
      <!-- Progress bar -->
      <v-col class="pa-1 flex-grow-1" cols="auto">
        <v-progress-linear
          v-if="state !== 'uploaded'"
          :color="colors[state]"
          :value="uploadProgress"
          background-color="gray-7"
          class="body-2 rounded-pill"
          height="12"
        >
          <small> {{ Math.ceil(uploadProgress) }}% </small>
        </v-progress-linear>
      </v-col>
      <v-col v-if="state === 'uploading'" class="text-center pa-2" cols="1">
        {{ uploadProgress }}%
      </v-col>
      <!-- Upload state -->
      <v-col v-else class="mx-2 pa-2 text-capitalize" cols="auto">
        {{ state }}
        <!-- Upload state icon -->
        <v-icon class="mx-2" color="secondary" small>
          {{ stateIcons[state] }}
        </v-icon>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "VideoUploadListItem",
  data: () => ({
    // Upload state icons
    stateIcons: {
      queued: "$queued",
      paused: "fas fa-pause",
      error: "fas fa-exclamation-triangle",
      uploaded: "fas fa-check-circle",
    },
    // Progress bar colours
    colors: {
      uploading: "primary",
      queued: "primary",
      paused: "gray-5",
      error: "accent",
    },
  }),
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    uploadProgress: {
      type: Number,
      default: 0,
      required: true,
    },
    state: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-progress-linear__determinate {
  border-radius: 100vh;
}

::v-deep .v-progress-linear {
  background: var(--v-gray-7-base);
}
</style>
