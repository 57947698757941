<template>
  <v-sheet
    :width="'100%'"
    class="pa-4 d-flex flex-column justify-center white--text"
    color="gray-2"
    rounded="lg"
  >
    <!-- Project Title and Details -->
    <h1 class="px-2 text-wrap text-truncate">{{ project.name }}</h1>
    <p class="py-0 px-3">
      <small>Last accessed on: {{ project.created_at }}</small>
    </p>
    <v-row class="mb-1" justify="space-between">
      <v-col class="px-6">
        <h5>3/5 tasks completed</h5>
        <h5>Time Spent: 130h 27m</h5>
      </v-col>
      <!-- Action Button -->
      <v-col align-self="end" class="d-flex justify-end">
        <u-button
          class="space-mono ml-1 text-capitalize pa-0 gray-2--text"
          color="white"
          @click="resumeProject"
        >
          Resume
        </u-button>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UProjectCardMini",
  components: { UButton },
  props: {
    project: {
      type: Object,
    },
  },
  methods: {
    resumeProject() {
      this.$store.dispatch("Project/clearProjectState");
      this.$store.commit("Project/storeProjectDetails", this.project);
      this.$store.dispatch(
        "Project/getProjectCameras",
        this.project.project_id,
      );
      this.$router.push({
        name: "Project Details",
        params: { project_id: this.project.project_id },
      });
    },
  },
};
</script>
