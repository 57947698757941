import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"ma-0 pa-0"},[_c('label',{staticClass:"font-weight-bold secondary--text",class:_vm.large ? 'body-1' : 'body-2'},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"accent--text font-weight-bold"},[_vm._v("*")]):_vm._e()]),_c(VTextField,_vm._g(_vm._b({ref:"textField",staticClass:"input",class:{ large: _vm.large },attrs:{"background-color":_vm.disabled ? 'disabled' : 'background',"disabled":_vm.disabled,"color":"secondary","flat":"","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-text-field',Object.assign({}, {placeholder: _vm.placeholder,
      type: _vm.type,
      hideDetails: _vm.hideDetails,
      prependInnerIcon: _vm.prependInnerIcon,
      appendIcon: _vm.appendIcon,
      dense: _vm.dense,
      readonly: _vm.readonly,
      error: _vm.error},
      _vm.$attrs),false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }