<template>
  <v-row class="content my-2">
    <v-col>
      <!--  Input  -->
      <input
        id="uploadFileInput"
        accept="video/*"
        class="d-none"
        multiple
        type="file"
        @change="selectFiles"
      />

      <!--  File Drag & Drop Area  -->
      <v-sheet
        class="pa-4 card-border fill-height cursor-pointer"
        outlined
        rounded="lg"
        @click="browseFiles()"
        @dragover="handleDragOver"
        @drop="handleDrop"
      >
        <v-row
          align="center"
          align-content="center"
          class="fill-height col-md-10 mx-auto"
          justify="center"
        >
          <v-col class="text-right" cols="auto">
            <v-icon
              :size="$vuetify.breakpoint.smAndUp ? '108' : '84'"
              color="secondary"
            >
              fas fa-file-video
            </v-icon>
          </v-col>
          <v-col cols="auto" lg="auto" sm="10">
            <h1 class="space-mono placeholder-title text-wrap">
              Upload video from first camera
            </h1>
            <h3 class="placeholder-subtitle text-wrap">
              Drag and drop all your video files for your first camera here or
              click here to open file selector
            </h3>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <!--  New Camera Popup  -->
    <camera-details-modal
      v-if="showCameraDetailsModal"
      :videos="files"
      @click:close="showCameraDetailsModal = false"
      @click:apply="apply"
    />
  </v-row>
</template>

<script>
import { CameraDetailsModal } from "@/components/modals";
import { createVideoObjects } from "@/utils/videos";
import { mapGetters } from "vuex";

export default {
  name: "VideoLibraryPlaceholder",
  components: { CameraDetailsModal },
  computed: {
    ...mapGetters("Project", ["activeProjectId"]),
  },
  data: () => ({
    showCameraDetailsModal: false,
    files: [],
  }),
  methods: {
    // Trigger file select
    browseFiles() {
      document.getElementById("uploadFileInput").click();
    },
    // Handles file drag over window
    handleDragOver(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
    },
    // Handles files details
    handleFiles(files) {
      // files is a FileList of File objects. List some properties.
      for (let f of files) {
        console.log("File: " + f.name + " " + f.size + " bytes");
      }

      if (files.length > 0) {
        this.files = createVideoObjects(files);
        this.showCameraDetailsModal = true;
      }
    },
    // Handles drag and drop of files into window
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files; // FileList object.
      this.handleFiles(files);
    },
    // Handle file select input
    selectFiles(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    // Executes on creating new camera
    apply() {
      this.$router.push({
        name: "Video Setup",
        params: { project_id: this.activeProjectId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-title {
  font-size: clamp(1.75rem, 3.5vw, 2.5rem);
}

.placeholder-subtitle {
  font-size: clamp(0.875rem, 2.5vw, 1.25rem);
}
</style>
