<template>
  <v-sheet
    :color="color"
    :max-width="$vuetify.breakpoint.smAndUp ? '596px' : '100%'"
    class="pl-4 pr-2 d-flex align-center gap-2"
    rounded="lg"
  >
    <v-container class="flex-fill" fluid>
      <v-row>
        <!--  Prepend Content  -->
        <slot name="prepend">
          <v-icon :color="contentColor" class="my-2" small>{{ icon }}</v-icon>
        </slot>
        <!--  Content  -->
        <v-col>
          <slot>
            <p :class="`${contentColor}--text mb-0 font-weight-bold`">
              {{ text }}
            </p>
          </slot>
        </v-col>
      </v-row>
    </v-container>

    <!--  Close / Action Button  -->
    <slot v-if="dismissible" name="close">
      <u-button :color="contentColor" icon small @click="$emit('click:close')">
        <v-icon small>fas fa-times</v-icon>
      </u-button>
    </slot>
  </v-sheet>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UToast",
  components: { UButton },
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "secondary",
    },
    customIcon: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    // Time to display the toast in milliseconds
    time: {
      type: Number,
      default: 10000, // 10 seconds
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit("click:close");
    }, this.time);
  },
  computed: {
    icon() {
      let icon = "fas fa-comment-alt";
      switch (this.color) {
        case "primary":
        case "success":
          icon = "fas fa-check";
          break;
        case "error":
          icon = "fas fa-exclamation-triangle";
          break;
        default:
          break;
      }
      return this.customIcon.length !== 0 ? this.customIcon : icon;
    },
    contentColor() {
      return this.textColor || this.color === "secondary"
        ? "white"
        : "secondary";
    },
  },
};
</script>
