<template>
  <v-sheet
    class="tile cursor-pointer font-weight-bold pa-1 pa-md-2 pa-lg-3 ma-0"
    rounded="lg"
    @click="clickHandler"
  >
    <!-- Illustration and Label -->
    <v-responsive :aspect-ratio="1">
      <slot v-bind="{ ...$props }">
        <v-container
          :class="$vuetify.breakpoint.mdAndUp ? 'align-start' : 'align-center'"
          class="pa-0 d-flex fill-height flex-column justify-center"
        >
          <v-icon class="icon">{{ icon }}</v-icon>
          <p class="mb-0 pt-1 pt-lg-2 text-wrap d-block widget-label">
            {{ label }}
          </p>
        </v-container>
      </slot>
    </v-responsive>
  </v-sheet>
</template>

<script>
export default {
  name: "UWidgetTile",
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickHandler() {
      this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  background: white;
  color: var(--v-gray-1-base);

  .icon {
    color: var(--v-gray-1-base);
    font-size: 2.7vw;
  }

  .widget-label {
    line-height: clamp(0.6rem, 3vw - 1.75rem, 1.2rem);
    font-size: clamp(0.6rem, 3vw - 1.75rem, 1.2rem);
  }

  // Targets tile hover state
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  }

  // Targets tile active state
  &:active {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0);
    background-color: var(--v-gray-1-base);
    color: white;

    .icon {
      color: white;
    }
  }
}
</style>
