import { HTTP } from "@/utils/axios";
import { ENDPOINTS } from "@/utils/const";
import { getJwt, REFRESH_TOKEN } from "@/utils";

const ApiService = {};

// Auth
ApiService.login = (params) => {
  return HTTP.post(ENDPOINTS.LOGIN, params);
};

ApiService.refreshToken = () => {
  return HTTP.post(
    ENDPOINTS.REFRESH,
    {},
    { headers: { Authorization: `Bearer ${getJwt(REFRESH_TOKEN)}` } },
  );
};

ApiService.signup = (params) => {
  return HTTP.post(ENDPOINTS.SIGNUP, params);
};

ApiService.forgotPassword = (params) => {
  return HTTP.post(ENDPOINTS.RESET_PASSWORD_LINK, params);
};

ApiService.resetPassword = (params) => {
  return HTTP.post(ENDPOINTS.RESET_PASSWORD, params);
};

// Project
ApiService.createProject = (params) => {
  return HTTP.post(ENDPOINTS.CREATE_PROJECTS, params);
};

ApiService.getAllProjects = () => {
  return HTTP.get(ENDPOINTS.LIST_USER_PROJECTS);
};

ApiService.projectDetails = (projectId) => {
  return HTTP.get(ENDPOINTS.PROJECT_DETAILS(projectId));
};

ApiService.deleteProject = (projectId) => {
  return HTTP.delete(ENDPOINTS.DELETE_PROJECT(projectId));
};

ApiService.updateProject = (projectId, params) => {
  return HTTP.put(ENDPOINTS.EDIT_PROJECT(projectId), params);
};

// Templates
ApiService.getAllTemplates = () => {
  return HTTP.get(ENDPOINTS.FETCH_ALL_TEMPLATES);
};

// Cameras
ApiService.getProjectCameras = (projectId) => {
  return HTTP.get(ENDPOINTS.LIST_PROJECT_CAMERAS(projectId));
};

ApiService.createCamera = (params) => {
  return HTTP.post(ENDPOINTS.CREATE_CAMERAS, params);
};

ApiService.deleteCamera = (cameraId) => {
  return HTTP.delete(ENDPOINTS.DELETE_CAMERA(cameraId));
};

ApiService.assignCameraImage = (cameraId, params) => {
  return HTTP.put(ENDPOINTS.ASSIGN_CAMERA_IMAGE(cameraId), params);
};

// Tags
ApiService.listTags = (projectId) => {
  return HTTP.get(ENDPOINTS.LIST_TAGS(projectId));
};
ApiService.createTag = (projectId, params) => {
  return HTTP.post(ENDPOINTS.CREATE_TAG(projectId), params);
};

// Videos
ApiService.uploadVideo = (cameraId, formData, config) => {
  return HTTP.post(ENDPOINTS.UPLOAD_VIDEO(cameraId), formData, config);
};

ApiService.getProjectVideos = (projectId) => {
  return HTTP.get(ENDPOINTS.LIST_PROJECT_VIDEOS(projectId));
};

ApiService.getCameraVideoImages = (cameraId) => {
  return HTTP.get(ENDPOINTS.GET_CAMERA_VIDEO_IMAGES(cameraId));
};

ApiService.getCameraVideos = (cameraId) => {
  return HTTP.get(ENDPOINTS.CAMERA_VIDEOS(cameraId));
};

// Camera Configuration
ApiService.geotag = (cameraId, params) => {
  return HTTP.post(ENDPOINTS.GEOTAG(cameraId), params);
};
ApiService.processVideos = (params) => {
  return HTTP.post(ENDPOINTS.PROCESS_VIDEOS, params);
};
ApiService.getDetectionStatuses = (projectId) => {
  return HTTP.get(ENDPOINTS.GET_DETECTION_STATUSES(projectId));
};

// Metric Configuration
ApiService.saveMetricConfig = (projectId, params) => {
  return HTTP.post(ENDPOINTS.SAVE_METRIC_CONFIG(projectId), params);
};
ApiService.processMetric = (projectId, params) => {
  return HTTP.post(ENDPOINTS.PROCESS_METRIC(projectId), params);
};
ApiService.getDirectionMovement = (projectId, cameraId, params) => {
  return HTTP.post(
    ENDPOINTS.GET_DIRECTION_MOVEMENTS(projectId, cameraId),
    params,
  );
};
ApiService.testConfig = (projectId, cameraId, params) => {
  return HTTP.post(ENDPOINTS.TEST_CONFIG(projectId, cameraId), params);
};
ApiService.getMetricConfig = (projectId) => {
  return HTTP.get(ENDPOINTS.GET_METRIC_CONFIG(projectId));
};

export default ApiService;
