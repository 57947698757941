import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0 ma-0"},[_c('label',{staticClass:"body-2 font-weight-bold secondary--text"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c(VSelect,_vm._b({staticClass:"font-weight-bold",attrs:{"menu-props":{ top: _vm.top, offsetY: _vm.offsetY },"background-color":"white","color":"secondary","flat":"","hide-details":"auto","item-color":"secondary","solo":""},scopedSlots:_vm._u([(_vm.multiple)?{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.itemText ? item[_vm.itemText] : item)+" ")]),_c(VSpacer),_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"dense":""}})],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}:{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("item",function(){return [_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.itemText ? item[_vm.itemText] : item)+" ")])]},null,{ active: active, item: item, attrs: attrs, on: on })]}},{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-select',{
      items: _vm.items,
      itemText: _vm.itemText,
      placeholder: _vm.placeholder,
      itemValue: _vm.itemValue,
      dense: _vm.dense,
      multiple: _vm.multiple,
      returnObject: _vm.returnObject,
      prependInnerIcon: _vm.prependInnerIcon,
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }