<template>
  <!-- Progress bar for Steps -->
  <v-app-bar
    class="secondary--text"
    color="primary"
    flat
    height="64"
    min-width="50vw"
  >
    <v-row align="center" class="mx-2">
      <h1 class="px-2">Step {{ step }}/4</h1>

      <v-col cols="auto" align-self="center">
        <h2 class="space-mono title-height">{{ title }}</h2>
        <p class="body-2 mb-0">{{ subtitle }}</p>
      </v-col>

      <v-spacer></v-spacer>
      <!-- Navigate to Previous and Next sub-step -->
      <u-button color="secondary" icon @click="$emit('click:prev')">
        <v-icon>fas fa-chevron-left</v-icon>
      </u-button>

      <p class="mb-0 font-weight-bold">{{ subStep }}</p>

      <u-button color="secondary" icon @click="$emit('click:next')">
        <v-icon>fas fa-chevron-right</v-icon>
      </u-button>

      <u-button color="secondary" icon @click="$emit('click:close')">
        <v-icon>fas fa-times</v-icon>
      </u-button>
    </v-row>
  </v-app-bar>
</template>

<script>
import { UButton } from "@/components/base";

export default {
  name: "UStepperProgression",
  components: { UButton },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
      required: true,
    },
    step: {
      type: [Number, String],
      default: "",
      required: true,
    },
    subStep: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title-height {
  line-height: 1.75rem;
}
</style>
