import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"py-1 px-4"},[_c('camera-icon',{attrs:{"number":_vm.cameraIndex,"size":22}}),_c('p',{staticClass:"px-2 my-0 body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.cameraName))])],1),_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},_vm._l((_vm.cameraVideos),function(ref,index){
var filename = ref.filename;
var uploadPercentage = ref.uploadPercentage;
var state = ref.state;
return _c('video-upload-list-item',{key:index,staticClass:"my-5",attrs:{"label":filename,"state":state,"uploadProgress":uploadPercentage}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }