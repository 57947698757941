<template>
  <v-container
    class="toast-container d-flex flex-column gap-1 flex-nowrap align-center"
    fluid
  >
    <UToast
      v-for="snackbar in displayNotifications"
      :key="snackbar.id"
      dismissible
      v-bind="{
        text: snackbar.text,
        ...snackbar,
      }"
      @click:close="removeNotification(snackbar.id)"
    />
  </v-container>
</template>

<script>
import { UToast } from "@/components/base";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ToastContainer",
  components: { UToast },
  computed: {
    ...mapGetters("Notification", ["displayNotifications"]),
  },
  methods: {
    ...mapActions("Notification", ["removeNotification"]),
  },
};
</script>

<style scoped>
.toast-container {
  z-index: 10;
  position: absolute;
  bottom: 1rem;
  pointer-events: none;
}

.toast-container > * {
  pointer-events: all;
}
</style>
